import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "./loader.service";
import { RequestService } from "./request.service";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { ViewInvestigatorDialogComponent } from "src/app/pages/case-page/investigator/view-investigator-dialog/view-investigator-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ViewInvestigatorGroupDialogComponent } from "src/app/pages/case-page/investigator/view-investigator-group-dialog/view-investigator-group-dialog.component";
import { VonageService } from "./vonage.service";
import { environment } from "src/environments/environment";

export enum TypesEnum {
    VIDEO = 'Video',
    AUDIO = 'Audio',
    IMAGE = 'Image',
    FILE = 'File'
};

@Injectable()
export class CaseService {
    private tabId: string;
    private channel: BroadcastChannel;
    public cameraSession: OT.Session;
    private defaultSettings = {
        retrySubscribing: 5000,
        audioBlockedTimer: 3000
    }
    public streams: any = [];

    public goToLocation: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public setNewCaseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public caseMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public profile: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public noteMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public userMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public cameraMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public userMapMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public investigatorGroupMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public currentUserGroupsId: any = [];
    public cameras: any = [];
    public cameraGroups: any = [];
    public investigatorGroups: any = [];
    public investigators: any = [];
    public admins: any = [];
    public commanders: any = [];
    public investigatorsAll: any = [];
    public investigatorsGroup: any = [];
    public investigatorsMap: any = [];
    public timeline: any = [];
    public currentUsermap: any = {};
    public currentUserGroupsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public caseLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public caseUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public investigatorsMapUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public investigatorsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public investigatorsGroupsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public camerasUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public cameraGroupsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public timelineUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public documentsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public cameraSteamCreated: BehaviorSubject<OT.Stream[]> = new BehaviorSubject<OT.Stream[]>([]);
    public cameraSteamDestroyed: BehaviorSubject<OT.Stream[]> = new BehaviorSubject<OT.Stream[]>([]);
    public cameraSteamChanged: BehaviorSubject<OT.Stream[]> = new BehaviorSubject<OT.Stream[]>([]);

    constructor(private requestService: RequestService, private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private loadService: LoaderService, private router: Router, private dialog: MatDialog, private vonageService: VonageService) {
        this.channel = new BroadcastChannel('case-service');

        // Generate a unique tab ID and store it in sessionStorage
        this.tabId = sessionStorage.getItem('tabId') || this.generateTabId();
        sessionStorage.setItem('tabId', this.tabId);

        // Listen for messages
        this.channel.addEventListener('message', (event) => {
            if (event.data.tabId !== this.tabId) {
                if (this.profile) {
                    let profile = this.profile.getValue();
                    if (profile && profile._id) {
                        if (event.data.caseId === profile._id) {
                            if (event.data.type == 'timeline') {
                                this.timelineUpdated.next(true);
                            } else if (event.data.type == 'case') {
                                this.caseUpdated.next(true);
                            }
                        }
                    }
                }
            }
        });
    }
    private generateTabId(): string {
        return Math.random().toString(36).substring(2, 15);
    }
    cleanSelectedCase() {
        this.cameras = [];
        this.cameraGroups = [];
        this.investigatorGroups = [];
        this.admins = [];
        this.commanders = [];
        this.investigators = [];
        this.investigatorsAll = [];
        this.investigatorsGroup = [];
        this.investigatorsMap = [];
        this.timeline = [];
        this.currentUsermap = {};
        this.goToLocation.next(undefined);
        this.caseLoaded.next(false);
        this.caseUpdated.next(false);
        this.investigatorsMapUpdated.next(false);
        this.investigatorsUpdated.next(false);
        this.investigatorsGroupsUpdated.next(false);
        this.camerasUpdated.next(false);
        this.cameraGroupsUpdated.next(false);
        this.timelineUpdated.next(false);
        this.documentsUpdated.next(false);
        // this.caseLoaded = new BehaviorSubject<boolean>(false);
        // this.caseUpdated = new BehaviorSubject<boolean>(false);
        // this.investigatorsMapUpdated = new BehaviorSubject<boolean>(false);
        // this.investigatorsUpdated = new BehaviorSubject<boolean>(false);
        // this.investigatorsGroupsUpdated = new BehaviorSubject<boolean>(false);
        // this.camerasUpdated = new BehaviorSubject<boolean>(false);
        // this.cameraGroupsUpdated = new BehaviorSubject<boolean>(false);
        // this.timelineUpdated = new BehaviorSubject<boolean>(false);
        // this.documentsUpdated = new BehaviorSubject<boolean>(false);
    }
    updateCase(caseId, type = 'all', data = '') {
        if (this.profile) {
            let profile = this.profile.getValue();
            if (profile && profile._id) {
                this.channel.postMessage({ tabId: this.tabId, caseId: caseId, timestamp: Date.now(), type: type, data: data });
            }
        }
    }
    getCaseMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('case').then((metadata: any) => {
                this.caseMetadata.next(metadata.results || []);
                resolve(metadata.results);
            }).catch((e) => reject(e));
        });
    }

    getNoteMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('note').then((metadata: any) => {
                this.noteMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }

    getUserMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('user').then((metadata: any) => {
                this.userMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }
    getCameraMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('camera').then((metadata: any) => {
                this.cameraMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }
    getUserMapMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('caseusermapping').then((metadata: any) => {
                this.userMapMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }
    getInvestigatorGroupMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('investigatorgroup').then((metadata: any) => {
                this.investigatorGroupMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }

    getCase(caseId: string, skipSubs = false): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getSingleData('case', caseId, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    if (!skipSubs) {
                        this.profile.next(data.results);
                    }
                    resolve(data.results);
                }
            });
        });
    }

    private getMetadataPromise(type: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getMetaData(type, undefined, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getDocuments(pageIndex = 0, pageSize = 10, caseId, searchValue, type): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [
                { "organizationId._id": { "$in": [this.requestService.orgId] } },
                { "case._id": { "$eq": caseId } },
            ];

            if (type) {
                andFilter.push({ "type": { "$eq": type } });
            }

            this.requestService.postRequest('doc', 'search', {
                page: pageIndex + 1,
                count: pageSize,
                order: [{ "field": "createdAt", "order": "desc" }],
                fields: ["createdAt", "name", "case", "type", "url", "createdName"],
                termfieldstermfields: ['name'],
                term: searchValue,
                filter: {
                    "$and": andFilter
                }
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getInvestigatorGroups(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigatorgroup/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    data.results = data.results.map((group, index) => ({
                        ...group,
                        investigatorNames: group.investigators.map(inv => (inv.alternative_name || inv.name)).join(', '),
                        investigatorIds: group.investigators.map(inv => (inv._id)),
                        shortId: (index + 1) < 10 ? '0' + (index + 1) : (index + 1)
                    }));

                    // this.investigatorgroups.map((i, index) => {
                    //     i.shortId = (index + 1) < 10 ? '0' + (index + 1) : (index + 1);
                    // });
                    this.investigatorGroups = data.results;
                    resolve(data.results);
                }
            });
        });
    }

    getInvestigatorsList(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigators/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    // this.investigators = data.results;
                    resolve(data);
                }
            });
        });
    }
    public getAdminsPromise(casePrefecture: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('user', 'search', {
                order: [{ "field": "alternative_name", "order": "asc" }],
                fields: ["resources", "name", "alternative_name", "deviceids", "pictureLink"],
                term: '',
                filter: {
                    "$and": [{ "organizationId._id": { "$in": [this.requestService.orgId] } }, {
                        "resources._id": { "$in": [environment.customKeys.roleAdmin] },
                        "prefecture": { "$eq": casePrefecture }
                    }]
                }
            }, (data, error) => {
                if (data?.results?.length) {
                    // this.displayAdmins = data.results;
                    data.results.map(i => {
                        i.role = 'Admin';
                    });
                    this.admins = data.results;
                    resolve(data.results);
                }
                else {
                    resolve();
                }
            });
        });
    }

    public getCommandersPromise(casePrefecture: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.postRequest('user', 'search', {
                order: [{ "field": "alternative_name", "order": "asc" }],
                fields: ["resources", "name", "alternative_name", "deviceids", "pictureLink"],
                term: '',
                filter: {
                    "$and": [{ "organizationId._id": { "$in": [this.requestService.orgId] } }, { "resources._id": { "$in": [environment.customKeys.rolePrefectureCommanders] } }, { "prefecture": { "$eq": casePrefecture } }]
                }
            }, (data, error) => {
                if (data?.results?.length) {
                    // this.displayCommanders = data.results;
                    data.results.map(i => {
                        i.role = 'Commander';
                    });
                    this.commanders = data.results;
                    resolve(data.results);
                }
                else {
                    resolve();
                }
            });
        });
    }
    getInvestigators(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/user/${caseId}/all`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.investigators = data.results;
                    this.investigatorsAll = data.results;
                    resolve(data);
                }
            });
        });
    }
    getInvestigatorsGroup(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigatorgroup/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.investigatorsGroup = data.results;
                    resolve(data);
                }
            });
        });
    }
    getCurrentInvestigatorGroups(userId, skipUpdate = false): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `investigatorgroup/user/${userId}/get`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    if (skipUpdate)
                        this.currentUserGroupsId = data.results.map(itm => itm._id);
                    resolve(data);
                }
            });
        });
    }
    getInvestigatorsCaseData(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/caseusermapping/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.investigatorsMap = data.results;

                    resolve(data);
                }
            });
        });
    }
    getInvestigatorCaseData(caseId, userId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/caseusermapping/${caseId}/list/${userId}`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }
    getCameras(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/camera/${caseId}/all`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.cameras = data.results;
                    resolve(data);
                }
            });
        });
    }
    getCameraGroups(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/cameragroup/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.cameraGroups = data.results;
                    resolve(data);
                }
            });
        });
    }
    secureCameras() {
        let currentUserMap = this.currentUsermap;
        if (!currentUserMap || !currentUserMap.timeline_visibility || currentUserMap.timeline_visibility === '' || currentUserMap.timeline_visibility === 'filtered') {
            let hiddenCams = this.cameras.filter((itm) => itm.invisible).map(itm => itm._id);
            this.cameras = this.cameras.filter((itm) => !itm.invisible);

            this.cameraGroups = this.cameraGroups.map((itm) => {
                let cameras = itm.cameras.filter(itm => !hiddenCams.includes(itm._id))
                itm['cameras'] = cameras;
                return itm;
            })

        }
    }
    getTimelinePromise(caseId: string, pageIndex: number, pageSize: number, filters?: any): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [
                { "organizationId._id": { "$in": [this.requestService.orgId] } },
                { "case._id": { "$eq": caseId } }
            ];
            let documentFilter = {};

            if (filters) {
                let investigators = [];
                if (filters.investigators?.length && !filters.investigators.includes("L1")) {
                    investigators = filters.investigators;
                }
                if (filters.fileType?.length) {
                    documentFilter = {
                        "document.type": { "$in": filters.fileType }
                    }
                }
                if (filters.visibility?.length) {
                    andFilter.push({
                        "visibility": { "$in": filters.visibility }
                    })
                }
                if (filters.filtervisibility) {
                    investigators.push(filters.filtervisibility.userId)
                    andFilter.push({
                        "$or": [
                            { "investigators._id": { "$in": investigators } },
                            { "intiatedFrom._id": { "$in": investigators } },
                            { "investigatorgroup._id": { "$in": filters.filtervisibility.userGroupsIds } },
                            { "visibility": { "$in": ["green"] } },
                            { "createdBy": { "$eq": filters.filtervisibility.userId } }
                        ]
                    });
                } else {
                    if (investigators.length > 0) {
                        andFilter.push({
                            "$or": [{ "investigators._id": { "$in": investigators } }, { "intiatedFrom._id": { "$in": investigators } }]
                        });
                    }
                }
                if (filters.hasOwnProperty('type')) {
                    andFilter.push({
                        "type": { "$eq": filters.type }
                    })
                }
                else {
                    andFilter.push({
                        "type": { "$ne": 'call' }
                    })
                }
            } else {
                andFilter.push({
                    "type": { "$ne": 'call' }
                })
            }

            this.requestService.postRequest('note', 'search', {
                page: pageIndex + 1,
                count: pageSize,
                "order": [{ "field": "updatedAt", "order": "desc" }], "fields": ["createdAt", "createdBy", "name", "camera", "case_status", "case", "casetype", "type", "notes", "createdName", "document", "location", "updatedAt", "geolocation", "action_date", "plate_of_interest", "person_of_interest_name", "person_of_interest_description", "car_of_interest", "person_of_interest", "investigators", "investigatorgroup", "car_of_interest_description", "car_of_interest_identifier", "car_color", "initiatedFrom", "car_model", "car_make", "car_type", "person_of_interest_gender", "visibility"], "term": "",
                "filter": {
                    "$and": andFilter
                },
                document: documentFilter,
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getUserList(users: any, groups: any) {
        let userNames = [];
        users?.forEach(user => {
            userNames.push(user.alternative_name || user.name);
        });
        groups?.forEach(group => {
            userNames.push(group.name);
        });
        let names = userNames.join(", ");
        if (userNames.length > 1) {
            names = names.replace(new RegExp(',' + "(?=[^" + ',' + "]*$)"), ' and ');
        }
        return names;
    }

    viewProfile(userId: string, userMetadata: any, caseId: string = undefined, userMapMetadata: any = undefined, userMapData: any = undefined, hideCall: boolean = false) {
        this.loadService.display(true);
        this.requestService.getRecord(userId, 'user', (data, error) => {
            if (data) {
                this.loadService.display(false);
                const dialog = this.dialog.open(ViewInvestigatorDialogComponent, {
                    autoFocus: false,
                    data: {
                        name: data.results.alternative_name,
                        badgeNo: data.results.badge_no,
                        rank: data.results.rank,
                        division: data.results.division,
                        pictureLink: data.results.pictureLink,
                        deviceids: data.results.deviceids,
                        phone: data.results.phone,
                        alternative_name: data.results.alternative_name,
                        caseId: caseId,
                        userMetadata: userMetadata,
                        userMapMetadata: userMapMetadata,
                        squad_ref: userMapData?.squad_ref,
                        remarks: userMapData?.remarks,
                        call_sign: userMapData?.call_sign,
                        _id: data.results._id,
                        role: data.results.resources[0],
                        hideCall: hideCall
                    },
                    disableClose: false,
                    width: 'fit-content',
                })
            }
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                this.loadService.display(false);
            }
        });
    }
    mapUsersData(investigators, investigatorsMapData) {
        let investigatorsMapped = investigators.map((user) => {
            return this.mapUserData(user, investigatorsMapData);
        });
        return investigatorsMapped;
    }
    mapUserData(investigator, investigatorsMapData) {
        let userMapData = investigatorsMapData.find(itm => investigator._id === itm.user._id);
        if (userMapData) {
            investigator['squad_ref'] = userMapData['squad_ref'];
            investigator['remarks'] = userMapData['remarks'];
            investigator['call_sign'] = userMapData['call_sign'];
            investigator['timeline_visibility'] = userMapData['timeline_visibility'];
        }
        return investigator;
    }
    viewInvestigatorGroupProfile(groupId: string, groupMetadata: any, caseId: string = undefined, userMetadata: any = undefined, userMapMetadata: any = undefined, investigatorsMapData: any[] = undefined, hideCall: boolean = false) {
        this.loadService.display(true);
        this.requestService.getRecord(groupId, 'investigatorgroup', (data, error) => {
            if (data) {
                // console.log(data);
                this.loadService.display(false);
                const dialog = this.dialog.open(ViewInvestigatorGroupDialogComponent, {
                    autoFocus: false,
                    data: {
                        name: data.results.name,
                        pictureLink: data.results.picture,
                        investigators: data.results.investigators,
                        investigatorsMapData: investigatorsMapData,
                        groupMetadata: groupMetadata,
                        userMetadata: userMetadata,
                        userMapMetadata: userMapMetadata,
                        caseId: caseId,
                        _id: data.results._id,
                        hideCall: hideCall
                    },
                    disableClose: false,
                    width: 'fit-content',
                })
            }
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                this.loadService.display(false);
            }
        });
    }

    getCarPlates(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('list', 'note/carplate', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    getPeople(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('list', 'note/persons', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    getAssignedCasesPromise(userId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [{ "organizationId._id": { "$in": [this.requestService.orgId] } }];

            andFilter.push({ "investigators._id": { "$in": [userId] } });

            this.requestService.postRequest('case', 'search', {
                order: [{ "field": "name", "order": "asc" }],
                fields: ["case_id", "createdAt"],
                term: '',
                filter: {
                    "$and": andFilter
                }
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data.results);
                }
            });
        });
    }
    getActiveCasesPromise(selectedRegion: string = undefined, selectedPrefecture: string = undefined, isSuperAdmin: boolean = false): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [{ "organizationId._id": { "$in": [this.requestService.orgId] } }];
            andFilter.push({ "status": { "$eq": 'active' } });
            if (!isSuperAdmin) {
                if (selectedRegion) {
                    andFilter.push({ "region": { "$eq": selectedRegion } });
                }
                if (selectedPrefecture) {
                    andFilter.push({ "prefecture": { "$eq": selectedPrefecture } });
                }
            }
            this.requestService.postRequest('case', 'search', {
                order: [{ "field": "createdAt", "order": "desc" }],
                fields: ["name", "case_id", "createdAt"],
                term: '',
                page: 1,
                // count: 50,
                filter: {
                    "$and": andFilter
                }
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data.results);
                }
            });
        });
    }

    joinCameraSession(caseId: string, currentUser) {
        this.loadService.display(true);
        this.requestService.getRequest('camera', 'opentok/' + caseId + '/join', (sessionData, error) => {
            if (sessionData.results?.openTokSessionId) {
                const opentokSessionId = sessionData.results.openTokSessionId;
                this.requestService.postRequest('camera', 'createtoken', {
                    hostId: caseId,
                    userId: currentUser._id,
                    name: currentUser.alternative_name,
                }, (data, error) => {
                    if (data) {
                        const token = data.results;
                        this.joinCameraCall(opentokSessionId, token);
                    }
                    if (error) {
                        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                        this.loadService.display(false);
                    }
                });
            }
        });
    }

    private joinCameraCall(opentokSessionId: string, token: string) {
        this.vonageService.initSession(opentokSessionId).then((session: OT.Session) => {
            this.cameraSession = session;

            this.cameraSession.on('streamCreated', (event) => {
                this.streams.push(event.stream);
                this.cameraSteamCreated.next(this.streams);
            });

            this.cameraSession.on('streamDestroyed', (event) => {
                this.streams = this.streams.filter(s => s.id != event.stream.streamId);
                this.cameraSteamDestroyed.next(this.streams);
            });

            // this.cameraSession.on('streamPropertyChanged', (event) => {
            //     this.cameraSteamChanged.next(event.stream);
            // });
        }).then(() => {
            this.vonageService.connect(token, this.cameraSession).then((data) => {

            }).finally(() => {
                this.loadService.display(false);
            });
        });
    }

    disconnectCameraSession() {
        this.streams = [];
        if (this.cameraSession) {
            this.vonageService.disconnectSession(this.cameraSession);
        }
    }

    async subscribeStream(stream: OT.Stream, container: any, insertMode: string = 'replace', callBack?: () => void, session: OT.Session = this.cameraSession): Promise<OT.Subscriber> {
        return await this.subscribeToMedia(stream, container, session, this.defaultSettings.retrySubscribing, insertMode, callBack, (error) => {
            if (error && error.hasOwnProperty('name') && error.name === 'OT_STREAM_LIMIT_EXCEEDED') {
                const dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('This meeting has reached its maximum capacity of participants.'), {
                    overlayClickToClose: false,
                    showCancelButton: false,
                    declineText: this.translate.instant('OK')
                }, 'fit-content');
            }
            else if (error?.name !== 'OT_STREAM_NOT_FOUND') {
                setTimeout(() => {
                    this.subscribeStream(stream, container, insertMode, callBack, session);
                }, this.defaultSettings.retrySubscribing);
                this.vonageService.handleError(error);
            }
        });
    }

    private subscribeToMedia(stream: OT.Stream, container: any, session: OT.Session, retryPeriod: number, insertMode: any, callBack?: () => void, errorCallBack?: (error) => void): Promise<OT.Subscriber> {
        //console.log('camera stream', stream);
        // let data = JSON.parse(stream.connection.data);
        return new Promise((resolve, reject) => {
            let element = document.createElement('div');
            if (element) {
                element.style.height = '100%';
                element.style.width = '100%';
                element.setAttribute('data-type', 'media');
                element.setAttribute('data-id', stream.streamId);
                element.classList.add('media-container');

                // console.log('element', element)
                container.append(element);
            }

            let timer;
            let options: OT.SubscriberProperties = { width: '100%', height: '100%', insertMode: insertMode, style: { nameDisplayMode: 'off', buttonDisplayMode: 'off', audioLevelDisplayMode: 'off', audioBlockedDisplayMode: 'off', videoDisabledDisplayMode: 'off' }, subscribeToAudio: true, subscribeToVideo: true };

            // console.log('stream', stream)
            let subscriber: OT.Subscriber = session.subscribe(stream, element, options, (error) => {
                if (error) {
                    if (errorCallBack && error && error.hasOwnProperty('name') && error.name === 'OT_STREAM_LIMIT_EXCEEDED')
                        errorCallBack(error);
                    this.vonageService.handleError(error);
                    if (error['code'] == 1501) {
                        timer = setTimeout(() => this.subscribeToMedia(stream, container, session, retryPeriod, insertMode), retryPeriod);
                    }
                    reject(error);
                }
                else {
                    clearTimeout(timer);
                    if (callBack) {
                        callBack();
                    }
                    resolve(subscriber);
                }
            });
        });
    }

    unsubscribeStream(subscriber: OT.Subscriber) {
        this.cameraSession.unsubscribe(subscriber);
    }

    combineCaseUsers(original, added) {
        return this.sortChatUsers([...original, ...added]
            // Remove duplicates based on _id
            .filter((user, index, self) =>
                index === self.findIndex(u => u._id === user._id)
            ));
    }

    sortChatUsers(users: any) {
        const rolePriority: { [key: string]: number } = {
            'Admin': 1,
            'Commander': 2,
            'Investigator': 3
        };

        return users.sort((a, b) => {
            // 1. unread true first
            if (a.unread !== b.unread) return a.unread ? -1 : 1;

            // 2. If both are unread, sort by timestamp (newest first)
            if (a.unread && b.unread) {
                const aTime = a.createdAt || 0;
                const bTime = b.createdAt || 0;
                if (aTime !== bTime) return bTime - aTime;
            }

            // 3. Role priority
            const aRole = rolePriority[a.role] || 99;
            const bRole = rolePriority[b.role] || 99;
            if (aRole !== bRole) return aRole - bRole;

            // 4. isGroup true first
            if (a.isGroup !== b.isGroup) return a.isGroup ? -1 : 1;

            // 5. Alphabetical by name
            const aName = (a.alternative_name || a.name || '').toLowerCase();
            const bName = (b.alternative_name || b.name || '').toLowerCase();
            return aName.localeCompare(bName);
        });
    }
}
