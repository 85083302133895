<div *ngIf="casesList && casesList.length == 0" class="noResults">
    {{'No records found' | translate}}
</div>
<!-- <div *ngIf="metaFieldSetting && groupedData && casesList && casesList.length > 0">
    <div *ngFor="let date of objectKeys(groupedData)">
        <div class="dateTitle">{{ date }}</div>
        <div class="caseItem" [class.noBorder]="index === groupedData[date].length - 1" fxLayout="row"
            fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngFor="let item of groupedData[date];let index = index">
            <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" class="dateColumn">
                <div class="dateCss">{{ item.createdAt | date: 'yyyy/MM/dd'}}</div>
                <div class="timeCss">{{ item.createdAt | date: 'HH:mm' }}</div>
            </div>
            <div fxFlex="15" class="box" fxLayout="column" fxLayoutGap="2vh">
                {{item['department']}}
                <div><span>{{'Case Type' | translate}}</span>: {{item['casetype']}}
                </div>
                <div><span>{{'Case number' | translate}}</span>: {{item['case_id']}}</div>
            </div>
            <div fxFlex="35" class="box" fxLayout="column" fxLayoutGap="2vh">
                <div class="caseTitle" (click)="openCase(item._id)"><span *ngIf="item.name">{{ item.name }}</span><span
                        *ngIf="!item.name">{{ item.case_id }}</span></div>
                <div class="caseDescription">{{ item.description }}</div>
            </div>
            <div fxFlex="30" class="box" fxLayout="column" fxLayoutGap="2vh" *ngIf="locale === 'en'">
                <div class="caseAddress" (click)="openCaseMap(item._id)">
                    <span>{{ item.address }}</span>
                </div>
            </div>
            <div fxFlex="30" class="box" fxLayout="column" fxLayoutGap="2vh" *ngIf="locale === 'ja'">
                <div class="caseAddress" (click)="openCaseMap(item._id)">
                    <span>{{ item.address_jp }}</span>
                </div>
            </div>
            <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center center"
                *ngIf="!isInvestigator">
                <button mat-raised-button style="width: fit-content;" 
                    (click)="edit(item, index)">{{'Edit' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div> -->
<div *ngIf="metaFieldSetting && casesList && casesList.length > 0">
    <div class="caseItem" [ngClass]="'caseItem-' + (index % 2)" [class.noBorder]="index === casesList.length - 1"
        fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
        *ngFor="let item of casesList;let index = index" (click)="openCase(item._id)">
        <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" class="dateColumn">
            <div class="titleDate">
                <div class="dateCss">{{ (item.time_of_occurrence || item.createdAt) | date: 'yyyy/MM/dd'}}</div>
                <div class="timeCss">{{ (item.time_of_occurrence || item.createdAt) | date: 'H:mm' }}</div>
            </div>
        </div>
        <div fxFlex="15" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center center">
            <div class="caseDepartment">{{item['department']}}</div>
            <div class="caseType" *ngIf="item['casetype']?.name" [style.background]="item['casetype']?.color || ''"
                [style.color]="!item.casetype?.color || item.casetype?.color == '#ffffff' || item.casetype?.color == 'white' ? 'black' : 'white'">
                {{(item['casetype']?.name || '') | truncate: ['100','...']}}
            </div>
        </div>
        <div fxFlex="35" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center start">
            <div class="caseTitle"><span *ngIf="item.name">{{ item.name }}</span><span *ngIf="!item.name">{{
                    item.case_id }}</span>
            </div>
            <div class="caseAddress">
                <mat-icon style="display: contents;">location_on</mat-icon>
                <span *ngIf="locale === 'en'">{{ item.address }}</span>
                <span *ngIf="locale === 'ja'">{{ item.address_jp }}</span>
            </div>
            <div class="caseAddress">
                <mat-icon style="display: contents;">tag</mat-icon>
                <span>{{ item.case_id }}</span>
            </div>
        </div>
        <div fxFlex="30" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center start">
            <div class="dataContent">
                <div class="caseDescription">{{ 'Description' | translate }}:
                    <div>{{ item.description }}
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center end"
            *ngIf="!isInvestigator">
            <div class="caseEdit" (click)="edit($event,item, index)"><span>{{'Edit' |
                    translate}}</span></div>
            <!-- <button mat-raised-button class="caseEdit" (click)="edit($event,item, index)">{{'Edit' |
                translate}}</button> -->
        </div>
    </div>
</div>