<div mat-dialog-content style="position: relative;">
    <div fxLayout="row" fxLayoutAlign="space-around center"
        style="height:100%; position: absolute; left: 50%; z-index: 999;" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div [style.visibility.hidden]="loading">
        <google-map (tilesloaded)="loaded()" [height]="height" [width]="width" [center]="center" [zoom]="zoom"
            (mapClick)="setPin($event)" [options]="options" *ngIf="center.lat && center.lng">
            <map-marker #markerElem="mapMarker" [position]="marker.position" [label]="marker.label"
                [matTooltip]="marker.title" [options]="marker.options">
            </map-marker>
        </google-map>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="green-text" mat-raised-button color="primary" (click)="onConfirm()"
        *ngIf="data.enableConfirm">{{'Submit' |
        translate}}</button>
    <button class="red-text" mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
</div>