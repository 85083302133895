import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Dictionary } from './interface';
import { ContentType } from './enums';
import { StoreService } from './store.service';
import { Utils } from '../helpers/utils';
import { NgxPermissionsService, NgxRolesService } from '../modules/ngx-permissions';
import { urlSafeBase64Encoding } from '../helpers';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseAuthService } from './firebase-auth.service';
import { LoaderService } from './loader.service';

@Injectable()
export class RequestService {
  public isNetworkConnected: boolean = true;
  private authStandaloneURL = environment.serverLoginUrl;
  private authURL = environment.serverUrl;
  private scheduleURL = environment.scheduleUrl;
  public orgType = environment.orgType;
  private loading: boolean = false;
  private token: any = '';
  private userType: string = 'default';
  public currentPageTitle: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public orgId: string = environment.orgId;
  public appId: string = environment.appId;
  public locId: string = environment.locId;
  public tabId = "";
  public lang = 'en';
  public environmentserverHostUrl = environment.serverHostUrl;
  public serverHostUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  // public formLibModules = ['assessment', 'rules', 'diagnostic'];
  public appStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public authenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public cachedObj = {};
  public currentUserSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public _currentUser: any | undefined = undefined;
  reloadTimeline = new BehaviorSubject(null);
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.userType = currentUser.type || 'default';
      this.store.init('default_' + userObject._id);
      this.setToken(userObject.token);
      this.currentUserSubject.next(userObject);
      // this.store.set('te', 'temp');
      this.permissionsService.flushPermissions();
      this.permissionsService.addPermission(this.userType);
      // for (let res of userObject.resources) {
      //   this.permissionsService.addPermission(res._id);
      // }
    } else {
      this._currentUser = undefined;
      // this.orgId = undefined;
      this.currentUserSubject.next(undefined);
      this.token = '';
      this.userType = 'default';
      this.store.init('default');
      this.permissionsService.flushPermissions();
      // this.permissionsService.flushPermissions();
    }
  }
  get currentUser(): any | undefined {
    return this._currentUser;
  }

  public updatePermissions(userType) {
    this.permissionsService.flushPermissions();
    this.permissionsService.addPermission(userType);
  }
  public updateCurrentUser(newData: any) {
    let objectUser = Object.assign(this._currentUser, newData);
    this.currentUser = objectUser;
  }
  public updateUserData(key, value) {
    let userObject: any = this.currentUserSubject.getValue();
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    userObject[key] = value;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  public updateSetOfUserData(dateList = []) {
    let userObject: any = this.currentUserSubject.getValue();
    for (let itm of dateList) {
      userObject[itm.key] = itm.value;
    }
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  public updateCommonUserData(userDate) {
    let userObject: any = this.currentUserSubject.getValue();
    let defaultFields: string[] = ['firstName', 'lastName', 'phone', 'region', 'prefecture', 'pictureLink', 'badge_no'];
    for (let itm of defaultFields) {
      userObject[itm] = userDate[itm];
    }
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  constructor(private utils: Utils, public store: StoreService, private router: Router, private http: HttpClient, private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService, private dialog: MatDialog, private translate: TranslateService, private firebaseAuthService: FirebaseAuthService, private loader: LoaderService, private location: Location) {
    this.store.init('Anonomous');
    localStorage.setItem('o', JSON.stringify(this.orgId));
    localStorage.setItem('a', JSON.stringify(this.appId));
    localStorage.setItem('l', JSON.stringify(this.locId));
    if (localStorage.getItem('languageSelected')) {
      this.lang = localStorage.getItem('languageSelected');
    }
  }
  public loggedIn() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  public getUserType() {
    return this.userType;
  }
  public getUserId() {
    if (this.currentUser && this.currentUser.hasOwnProperty('_id')) {
      return this.currentUser['_id'];
    } else {
      return '';
    }
  }
  public setToken(token: any) {
    this.token = token;
  }
  public getToken() {
    return this.token;
  }
  public redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  public addLanguageToURL(url: string, lang?: string): string {
    // if (url) {
    //   let langEnd = lang;
    //   if (langEnd === undefined) {
    //     langEnd = 'en';
    //   }
    //   if (~url.indexOf('?')) {
    //     url += '&locale=' + langEnd;
    //   } else {
    //     url += '?locale=' + langEnd;
    //   }
    //   return url;
    // } else {
    //   return '';
    // }
    return url;
  }
  public addLangToURL(url: string, lang?: string): string {
    if (url) {
      let langEnd = lang;
      if (langEnd === undefined) {
        langEnd = 'en';
      }
      if (~url.indexOf('?')) {
        url += '&language=' + langEnd;
      } else {
        url += '?language=' + langEnd;
      }
      return url;
    } else {
      return '';
    }
    // return url;
  }

  public verifyUserEmail(token: string, organizationId: string, appId: string, locationId: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {

    this.token = token;
    let urlStr = this.authURL + 'user/valid/' + organizationId + '/' + appId + '/' + locationId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            if (jsonObj.token) {
              // this.setToken(jsonObj.token);
              //this.token = jsonObj.token;
              //this.store.set('token', jsonObj.token)
            }
            //userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }


  public requestLoginTokenCheck(token: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, refreshToken: string = '', lang?: string) {
    this.token = token;
    let urlStr = this.authURL + 'user/login/bytoken';
    let data = {
      refreshtoken: refreshToken
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            if (jsonObj.token) {
              this.setToken(jsonObj.token);
              this.token = jsonObj.token;
              this.store.set('token', jsonObj.token)
            }
            userObject['token'] = jsonObj.token;
          }
          if (jsonObj.hasOwnProperty('ftoken')) {
            userObject['ftoken'] = jsonObj.ftoken;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', data);
  }

  public getListContains(lst: any[], idKey: string = '_id'): any[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey)) {
        dataList.push(itm[idKey]);
      }
    }
    return dataList;
  }
  public validateMe() {
    try {
      if (localStorage.getItem('currentUser') && localStorage.getItem('o')) {
        let currentStorageUser = JSON.parse(localStorage.getItem('currentUser'));
        let orgId = JSON.parse(localStorage.getItem('o'));
        if (!currentStorageUser || !this.currentUser || (currentStorageUser && this.currentUser && currentStorageUser._id !== this.currentUser._id)) {
          return false;
        }
        if (orgId !== this.orgId) {
          return false;
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }
  public getBrand() {
    let serverHostUrl = this.environmentserverHostUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    let currentHostname = (window.location.hostname).replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    if (currentHostname !== 'localhost' && serverHostUrl !== currentHostname) {
      let remainingLink = currentHostname.replace(serverHostUrl, "");
      remainingLink = remainingLink.replace('.', "");
      return remainingLink;
    }
    return undefined;
  }
  public getDataFromList(lst: any[], idKey: string = '_id'): string[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey)) {
        dataList.push(itm[idKey]);
      }
    }
    return dataList;
  }
  public getDataFromListContains(lst: any[], val: string[], idKey: string = '_id'): any[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && val.includes(itm[idKey])) {
        dataList.push(itm);
      }
    }
    return dataList;
  }
  public getItemFromListContains(lst: any[], val: string, idKey: string = '_id'): any {
    if (lst) {
      for (let itm of lst) {
        if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
          return itm;
        }
      }
    }
    return undefined;
  }
  public getItemFromListContainsDisplay(lst: any[], val: string, idKey: string = '_id', displayName: string = 'name'): any {
    if (lst) {
      for (let itm of lst) {
        if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
          return itm[displayName];
        }
      }
    }
    return '';
  }
  public checkListContains(lst: any[], val: string, idKey: string = '_id'): boolean {
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
        return true;
      }
    }
    return false;
  }
  public logout(showDialog: boolean = false) {
    // console.log('logout');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('o');
    localStorage.removeItem('a');
    localStorage.removeItem('l');
    // localStorage.removeItem('org'); // uncomment this if we let user select org
    localStorage.removeItem('playedTime');
    localStorage.removeItem('played');
    localStorage.removeItem('DND');
    localStorage.removeItem('subscribed-topics');
    sessionStorage.removeItem('played');
    sessionStorage.removeItem('playedTime');
    sessionStorage.removeItem('DND');
    sessionStorage.removeItem('qr');
    this.appStatusSubject.next(undefined);
    this.authenticatedUser.next(false);
    this.currentUser = undefined;

    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister()
        }
      })
    }
    this.firebaseAuthService.signOut().finally(() => {
      this.loader.display(false);
      if (showDialog) {
        this.location.go('/loginWithDialog');
        window.location.reload();
        this.router.navigateByUrl('loginWithDialog');
        // this.router.navigateByUrl('/login').then(() => {
        //   window.location.reload();
        // });
      } else {
        this.location.go('/login');
        window.location.reload();
        this.router.navigateByUrl('login');
        // this.router.navigateByUrl('/login').then(() => {
        //   window.location.reload();
        // });
      }
    });

  }
  public logOutApi() {
    if (!this.loading && this.token !== '') {
      this.loading = true;
      let urlStr = this.authURL + 'user/logout/';
      this.jsonGetRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          // do nothing
        }
        if (jsonObj) {
          this.logout();
        }
        this.loading = false;
      });
    }
  }
  public getMe(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/me';

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          callback(userObject, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    });
  }
  public requestAuthLogin(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend

    let data = {};
    this.token = '';
    // data["email"] = encodeURIComponent(username);
    data["email"] = username;
    data["password"] = encodedPassword;
    let urlStr = this.authStandaloneURL + 'user/login';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = {};
          //this.updatePermissions('general');
          if (jsonObj.hasOwnProperty('token')) {
            userObject['token'] = jsonObj.token;
          }
          if (jsonObj.hasOwnProperty('ftoken')) {
            userObject['ftoken'] = jsonObj.ftoken;
          }
          if (jsonObj.hasOwnProperty('refreshToken')) {
            userObject['refreshToken'] = jsonObj.refreshToken;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', data);
  } public requestLogin(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend

    let data = {};
    this.token = '';
    // data["email"] = encodeURIComponent(username);
    data["email"] = username;
    data["password"] = encodedPassword;
    let urlStr = this.authURL + 'user/login';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          //this.updatePermissions('general');

          if (jsonObj.hasOwnProperty('token')) {
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          if (jsonObj.hasOwnProperty('ftoken')) {
            userObject['ftoken'] = jsonObj.ftoken;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', data);
  }
  public forgotPassword(username: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let urlStr = this.authURL + 'user/forgotpassword';
    let postData = {
      email: username
    }
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (error.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', postData);
  }
  public validateUser(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/valid';
    callback(true, undefined);
    // this.jsonGetRequest(urlStr, (jsonObj, error) => {
    //     if (error !== undefined) {
    //         callback(undefined, error);
    //         return;
    //     }
    //     if (jsonObj) {
    //       if (jsonObj.status) {
    //         callback(true, undefined);
    //       }else {
    //         callback(undefined, jsonObj.message);
    //       }
    //     } else {
    //       callback(undefined, 'Data error from server ');
    //     }
    // });
  }
  private toBase64(stringToSign: string) {
    let base64 = btoa(stringToSign);
    return base64;
  }
  public getMetaData(type: string, fields: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + type + '/metadata';
    if (fields) {
      urlStr = urlStr + '?fields=' + fields;
    }

    // urlStr = this.addLanguageToURL(urlStr, lang);
    urlStr = this.addLangToURL(urlStr, this.lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {

        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public saveData(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.authURL;
      urlStr = urlStr + type + '/' + data._id;
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.authURL;
      urlStr = urlStr + type;

      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public saveDataNoPerfix(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.authURL + type + '/' + data._id;
      // console.log(urlStr);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.authURL + type;
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public signUp(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, useOrg: boolean = false, lang?: string) {
    let urlStr = this.authURL + 'resource/signup';
    if (useOrg) {
      urlStr = urlStr + '/' + this.orgId
    }
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public cropImageByOrg(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/crop/' + this.orgId;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public cropImageByOrgByUsr(userId: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/crop/' + this.orgId + '/' + userId;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = "";
    urlStr = this.authURL + type + '/' + id;

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSingleCachData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + id)) {
        callback(this.cachedObj[type + '/' + id], undefined);
        return;
      }
    }
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj.results;
          }
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  // public getCaseTypeList(term = undefined, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
  //   let urlStr = this.authURL + 'case/type/search';
  //   let data = {
  //     "order": [
  //       {
  //         "field": "case_type",
  //         "order": "asc"
  //       }
  //     ],
  //     "fields": [
  //       "case_type"
  //     ],
  //     "page": 1,
  //     "count": 10,
  //     "term": term,
  //     "termfields": [
  //       "case_type"
  //     ],
  //     "filter": {
  //       "$and": [
  //         {
  //           "organizationId._id": {
  //             "$in": [this.orgId]
  //           }
  //         }
  //       ]
  //     }
  //   }
  //   urlStr = this.addLanguageToURL(urlStr, lang);
  //   this.jsonRequest(urlStr, (jsonObj, error) => {
  //     if (error !== undefined) {
  //       callback(undefined, 'Server Error!');
  //       return;
  //     }
  //     if (jsonObj) {
  //       if (jsonObj.status) {
  //         callback(jsonObj, undefined);
  //       } else {
  //         if (jsonObj.hasOwnProperty('type')) {
  //           this.appStatusSubject.next(jsonObj.type);
  //         }
  //         callback(undefined, jsonObj.message);
  //       }
  //     } else {
  //       callback(undefined, error);
  //     }
  //   }, 'POST', data);
  // }
  public getMySingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deleteSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, checkusability: boolean = false) {
    let urlStr = this.authURL + type + '/' + id + '/delete';
    let objData = undefined;
    if (checkusability) {
      objData = {
        check: checkusability
      };
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteSingleImageData(type: string, id: any, targetDeleteApi: string = 'deletepicture', callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, checkusability: boolean = false) {
    let urlStr = this.authURL + type + '/' + id + '/' + targetDeleteApi;
    let objData = undefined;
    if (checkusability) {
      objData = {
        check: checkusability
      };
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteSingleDataByOrg(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, checkusability: boolean = false) {
    let urlStr = this.authURL + type + '/' + id + '/' + this.orgId + '/delete';
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = undefined;
    if (checkusability) {
      objData = {
        check: checkusability
      };
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteSingleDataByApi(type: string, id: any, targetApi: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/' + targetApi;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST');
  }
  public deleteProfileImage(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/deleteprofile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteImageData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/removeimage';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', {});
  }
  public deleteImageDataByOrg(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/delete/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteImageDataByOrgByUsr(userId: string, type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/delete/' + this.orgId + '/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteBackgroundImageData(type: string, id: string, imageUrl: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/delete/' + id + '/tilebackground';
    let data = {
      type: type,
      imageUrl: imageUrl
    };
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getDataListByGet(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list';
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListByOrgByGet(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListByOrgByGetByUsr(userId: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId + '/' + userId;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListBySelection(type: string, sessionId: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/' + sessionId + '/tilesearch';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');

    let urlStr = this.authURL;

    urlStr = urlStr + source + type + '/search' + sourceTarget;

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByListByOrg(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByListByOrgByUsr(userId: string, type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list/' + this.orgId + '/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListSummary(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL;
    urlStr = urlStr + type + '/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataLByOrg(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + this.orgId)) {
        callback(this.cachedObj[type + '/' + this.orgId], undefined);
        return;
      }
    }
    // let urlStr = this.authURL  + type;
    let urlStr = this.authURL + type + '/list/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + this.orgId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataLByOrgType(datatype: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(datatype + '/' + this.orgId + '/' + type)) {
        callback(this.cachedObj[datatype + '/' + this.orgId + '/' + type], undefined);
        return;
      }
    }
    let urlStr = this.authURL + datatype + '/list/' + this.orgId + '/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[datatype + '/' + this.orgId + '/' + type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataL(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, extraId: string = '', lang?: string, subDataType?: string) {

    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + extraId)) {
        callback(this.cachedObj[type + '/' + extraId], undefined);
        return;
      }
    }

    // let urlStr = this.authURL  + type;
    // let urlStr = this.authURL + type + '/list';
    let urlStr = this.authURL + type;
    if (subDataType === undefined) {

      urlStr = urlStr + '/list';
    }
    else {

      urlStr = urlStr + '/' + subDataType + '/list';
    }
    if (extraId) {
      urlStr = urlStr + '/' + extraId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + extraId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'user/search';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getInvestigatorsList(caseId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/investigators/' + caseId + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getCamerasList(caseId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/camera/' + caseId + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getCamerasGroupList(caseId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/cameragroup/' + caseId + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getInvestigatorGroupList(caseId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/investigatorgroup/' + caseId + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public assignInvestigators(cmId: any, data: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/investigators/' + cmId + '/assign';
    // urlStr = this.addLanguageToURL(urlStr, lang);

    let investigators = data.filter((itm) => !itm.isInvestigatorGroup);
    let investigatorGroups = data.filter((itm) => itm.isInvestigatorGroup);

    let fieldsToRemove = ['isInvestigatorGroup', 'selected'];
    // Function to remove fields from each object
    investigators = investigators.map(obj => {
      fieldsToRemove.forEach(field => {
        delete obj[field];
      });
      return obj;
    });
    investigatorGroups = investigatorGroups.map(obj => {
      fieldsToRemove.forEach(field => {
        delete obj[field];
      });
      return obj;
    });

    let objectpost = {
      investigators: investigators,
      investigatorgroup: investigatorGroups
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objectpost);
  }
  public assignCameras(cmId: any, data: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/camera/' + cmId + '/assign';
    // urlStr = this.addLanguageToURL(urlStr, lang);
    let selectedData = data.map((itm) => {
      if (itm.isCameraGroup) {
        return { _id: itm._id, name: itm.name, isCameraGroup: itm.isCameraGroup };
      } else {
        return { _id: itm._id, name: itm.name, invisible: itm.invisible };
      }
    });
    let cameras: any[] = selectedData.filter((itm) => !itm.isCameraGroup);
    let cameraGroups: any[] = selectedData.filter((itm) => itm.isCameraGroup);

    cameraGroups = cameraGroups.map((itm) => {
      return { _id: itm._id, name: itm.name };
    });
    let objectpost = {
      cameras: cameras,
      cameragroups: cameraGroups
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objectpost);
  }
  public assignCameraToCaseData(cameraId: string, caseId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'case/assigncamera';
    let objectpost = {
      "case": {
        "_id": caseId
      },
      "camera": {
        "_id": cameraId
      }
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objectpost);
  }
  public generatePlate(filename: any, data: any[], cameraId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    urlStr = urlStr + 'carplate/sdk/create';
    // urlStr = this.addLanguageToURL(urlStr, lang);
    let objectpost = {
      datas: data,
      filename: filename,
      camera_id: cameraId,
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objectpost);
  }
  public getMyUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'my/user/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getUser(userId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public registeruser(user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/register';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public editUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public confirmUserPassword(email: string, confirmationCode: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/confirm/forgotpassword';
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { email: email.trim(), confirmationCode: confirmationCode, newPassword: encodedNewPassword };

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user, ContentType.JSON, 10.0, false, 1.5, 60.0);
  }
  public editUserPassword(password: any, oldPassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + "user/" + this.currentUser._id + '/changepassword';

    let user = { newPassword: urlSafeBase64Encoding(password), currentPassword: urlSafeBase64Encoding(oldPassword) }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public editCurrentUserPassword(password: any, oldPassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + "user/changepassword";

    let user = { newpassword: urlSafeBase64Encoding(password), oldpassword: urlSafeBase64Encoding(oldPassword) }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public resetUserPassword(userId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resetpassword/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public requestPassword(useremail: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'requestpassword/' + useremail;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public editSelfUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'userself/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public saveDataToClass(classId: any, datatype: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + datatype + '/' + classId + '/save';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public buildSearchRequestSToAPI(conf: any, token: string = '', addCustomData: boolean = true): {} {
    let searchRequestGeneric: any = {
      count: conf.perpage || 10
    };
    if (conf.perpage) {
      searchRequestGeneric['count'] = conf.perpage || 10;
    }
    if (searchRequestGeneric.count === -1) {
      delete searchRequestGeneric.count;
    }
    if (conf.orderBy && conf.orderDir) {
      searchRequestGeneric['order'] = [];
      searchRequestGeneric['order'].push({ field: conf.orderBy, order: conf.orderDir });
    }
    if (conf.order) {
      searchRequestGeneric['order'] = conf.order;
    }
    let fieldList: string[] = [];
    if (conf.hasOwnProperty('fieldKeys')) {
      fieldList = conf['fieldKeys'];
    }
    if (fieldList.length > 0) {
      searchRequestGeneric['fields'] = fieldList;
    }
    if (conf.hasOwnProperty('term') && conf['term'] !== undefined) {
      searchRequestGeneric['term'] = conf['term'] || '';
    }
    if (conf.hasOwnProperty('termfields') && conf['termfields'] !== undefined) {
      searchRequestGeneric['termfields'] = conf['termfields'] || '';
    }
    let filterList = {};
    if (conf.customData && addCustomData) {
      if (Object.keys(conf.customData).length > 0) {
        for (let field of Object.keys(conf.customData)) {
          if (field)
            filterList[field] = { op: 'eq', value: conf.customData[field] };
        }
      }
    }
    if (conf.filterFieldKey) {
      for (let field of conf.filterFieldKey) {
        if (field) {
          filterList[field.field] = { op: field.op, value: field.search };
          if (field.type && field.type === 'number') {
            filterList[field.field].value = Number(filterList[field.field].value);
          }
        }
      }
    }
    if (Object.keys(filterList).length > 0) {
      searchRequestGeneric['filter'] = filterList;
    }
    if (conf.hasOwnProperty('filter')) {
      searchRequestGeneric['filter'] = conf.filter;
    }
    if (conf.hasOwnProperty('page')) {
      searchRequestGeneric['page'] = conf.page;
    }
    if (token !== '') {
      searchRequestGeneric['paginationToken'] = token;
    }
    if (conf.hasOwnProperty('include') && conf['include'] !== undefined) {
      searchRequestGeneric['include'] = conf['include'] || [];
    }
    if (conf.hasOwnProperty('exclude') && conf['exclude'] !== undefined) {
      searchRequestGeneric['exclude'] = conf['exclude'] || [];
    }
    if (conf.hasOwnProperty('organizationId') && conf['organizationId'] !== undefined) {
      searchRequestGeneric['organizationId'] = conf['organizationId'] || '';
    }
    return searchRequestGeneric;
  }
  private getCorrectUrl(urlString) {
    let urlCorrectString = urlString;
    //check if it is a formLib module
    // for (let itm of this.formLibModules) {
    //   if (urlString.startsWith(this.authURL + itm)) {
    //     urlCorrectString = urlCorrectString.replace(this.authURL + itm, this.formLibrariesURL + itm);
    //   }
    // }
    // if (urlString.startsWith(this.authURL + 'event')) {
    //   urlCorrectString = urlCorrectString.replace(this.authURL + 'event', this.scheduleURL + 'event');
    //   return urlCorrectString || '';
    // }
    return urlCorrectString || '';
  }
  private urlEncode(str: string): string {
    return encodeURI(str);
  }
  private jsonRequestSimple(urlString: string, callback: (json?: any, error?: any) => void, params: Dictionary, timeout: number = 60.0) {
    let body;
    if (params) {
      body = params;
    } else {
      // we need to recheck this
      //console.log('Parameters sent to jsonRequestSimple are not serializable into JSON');
    }
    this.jsonRequest(urlString, (json, error) => {
      callback(json, error);
    }, 'POST', body, ContentType.JSON, timeout);
  }
  public jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      this.jsonRequest(urlComps, (json, error) => {
        callback(json, error);
      }, 'GET');
    } else {
      return;
    }
  }
  public jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0) {
    if (urlString) {
      let url: string = this.getCorrectUrl(urlString);
      let headers = {
        'Content-Type': contentType,
        'vertical': this.orgType,
        'Accept': 'application/json',
        group: 'web',
      };

      headers['lang'] = this.lang;
      headers['project'] = environment.projectName;
      headers['vertical'] = this.orgType;
      headers['productid'] = environment.productId;
      if (this.orgId && this.orgId !== '') {
        headers['organizationid'] = this.orgId;
        headers['integratedid'] = this.orgId;
      }
      if (this.token) {
        headers['Authorization'] = this.token;
      }
      let httpOptions: any = {
        responseType: 'json',
        headers: new HttpHeaders(headers),
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {

        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            this.updateToken(data);
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, this.translate.instant('Refresh page'));
              } else if (err.status >= 400) {
                if (this.currentUser) {
                  try {
                    let jsonErr = err.json();
                    if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                      this.appStatusSubject.next(jsonErr.type);
                    } else {
                      callback(undefined, this.translate.instant('Connectivity issue.'));
                    }
                  } catch (e1) {
                    try {
                      if (err.hasOwnProperty('error')) {
                        let jsonErr = err.error;
                        if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                          this.appStatusSubject.next(jsonErr.type);
                        } else {
                          callback(undefined, this.translate.instant('Connectivity issue.'));
                        }
                      } else {
                        callback(undefined, this.translate.instant('Connectivity issue.'));
                      }
                    } catch (e2) {
                      callback(undefined, this.translate.instant('Connectivity issue.'));
                    }
                  }
                } else {
                  callback(undefined, this.translate.instant('Connectivity issue.'));
                }
              } else {
                callback(undefined, err);
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }
  public onUploadUserImage(browsed_file: any, userId: string): Observable<{}> {

    let headers = {
      'Accept': 'application/json',
      group: 'web',
    };
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    if (this.token) {
      headers['Authorization'] = this.token;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.authURL + 'user/image/upload/' + userId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadCarplateImage(browsed_file: any): Observable<{}> {

    let headers = {
      'Accept': 'application/json',
      group: 'web',
    };
    headers['lang'] = this.lang;
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    if (this.token) {
      headers['Authorization'] = this.token;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.authURL + 'carplate/file/create';
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadUserTranscript(browsed_file: any, userId: string, transcript: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType,
      group: 'web',
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'transcript/upload/' + userId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    formData.append('year', transcript.year);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public loadUserGroupsData(id: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'investigatorgroup/user/' + id + '/get';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public selectImageGallery(type: string, id: string, imageId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/copy/image/' + id + '/' + imageId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public onUploadFiles(browsed_file: any, region: string = '', prefecture: string = '', type: string = '', isEncoded: string = 'false'): Observable<{}> {
    let headers = {
      'Accept': 'application/json',
      group: 'web',
    };
    headers['lang'] = this.lang;
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    if (this.token) {
      headers['Authorization'] = this.token;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload';
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.name);
    formData.append('region', region);
    formData.append('prefecture', prefecture);
    formData.append('type', type);
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFilesByPath(path: string, browsed_file: any): Observable<{}> {
    let headers = {
      'Accept': 'application/json',
      group: 'web',
    };
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    if (this.token) {
      headers['Authorization'] = this.token;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + path;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFilesByUsr(userId: string, browsed_file: any, folder: string = '', type: string = '', isEncoded: string = 'false'): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType,
      group: 'web',
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/resource/upload/' + this.orgId + '/' + userId;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('folder', folder);
    formData.append('type', type);
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public isUserRoleAdmin(): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
        return true;
    }
    return false;
  }
  public isUserRoleCommander(): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] == (environment.customKeys.rolePrefectureCommanders || environment.customKeys.roleRegionCommanders)))
        return true;
    }
    return false;
  }
  public isUserRoleInvestigator(): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] == environment.customKeys.roleInvestigators))
        return true;
    }
    return false;
  }
  public isUserRoles(roles: any[]): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => roles.includes(role['_id'])))
        return true;
    }
    return false;
  }
  public getUserRole(): string {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].length > 0)
        return user['resources'][0]['_id'];
    }
    return undefined;
  }

  public getUserRoleByText(): string {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
        return 'Admin';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleInvestigators))
        return 'Investigator';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.rolePrefectureCommanders))
        return 'Commander';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleRegionCommanders))
        return 'Commander';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleTechnicians))
        return 'Technician';
      return '';
    }

    return '';
  }
  // public getUserRole(): string {
  //   let user = this.currentUser;
  //   if (user) {
  //     if (user['resources'].find(role => role['_id'] == environment.customKeys.roleCaseManager))
  //       return 'edit';
  //     else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleClient))
  //       return 'view';
  //     else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
  //       return 'admin';
  //   }

  //   return 'anonymous';
  // }
  public getUserRoleByUserData(user): string {
    // if (user) {
    //   if (user['resources'].find(role => role['_id'] == environment.customKeys.roleEdit))
    //     return 'edit';
    //   else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleView))
    //     return 'view';
    //   else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
    return 'admin';
    // }
    // return 'anonymous';
  }
  public getUserClassRole(classData: any): string {
    return 'anonymous';
  }
  // public getUserRoomRole(roomData: any): string {
  //   if (roomData.hasOwnProperty('owner')) {
  //     for (let dt of roomData.owner) {
  //       if (this.currentUser && dt._id === this.currentUser._id) {
  //         return 'owner';
  //       }
  //     }
  //   }
  //   return this.getUserRole();
  // }
  // public getUserSessionRole(roomData: any): string {
  //   if (roomData.hasOwnProperty('users')) {
  //     for (let dt of roomData.users) {
  //       if (this.currentUser && dt._id === this.currentUser._id) {
  //         return dt.type;
  //       }
  //     }
  //   }
  //   if (this.getUserRole() === 'admin') {
  //     return 'admin';
  //   }
  //   return 'anonymous';
  // }

  // public getUserRoleByUserDataNoOrg(user): string {
  //   if (user) {
  //     if (user['resources'].find(role => role['_id'] == environment.customKeys.roleCaseManager))
  //       return 'edit';
  //     else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleClient))
  //       return 'view';
  //     else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
  //       return 'admin';
  //   }
  //   return 'anonymous';
  // }
  // public getSessionRoleByType(sessionData: any, type): any[] {
  //   let userList = [];
  //   if (sessionData.hasOwnProperty('users')) {
  //     for (let dt of sessionData.users) {
  //       if (dt.type === type) {
  //         userList.push(dt);
  //       }
  //     }
  //   }
  //   return userList;
  // }
  // public getSessionRoleByUser(sessionData: any, userId: string): string {
  //   if (sessionData.hasOwnProperty('users')) {
  //     for (let dt of sessionData.users) {
  //       if (dt._id === userId) {
  //         return dt.type;
  //       }
  //     }
  //   }
  //   return 'anonymous';
  // }
  postRequest(type: string, subType: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + type + '/' + subType;
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type') && jsonObj.type === 'login') {
            this.appStatusSubject.next(jsonObj.type);
            this.logout();
          }
          else if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }

  getRequest(type: string, subType: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + type + '/' + subType;
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type') && jsonObj.type === 'login') {
            this.appStatusSubject.next(jsonObj.type);
            this.logout();
          }
          else if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'GET');
  }

  getSessionHost(sessionData: any): string {
    if (sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt.host) {
          return dt._id;
        }
      }
    }
    return undefined;
  }
  public isUserRoleSuperAdmin(): boolean {
    let user = this.currentUser;
    if (user && user.isSuperAdmin) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return true;
    }
    // if (user && user.email === 'amerghalayini@interactivelife.com') {
    //   return true;
    // }
    return false;
  }
  public onUploadFilesByPathNew(
    path: string,
    browsed_file: any,
    type: string = undefined,
    isEncoded: string = undefined,
    id: string = undefined,
    folder: string = undefined,
    addName: boolean = true
  ): Observable<HttpEvent<any>> {
    let headers = {
      Authorization: this.token,
      Accept: 'application/json',
      group: 'web',
    };
    headers['lang'] = this.lang;
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions: any = {
      headers: new HttpHeaders(headers),
      reportProgress: true,
      responseType: 'json',
    };
    let url = this.authURL + path;
    const formData: FormData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    if (addName) {
      formData.append('name', browsed_file.text);
    }
    if (folder !== undefined) {
      formData.append('folder', folder);
    }
    if (type !== undefined) {
      formData.append('type', type);
    }
    if (id !== undefined) {
      formData.append('id', id);
    }
    if (isEncoded !== undefined) {
      formData.append('isEncoded', isEncoded);
    }

    const req = new HttpRequest('POST', url, formData, httpOptions);
    return this.http.request(req);
  }
  public updateToken(jsonObj: any) {
    let userObject = this.currentUser;
    if (jsonObj.hasOwnProperty('token')) {
      if (jsonObj.token) {
        this.setToken(jsonObj.token);
        this.store.set('token', jsonObj.token);
      }
      if (userObject) {
        userObject['token'] = jsonObj.token;
        localStorage.setItem('currentUser', JSON.stringify(userObject));
        this.currentUserSubject.next(userObject);
      }
    }
  }

  public getUserInfoByEmail(email: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/validate/superadmin?email=' + encodeURIComponent(email.trim());
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getImageBase64(url: string, callback: (base64: string | null) => void): void {
    this.http.get(url, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => callback(reader.result as string);
        reader.onerror = () => callback(null);
        reader.readAsDataURL(blob);
      },
      () => callback(null)
    );
  }

  public getRecord(
    id: string,
    type: string,
    callback: (
      dataResponse: any | undefined,
      requestError: any | undefined
    ) => void
  ) {
    let urlStr = this.authURL + type;
    if (id !== '') {
      urlStr = urlStr + '/' + id;
    }
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public onUploadFile(browsed_file: any, caseId: string, type: string, title: string, note: any): Observable<{}> {
    let headers = {
      'Accept': 'application/json',
      group: 'web',
      lang: this.lang,
    };
    headers['project'] = environment.projectName;
    headers['vertical'] = this.orgType;
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    if (this.token) {
      headers['Authorization'] = this.token;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }

    let url = this.authURL + 'doc/upload';
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', title);
    formData.append('type', type);
    formData.append('caseId', caseId);
    if (note.user)
      formData.append('note_user', note.user);
    if (note.geolocation)
      formData.append('note_geolocation', note.geolocation);
    if (note.name)
      formData.append('note_name', note.name);
    if (note.type)
      formData.append('note_type', note.type);
    if (note.location)
      formData.append('note_location', note.location);

    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return (jsonObj);
          } else {
            return ({ status: false, message: this.translate.instant('Created successfully') });
          }
        } else {
          return ({ status: false, message: this.translate.instant('An error has occurred. Please try again later.') });
        }
      }));
  }

  public sendAnroidFirebaseNotification(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (data.deviceToken && data.deviceToken.length > 0) {
      let urlStr = this.authURL + 'notification/firebase/android';

      if (!data['webPush']) {
        data["webPush"] = {
          "notification": {
            "icon": "/assets/images/notification-logo.png"
          },
          "headers": {
            "TTL": "300"
          }
        };
      }
      if (!data["fcmOptions"]) {
        data["fcmOptions"] = {
          "link": environment.serverMainUrl
        };
      }

      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, error);
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }

  public sendWebFirebaseNotification(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (data.deviceToken && data.deviceToken.length > 0) {
      let urlStr = this.authURL + 'notification/firebase';

      if (!data['webPush']) {
        data["webPush"] = {
          "notification": {
            "icon": "/assets/images/notification-logo.png"
          },
          "headers": {
            "TTL": "300"
          }
        };
      }
      if (!data["fcmOptions"]) {
        data["fcmOptions"] = {
          "link": environment.serverMainUrl
        };
      }

      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, error);
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }

  dataURLToBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  dataImageToFile(dataURL: string, filename: string): File {
    const blob = this.dataURLToBlob(dataURL);
    return new File([blob], filename, { type: blob.type });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  dataURItoFile(dataUrl: string): File {
    const parts = dataUrl.split(',');
    // const mimeMatch = parts[0].match(/:(.*?);/);
    // const mime = mimeMatch ? mimeMatch[1] : 'image/png'; // default to PNG if not found
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new File([uint8Array], `face_${Date.now()}_${Math.random().toString(36).substring(2, 8)}.png`, { type: 'image/png' });
  }

  downloadFile(file: File) {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Clean up memory
  }

  register(userObj: any, faceImage: any) {
    const formData = new FormData();

    // If faceImage is base64, you may want to convert it to a Blob
    // const byteString = atob(faceImage.split(',')[1]);
    // const arrayBuffer = new ArrayBuffer(byteString.length);
    // const intArray = new Uint8Array(arrayBuffer);
    // for (let i = 0; i < byteString.length; i++) {
    //   intArray[i] = byteString.charCodeAt(i);
    // }
    // const blob = new Blob([intArray], { type: 'image/png' });
    // let blob = this.dataURItoFile(faceImage);
    // this.downloadFile(faceImage);
    // debugger;
    formData.append('face_image', faceImage);
    formData.append('firstName', userObj.firstName);
    formData.append('lastName', userObj.lastName);
    formData.append('badge_no', userObj.badge_no);
    formData.append('email', userObj.email);
    formData.append('organizationId', this.orgId);
    formData.append('locationId', this.locId);
    formData.append('appId', this.appId);
    formData.append('prefecture', userObj.prefecture);
    formData.append('region', userObj.region);
    formData.append('resourcesId', userObj.resources);
    formData.append('division', userObj.division);

    const headers = {
      Accept: '*/*',
      lang: this.lang,
      project: environment.projectName,
      productid: environment.productId,
      organizationid: this.orgId,
      integratedid: this.orgId,
      Authorization: this.token,
      group: 'web',
    };

    const httpOptions: any = {
      responseType: 'json',
      headers: new HttpHeaders(headers),
    };

    return this.http.post(
      this.authURL + 'user/create/v2',
      formData,
      httpOptions
    ).pipe(map((response: any) => {
      if (response) {
        return response.status
          ? response
          : { status: false, message: response.message };
      }
      return { status: false, message: 'No response from server.' };
    }));
  }

  // register(userObj: any, faceImage: string) {
  //   let postData = {
  //     face_image: faceImage,
  //     firstName: userObj.firstName,
  //     lastName: userObj.lastName,
  //     badge_no: userObj.badge_no,
  //     email: userObj.email,
  //     organizationId: this.orgId,
  //     locationId: this.locId,
  //     appId: this.appId,
  //     prefecture: userObj.prefecture,
  //     region: userObj.region,
  //     resourcesId: userObj.resources,
  //     division: userObj.division,
  //   }

  //   let headers = {
  //     Accept: '*/*',
  //     lang: this.lang,
  //     project: environment.projectName,
  //     productid: environment.productId,
  //     organizationid: this.orgId,
  //     integratedid: this.orgId,
  //     Authorization: this.token,
  //     group: 'web',
  //   };

  //   let httpOptions: any = {
  //     responseType: 'json',
  //     headers: new HttpHeaders(headers),
  //     method: 'POST'
  //   }

  //   return this.http.post(this.authURL + 'user/rekognition/create/v2',
  //     postData, httpOptions).pipe(map((response: any) => {
  //       let jsonObj = response;
  //       if (jsonObj) {
  //         if (jsonObj.status) {
  //           return jsonObj;
  //         } else {
  //           return { status: false, message: jsonObj.message };
  //         }
  //       } else {
  //         return { status: false, message: jsonObj.message };
  //       }
  //     }));
  // }

  // registerByQR(file: File, userObj: any) {
  //   let formData = new FormData();
  //   formData.append('face_image', file);
  //   formData.append('firstName', userObj.firstName);
  //   formData.append('lastName', userObj.lastName);
  //   formData.append('badge_no', userObj.badge_no);
  //   formData.append('email', userObj.email);
  //   formData.append('organizationId', this.orgId);
  //   formData.append('locationId', this.locId);
  //   formData.append('appId', this.appId);
  //   formData.append('prefecture', userObj.prefecture);
  //   formData.append('region', userObj.region);
  //   formData.append('resourcesId', userObj.resources);
  //   formData.append('division', userObj.division);

  //   let headers = {
  //     // 'Content-Type': 'multipart/form-data',
  //     Accept: '*/*',
  //     lang: this.lang,
  //     project: environment.projectName,
  //     productid: environment.productId,
  //     organizationid: this.orgId,
  //     integratedid: this.orgId,
  //     Authorization: this.token,
  //     group: 'web',
  //   };

  //   let httpOptions: any = {
  //     responseType: 'json',
  //     headers: new HttpHeaders(headers),
  //     method: 'POST'
  //   }

  //   return this.http.post(this.authURL + 'user/qr/create',
  //     formData, httpOptions).pipe(map((response: any) => {
  //       let jsonObj = response;
  //       if (jsonObj) {
  //         if (jsonObj.status) {
  //           return jsonObj;
  //         } else {
  //           return { status: false, message: jsonObj.message };
  //         }
  //       } else {
  //         return { status: false, message: jsonObj.message };
  //       }
  //     }));
  // }

  login(badgeNumber: string, faceImage: any) {
    let blob = this.dataURItoFile(faceImage);

    let formData = new FormData();
    formData.append('badge_no', badgeNumber);
    formData.append('face_image', blob);

    let headers = {
      Accept: '*/*',
      lang: this.lang,
      project: environment.projectName,
      productid: environment.productId,
      organizationid: this.orgId,
      integratedid: this.orgId,
      group: 'web',
    };

    let httpOptions: any = {
      responseType: 'json',
      headers: new HttpHeaders(headers),
    }

    return this.http.post(this.authURL + 'user/login/v2',
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            let userObject = {};

            if (jsonObj.hasOwnProperty('token')) {
              this.setToken(jsonObj.token);
              userObject['token'] = jsonObj.token;
            }
            if (jsonObj.hasOwnProperty('ftoken')) {
              userObject['ftoken'] = jsonObj.ftoken;
            }
            if (jsonObj.hasOwnProperty('refreshToken')) {
              userObject['refreshToken'] = jsonObj.refreshToken;
            }
            return ({ status: true, results: userObject });
          } else {
            if (jsonObj.hasOwnProperty('return')) {
              return ({ status: false, message: jsonObj.message, return: jsonObj.return });
            } else {
              return ({ status: false, message: jsonObj.message });
            }
          }
        } else {
          return { status: false, message: jsonObj.message };
        }
      }));
  }

  loginByQRAuth(qrData: string, file: File) {
    let formData = new FormData();
    formData.append('face_image', file);
    formData.append('qr_data', qrData);

    let headers = {
      Accept: '*/*',
      lang: this.lang,
      project: environment.projectName,
      productid: environment.productId,
      organizationid: this.orgId,
      integratedid: this.orgId,
      group: 'web',
    };

    let httpOptions: any = {
      responseType: 'json',
      headers: new HttpHeaders(headers),
      method: 'POST'
    }

    return this.http.post(this.authStandaloneURL + 'user/qr/login',
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            let userObject = {};

            if (jsonObj.hasOwnProperty('token')) {
              this.setToken(jsonObj.token);
              userObject['token'] = jsonObj.token;
            }
            if (jsonObj.hasOwnProperty('ftoken')) {
              userObject['ftoken'] = jsonObj.ftoken;
            }
            if (jsonObj.hasOwnProperty('refreshToken')) {
              userObject['refreshToken'] = jsonObj.refreshToken;
            }
            return ({ status: true, results: userObject });
          } else {
            if (jsonObj.hasOwnProperty('return')) {
              return ({ status: false, message: jsonObj.message, return: jsonObj.return });
            } else {
              return ({ status: false, message: jsonObj.message });
            }
          }
        } else {
          return { status: false, message: jsonObj.message };
        }
      }));
  }
  loginByQR(qrData: string, file: File) {
    let formData = new FormData();
    formData.append('face_image', file);
    formData.append('qr_data', qrData);

    let headers = {
      Accept: '*/*',
      lang: this.lang,
      project: environment.projectName,
      productid: environment.productId,
      organizationid: this.orgId,
      integratedid: this.orgId,
      group: 'web',
    };

    let httpOptions: any = {
      responseType: 'json',
      headers: new HttpHeaders(headers),
      method: 'POST'
    }

    return this.http.post(this.authURL + 'user/qr/login',
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            let userObject = jsonObj.results;

            if (jsonObj.hasOwnProperty('token')) {
              this.setToken(jsonObj.token);
              userObject['token'] = jsonObj.token;
            }
            if (jsonObj.hasOwnProperty('ftoken')) {
              userObject['ftoken'] = jsonObj.ftoken;
            }
            return ({ status: true, results: userObject });
          } else {
            if (jsonObj.hasOwnProperty('return')) {
              return ({ status: false, message: jsonObj.message, return: jsonObj.return });
            } else {
              return ({ status: false, message: jsonObj.message });
            }
          }
        } else {
          return { status: false, message: jsonObj.message };
        }
      }));
  }

  // reissueQR(badgeno: string, prefecture: string, file: File) {
  //   let formData = new FormData();
  //   formData.append('face_image', file);
  //   formData.append('badge_no', badgeno);
  //   formData.append('prefecture', prefecture);

  //   let headers = {
  //     Accept: '*/*',
  //     lang: this.lang,
  //     project: environment.projectName,
  //     productid: environment.productId,
  //     organizationid: this.orgId,
  //     integratedid: this.orgId,
  //   };

  //   let httpOptions: any = {
  //     responseType: 'json',
  //     headers: new HttpHeaders(headers),
  //     method: 'POST'
  //   }

  //   return this.http.post(this.authURL + 'user/qr/reissue',
  //     formData, httpOptions).pipe(map((response: any) => {
  //       let jsonObj = response;
  //       if (jsonObj) {
  //         if (jsonObj.status) {
  //           return ({ status: true, results: jsonObj.results });
  //         } else {
  //           return ({ status: false, message: jsonObj.message });
  //         }
  //       } else {
  //         return { status: false, message: jsonObj.message };
  //       }
  //     }));
  // }

  reissueQR(badgeno: string, prefecture: string, descriptors: string) {
    let headers = {
      Accept: '*/*',
      lang: this.lang,
      project: environment.projectName,
      productid: environment.productId,
      organizationid: this.orgId,
      integratedid: this.orgId,
    };

    let httpOptions: any = {
      responseType: 'json',
      headers: new HttpHeaders(headers),
      method: 'POST'
    }

    return this.http.post(this.authURL + 'user/qr/reissue/v1',
      {
        badge_no: badgeno,
        prefecture: prefecture,
        descriptors: descriptors,
      }, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return ({ status: true, results: jsonObj.results });
          } else {
            return ({ status: false, message: jsonObj.message });
          }
        } else {
          return { status: false, message: jsonObj.message };
        }
      }));
  }
}
