<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content *ngIf="dataType" style="padding:10px">
		<app-custom-metadata-table #customMetadataTable [dataType]="dataType" [tableName]="tableName"
			[fieldsToHide]="fieldsToHide" [dataTypeDisplay]="dataTypeDisplay"
			[dataTypeSingleDisplay]="dataTypeSingleDisplay" [tableSetting]="tableSetting" [loading]="loading"
			[title]="'Cameras list'" [targetName]="targetName" (onClickBtn)="toggleClick($event)" [canNew]="canNew"
			[canFilter]="canFilter" [canExport]="canExport" [canImport]="canImport" [customFilter]="filterObject"
			[pageSize]="5" (onSelectFilter)="selectFilter($event)" [orderBy]="orderBy" [orderDir]="orderDir"
			[orderByList]="orderByList" [canSearch]="true" [showTopRightBtn]="true" [focusField]="focusField">
		</app-custom-metadata-table>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">

				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
	</div>
	<br />
</div>