import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms"; import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
;
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ConfirmEntityDialogComponent, GoogleMapsComponent, LayoutUtilsService, LoaderService, RequestService } from "src/app/shared";
import { AblyService } from "src/app/shared/services/ably.service";
import { CaseService } from "src/app/shared/services/case.service";
import { DocumentUploadDialogComponent } from "../../documents-dialog/document-upload-dialog/document-upload-dialog.component";
import { Observable, debounceTime, from, map, startWith, switchMap } from "rxjs";
import { CustomCreateTableDialogComponent } from "src/app/shared/components/custom-create-table-dialog/custom-create-table-dialog.component";
import { CustomSelectNewComponent } from "src/app/shared/components/custom-select-new/custom-select-new.component";

interface DialogData {
    caseId: string;
    userId: string;
    metadata: any;
    title: string;
}

@Component({
    selector: 'app-edit-investigator-map-dialog',
    templateUrl: './edit-investigator-map-dialog.component.html',
    styleUrls: ['./edit-investigator-map-dialog.component.scss']
})
export class EditInvestigatorMapDialogComponent implements OnInit, OnDestroy {
    public subscriptions: any[] = <any>[];

    public changeOccured: boolean = false;
    public isSubmitted: boolean = false;
    public userMapData: any = undefined;
    public type: string = 'note';
    public form: FormGroup;
    public metadata = new Map();
    public remarks = '';
    public timeline_visibility = 'filtered';
    public squad = undefined;
    public squadDisplay = '';
    public call_sign = '';
    // public filteredSquad: any = [];
    // private isSquadOptionSelected = false;


    @ViewChild('squadSelect') squadSelect: CustomSelectNewComponent;
    constructor(public dialogRef: MatDialogRef<EditInvestigatorMapDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private loader: LoaderService, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private formBuilder: FormBuilder, private dialog: MatDialog, private ablyService: AblyService, private caseService: CaseService,
        @Inject(MAT_DATE_LOCALE) public _locale: string, private _adapter: DateAdapter<any>) {
        if (localStorage.getItem('languageSelected')) {
            this._locale = localStorage.getItem('languageSelected');
        } else {
            this._locale = 'en';
        }
        this._adapter.setLocale(this._locale);
    }

    ngOnInit() {
        // this.form = this.formBuilder.group({
        //     squad: [this.squadDisplay || ''],
        //     call_sign: [this.call_sign || ''],
        //     remarks: [this.remarks || ''],
        // });
        // this.form.get('squad').valueChanges.pipe(
        //     debounceTime(300),
        // ).subscribe(value => {
        //     if (!this.isSquadOptionSelected) {
        //         from(this._filterSquad(value || ''));
        //     }
        //     this.isSquadOptionSelected = false;
        // });
        this.data.metadata.fields.forEach((field: any) => {
            this.metadata.set(field.name, field);
        });
        this.fetchUserMapData();
    }
    prepareForm() {
        this.form = this.formBuilder.group({
            call_sign: [this.call_sign || ''],
            remarks: [this.remarks || ''],
            timeline_visibility: [this.timeline_visibility || ''],
        });
        // this.subscriptions.push(this.form.get('squad').valueChanges.pipe(
        //     debounceTime(300),
        // ).subscribe(value => {
        //     if (!this.isSquadOptionSelected) {
        //         from(this._filterSquad(value || ''));
        //     }
        //     this.isSquadOptionSelected = false;
        // }));
    }
    async fetchUserMapData() {
        let investigatorCaseData = [];
        let dataInvCaseMapPromise = this.caseService.getInvestigatorCaseData(this.data.caseId, this.data.userId).then((data: any) => {
            let dataResult = [];
            if (data.results) {
                dataResult = data.results;
            }
            investigatorCaseData = dataResult;
        });
        await Promise.all([dataInvCaseMapPromise]).then(_ => { }).catch(e => {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + e, 'Dismiss');
        }).finally(() => {
            if (investigatorCaseData.length > 0) {
                this.userMapData = investigatorCaseData[0];
                this.remarks = this.userMapData.remarks;
                this.timeline_visibility = this.userMapData.timeline_visibility || 'filtered';
                this.squad = this.userMapData.squad_ref;
                this.squadDisplay = this.userMapData.squad_ref?.name || '';
                this.call_sign = this.userMapData.call_sign;
            }
            this.prepareForm();
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    save() {
        this.isSubmitted = true;
        if (this.form.valid) {
            this.loader.display(true);
            let squad = this.squad;
            // if (squad.name != this.form.controls.squad.value) {
            //     squad = { _id: '', name: this.form.controls.squad.value }
            // }
            if (squad && !squad._id) {
                squad = undefined;
            }
            this.requestService.postRequest('caseusermapping', 'insertupdate', {
                call_sign: this.form.controls.call_sign.value,
                remarks: this.form.controls.remarks.value,
                squad_ref: squad,
                timeline_visibility: this.form.controls.timeline_visibility.value,
                case: {
                    _id: this.data.caseId
                },
                user: {
                    _id: this.data.userId
                },
            }, (data, error) => {
                if (error) {
                    this.layoutUtilsService.showNotification(this.translate.instant('An error has occurred. Please try again later.'), this.translate.instant('Dismiss'));
                }
                if (data && data.results) {
                    this.layoutUtilsService.showNotification(this.translate.instant('Updated successfully'), this.translate.instant('Dismiss'));
                    this.closeModal(data.results);
                }
                this.loader.display(false);
            });
        }
    }
    closeModal(data): void {
        if (data) {
            this.dialogRef.close({ action: 'save', data });
        } else {
            if (this.changeOccured) {
                this.dialogRef.close({ action: 'refresh', data });
            } else {
                this.dialogRef.close(data);
            }
        }
    }
    // private async _filterSquad(value: string) {
    //     if (value != "") {
    //         this.filteredSquad = await this.searchSquad(value.toLowerCase());
    //     }
    //     else {
    //         this.filteredSquad = [];
    //     }
    // }
    // private searchSquad(value: string): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         this.requestService.postRequest('tag', 'search', {
    //             order: [{ "field": "name", "order": "asc" }],
    //             fields: ["name"],
    //             term: value.toLowerCase(),
    //             termfields: ["name"],
    //             filter: {
    //                 "$and": [{ "organizationId._id": { "$in": [this.requestService.orgId] } },
    //                 { "type": { "$eq": 'squad' } }],
    //             }
    //         }, (data, error) => {
    //             if (data?.results) {
    //                 resolve(data.results);
    //             }
    //             else
    //                 resolve();
    //         });
    //     });
    // }
    onSquadSelection(event: any) {
        // this.isSquadOptionSelected = true;
        // let selectedValue = event.option.value;
        this.squad = event;
        this.squadDisplay = event?.name || '';
        // this.form.controls.squad.setValue(selectedValue.name);
    }
    public manageSquad() {
        const dialogRef = this.dialog.open(CustomCreateTableDialogComponent, {
            autoFocus: false,
            width: '800px',
            data: {
                title: this.translate.instant('Manage'),
                dataType: 'squad',
                dataTypeDisplay: 'Squad',
                dataTypeSingleDisplay: 'Squad',
                focusField: 'name',
                defaultFields: ['imageLink', 'name'],
                fieldsToHide: [],
                canIconUpload: true,
                canNew: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.changeOccured = true;
                this.squadSelect.refresh();
                console.log(result);
                // do something
            }
        });
    }
}