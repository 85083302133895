<div class="disableLayer"></div>
<div class="showingLayer">
  <img *ngIf="cameraType === 'default'" style="width: 100%;" src="{{value}}" alt="{{name}}">
  <video *ngIf="cameraType === 'rtsp'" style="width: 100%;" id="video-player" controls autoplay poster="{{picture}}">
    <source src="{{value}}" type="video/mp4">
    {{'Your browser does not support the video tag.' | translate }}
    <img style="width: 70vw;" src="{{picture}}">
  </video>
  <iframe *ngIf="cameraType === 'vimeo' && iframe_html" [src]="iframe_html" width="100%" height="100%" frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  <iframe *ngIf="cameraType === 'youtube' && iframe_html" width="100%" height="100%" [src]="iframe_html"
    title="YouTube video player" frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  <iframe *ngIf="cameraType === 'antmedia' && iframe_html" width="100%" height="100%" [src]="iframe_html"
    frameborder="0" allowfullscreen></iframe>
</div>