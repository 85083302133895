<!-- <div id="sidebar-logo" exceptclick>
  <a class="topLogo" (click)="goHome()">
    <img style="max-height: 50px;" src="assets/images/main-logo.png">
  </a>
</div> -->
<!-- <div id="sidebar-logo" exceptclick>
  <mat-nav-list exceptclick>
    <a class="toggleSidebar" mat-list-item (click)="toggleSidebar()" exceptclick>
      <mat-icon class="sidenav-icon" exceptclick>dashboard</mat-icon>
    </a>
    <a class="goHome" mat-list-item (click)="goHome()" exceptclick>
      <mat-icon class="sidenav-icon" exceptclick>dashboard</mat-icon>
    </a>
  </mat-nav-list>
</div> -->
<div id="sidebar" clickOutside (clickOutside)="clickingOutside()">
  <!-- <a class="topLogo" (click)="goHome()">
    <img src="assets/images/main-logo.png">
  </a> -->
  <div style="overflow-y: auto;max-height: calc(100vh - 200px);scrollbar-width: thin;">
    <mat-nav-list>
      <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
    </mat-nav-list>
  </div>
  <div class="extraMainInfo">
    <!-- <img src="assets/images/oi-full-logo.png" /> -->
    <!-- <img src="assets/images/oi-logo-text.png" /> -->
    <!-- <div>Critical Incident Protocol</div>
    <div>&</div>
    <div>Hazard Emergency Response</div> -->
    <img src="assets/images/logo-text.png" />
  </div>
</div>

<ng-template #menuListTemplate>
  <ng-container *ngFor="let child of menuConfigs">
    <ng-container *ngIf="child.title && (!child.superadmin || (child.superadmin && isSuperAdmin))"
      [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ item: child }">
    </ng-container>
  </ng-container>
  <a mat-list-item *ngIf="isSuperAdmin" [routerLinkActive]="'active'" [routerLink]="'/car-plates'" (click)="hideMenu()"
    class="item-href" [title]="'Vehicle Lookup'">
    <mat-icon matListItemIcon>car_crash</mat-icon>
    <div matListItemTitle>{{ 'Vehicle Lookup' | translate }}</div>
  </a>
  <!-- <a mat-list-item (click)="viewProfile()">
    <mat-icon matListItemIcon class="sidenav-icon">account_circle</mat-icon>
    <div matListItemTitle>{{'Account' | translate }}</div>
  </a> -->
  <a mat-list-item [routerLink]="['notifications']" [routerLinkActive]="'active'" (click)="hideMenu()">
    <mat-icon matListItemIcon class="sidenav-icon unread-notifications"
      *ngIf="showNotificationUnRead">notifications_active</mat-icon>
    <mat-icon matListItemIcon class="sidenav-icon" *ngIf="!showNotificationUnRead">notifications</mat-icon>
    <div matListItemTitle>{{'Notifications' | translate }}</div>
  </a>
  <!-- <a mat-list-item (click)="changeLang()" class="visible-sm item-language">
    <mat-icon matListItemIcon class="sidenav-icon">language</mat-icon>
    <div *ngIf="languageSelected === 'en'" matListItemTitle>{{ 'EN' | translate }}</div>
    <div *ngIf="languageSelected === 'ja'" matListItemTitle>{{ 'JP' | translate }}</div>
  </a> -->
  <!-- <a *ngIf="languageSelection || isSuperAdmin" mat-list-item (click)="changeLanguage()" class="item-language">
    <mat-icon matListItemIcon class="sidenav-icon">language</mat-icon>
    <div *ngIf="languageSelected === 'en'" matListItemTitle>Language/言語</div>
    <div *ngIf="languageSelected === 'ja'" matListItemTitle>言語/Language</div>
  </a> -->
  <!-- <a mat-list-item (click)="logout()">
    <mat-icon matListItemIcon class="sidenav-icon">logout</mat-icon>
    <div matListItemTitle>{{'Logout' | translate }}</div>
  </a> -->
</ng-template>
<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">

  <ng-container *ngIf="!item.submenu && !item.permission" [ngTemplateOutlet]="menuItemInnerTemplate"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  <ng-template *ngIf="!item.submenu && item.permission" [ngxPermissionsOnly]="item.permission">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplate"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
  <ng-container *ngIf="item.submenu && !item.permission" [ngTemplateOutlet]="menuItemInnerTemplateSub"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  <ng-template *ngIf="item.submenu && item.permission" [ngxPermissionsOnly]="item.permission">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplateSub"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
</ng-template>
<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <a *ngIf="item.target" mat-list-item href="{{item.page}}" target="{{item.target}}" (click)="hideMenu()"
    class="item-href" [title]="item.title">
    <mat-icon *ngIf="item.icon" matListItemIcon>{{item.icon}}</mat-icon>
    <div matListItemTitle>{{ item.title | translate }}</div>
  </a>
  <a *ngIf="!item.target" mat-list-item [routerLinkActive]="'active'" [routerLink]="[item.page]" (click)="hideMenu()"
    class="item-href" [title]="item.title">
    <mat-icon *ngIf="item.icon" matListItemIcon>{{item.icon}}</mat-icon>
    <div matListItemTitle>{{ item.title | translate }}</div>
  </a>
</ng-template>
<ng-template #menuItemInnerTemplateSub let-item="item" let-parentItem="parentItem">
  <div class="nested-menu">
    <a mat-list-item (click)="addExpandClass(item.target)">
      <mat-icon matListItemIcon class="sidenav-icon">{{item.icon}}</mat-icon> {{ item.title | translate }}
    </a>
    <ul class="nested submenu" [class.expand]="showMenu === item.target">
      <li *ngFor="let child of item.submenu">
        <ng-container *ngIf="child.title" [ngTemplateOutlet]="menuItemTemplate"
          [ngTemplateOutletContext]="{ item: child }"></ng-container>
      </li>
    </ul>
  </div>
</ng-template>