<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field [class.hideDate]="hideDate" style="width: 100%;">
        <mat-label>{{ !metadata['hideDisplayName'] ? metadata.displayName : '' | translate}}</mat-label>
        <input matInput [matDatepicker]="dp" name="mydate" [required]="!metadata.nullable" [(ngModel)]="dateValue"
          ng-model="'mydate'" placeholder="{{!metadata['hideDisplayName'] ? metadata.displayName +' ' : ''| translate}}"
          (ngModelChange)="setDateAttribute($event)" [errorStateMatcher]="esMatcher"
          [disabled]="disabled || disabledDate">
        <mat-error *ngIf="!metadata.nullable">
          {{'Date is required' |
          translate}}
        </mat-error>
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field style="width: 100%;">
        <mat-label>{{'Time' | translate}}</mat-label>
        <input matInput [ngxTimepicker]="picker" type="time" name="mytime" [required]="!metadata.nullable"
          [(ngModel)]="timeValue" ng-model="'mytime'" placeholder="{{'Time' | translate}}"
          (ngModelChange)="setTimeAttribute($event)" [errorStateMatcher]="esMatcher"
          [disabled]="disabled || disabledTime" [format]="24">
        <ngx-material-timepicker #picker [cancelBtnTmpl]="cancelBtnTmpl"
          [confirmBtnTmpl]="confirmBtnTmpl"></ngx-material-timepicker>
        <mat-error *ngIf="!metadata.nullable && (timeValue === null || timeValue === undefined || timeValue === '')">
          {{'Time is required' |
          translate}}
        </mat-error>
      </mat-form-field>
      <ng-template #cancelBtnTmpl>
        <button class="red-text" mat-raised-button style="margin: 0px 1vw;">
          {{'Cancel' | translate}}
        </button>
      </ng-template>
      <ng-template #confirmBtnTmpl>
        <button class="green-text" mat-raised-button>
          {{'OK' | translate}}
        </button>
      </ng-template>
    </div>
  </div>
</form>