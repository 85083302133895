import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs/internal/Subscription";
import { RequestService } from "src/app/shared";
import { ChatService } from "src/app/shared/services/chat.service";
import { environment } from "src/environments/environment";
import { EditInvestigatorMapDialogComponent } from "../edit-investigator-map-dialog/edit-investigator-map-dialog.component";
import { LogsInvestigatorMapDialogComponent } from "../logs-investigator-dialog/logs-investigator-dialog.component";
import { CaseService } from "src/app/shared/services/case.service";
import { openCenteredPopup } from "src/app/shared/helpers";

interface Profile {
    _id: string;
    name: string;
    badgeNo: string;
    rank: string;
    phone: string;
    division: string;
    pictureLink: string;
    alternative_name: string;
    squad_ref: string;
    remarks: string;
    call_sign: string;
    caseId: string;
    deviceids: any[];
    userMetadata: any;
    userMapMetadata: any;
    role: any;
    hideCall: boolean;
}

@Component({
    selector: 'app-view-investigator-dialog',
    templateUrl: './view-investigator-dialog.component.html',
    styleUrls: ['./view-investigator-dialog.component.scss'],
})

export class ViewInvestigatorDialogComponent implements OnInit, OnDestroy {
    public isPopup: boolean = false;
    protected subscriptions: Subscription[] = [];
    public loading: boolean = false;
    public rankEnum: any = [];
    // public divisionEnum: any = [];
    public phoneMeta = undefined;
    public badgeNoMeta = undefined;
    public squadMeta = undefined;
    public remarkMeta = undefined;
    public callsignMeta = undefined;
    public rankMeta = undefined;
    public divisionMeta = undefined;
    public isInvestigator: boolean = false;
    public currentUser: any = undefined;
    public rolesCannotView: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];
    public rolesCannotEdit: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];

    constructor(private translate: TranslateService, private requestService: RequestService, public dialogRef: MatDialogRef<ViewInvestigatorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Profile, private router: Router, private chatService: ChatService, private dialog: MatDialog, private caseService: CaseService) {
        if (window.opener) {
            this.isPopup = true;
        }
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.currentUser = data;
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                }
            })
        );
        this.rankEnum = this.data.userMetadata.fields.find(i => i.name == 'rank').enum;
        // this.divisionEnum = this.data.userMetadata.fields.find(i => i.name == 'division').enum;
        this.badgeNoMeta = this.data.userMetadata.fields.find(i => i.name == 'badge_no');
        this.rankMeta = this.data.userMetadata.fields.find(i => i.name == 'rank');
        this.divisionMeta = this.data.userMetadata.fields.find(i => i.name == 'division');
        this.phoneMeta = this.data.userMetadata.fields.find(i => i.name == 'phone');
        if (this.data.userMapMetadata) {
            this.squadMeta = this.data.userMapMetadata.fields.find(i => i.name == 'squad_ref');
            this.remarkMeta = this.data.userMapMetadata.fields.find(i => i.name == 'remarks');
            this.callsignMeta = this.data.userMapMetadata.fields.find(i => i.name == 'call_sign');
        }
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                }
            })
        );
    }

    ngOnDestroy(): void {

    }

    viewProfile() {
        if (this.data._id) {
            this.dialogRef.close();
            // window.open(`/investigator/${this.data._id}`, "_blank")
            openCenteredPopup(`/investigator/${this.data._id}`, this.translate.instant('Investigator'));
            // this.router.navigate([`/investigator/${this.data._id}`]);
        }
    }
    call() {
        this.chatService.showCallDialogSubject.next({
            show: true,
            currentCaseInvestigator: { _id: this.data._id, name: this.data.alternative_name },
            investigators: [{ _id: this.data._id, name: this.data.alternative_name }],
            selectedUsers: [this.data._id],
            callerId: this.currentUser._id,
            invitedBy: this.currentUser._id,
            caseId: this.data?.caseId || undefined,
        });
        this.dialogRef.close();
    }
    chat() {
        // let tokens = [];
        // this.data.deviceids?.map(j => tokens.push(j.deviceId));
        // this.chatService.selectedUser.next({ id: this.data._id, name: this.data.alternative_name, role: undefined });
        // let investigators = [{ _id: this.data._id, name: this.data.alternative_name, deviceids: tokens }];
        // let investigatorGroups = [];
        // if (this.data.caseId) {
        //     investigators = this.caseService.investigators;
        //     investigatorGroups = this.caseService.investigatorGroups;
        // }
        // this.chatService.showChatDialogSubject.next({
        //     show: true,
        //     investigators: investigators,
        //     caseId: this.data?.caseId || undefined,
        //     caseName: undefined,
        //     investigatorGroups: investigatorGroups,
        //     isInvestigatorProfile: this.data?.caseId ? false : true
        // });
        let isGroup = false;
        if (this.data?.caseId) {
            let url = '/global-chat/' + this.data._id + '/' + this.data.caseId;
            // window.open(url);
            if (isGroup) {
                url = '/global-chat/' + this.data._id + '/' + this.data.caseId + '/group';

                openCenteredPopup(url, this.translate.instant('General Chat') + this.data._id + '/' + this.data.caseId + '/group');
            } else {
                openCenteredPopup(url, this.translate.instant('General Chat') + this.data._id + '/' + this.data.caseId);
            }
        } else {
            let url = '/global-chat/' + this.data._id
            openCenteredPopup(url, this.translate.instant('General Chat') + this.data._id);
        }
        this.dialogRef.close();
    }
    editUserMapData() {
        const dialogRef = this.dialog.open(EditInvestigatorMapDialogComponent, {
            autoFocus: false,
            width: '50vw',
            data: {
                caseId: this.data.caseId,
                userId: this.data._id,
                metadata: this.data.userMapMetadata,
                title: this.translate.instant('Edit Investigator'),
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.action && result.action == 'save') {
                    this.updateMapData(result);
                } else {
                    this.caseService.getInvestigatorsCaseData(this.data.caseId).then(() => {
                        this.caseService.investigatorsMapUpdated.next(true);
                    });
                }
            }
        });
    }
    logs() {
        const dialogRef = this.dialog.open(LogsInvestigatorMapDialogComponent, {
            autoFocus: false,
            width: '50vw',
            data: {
                caseId: this.data.caseId,
                userId: this.data._id,
                title: this.translate.instant('Call Logs'),
            }
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }
    updateMapData(result) {
        this.data.squad_ref = result.data.squad_ref;
        this.data.remarks = result.data.remarks;
        this.data.call_sign = result.data.call_sign;
        this.caseService.getInvestigatorsCaseData(this.data.caseId).then(() => {
            this.caseService.investigatorsMapUpdated.next(true);
        });
    }
}