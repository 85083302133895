import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				// {
				// 	title: 'Dashboard',
				// 	root: true,
				// 	icon: 'dashboard',
				// 	page: '/dashboard',
				// 	bullet: 'dot',
				// },
				{
					title: 'List',
					root: true,
					icon: 'list',
					page: '/cases/list',
					permission: [
						environment.customKeys.roleAdmin,
						environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				{
					title: 'Map',
					root: true,
					icon: 'map',
					page: '/cases/map',
					permission: [
						environment.customKeys.roleAdmin,
						// environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				{
					title: 'Archived Cases',
					root: true,
					icon: 'inventory_2',
					page: '/cases/archived',
					permission: [
						environment.customKeys.roleAdmin,
						// environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				{
					title: 'Users',
					root: true,
					icon: 'group',
					page: '/users',
					permission: [
						environment.customKeys.roleAdmin,
						// environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				{
					title: 'Cameras',
					root: true,
					icon: 'videocam',
					page: '/cameras',
					permission: [
						environment.customKeys.roleAdmin,
						// environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				{
					title: 'Camera Groups',
					root: true,
					icon: 'group_work',
					page: '/camera-groups',
					permission: [
						environment.customKeys.roleAdmin,
						// environment.customKeys.roleInvestigators,
						environment.customKeys.rolePrefectureCommanders,
						environment.customKeys.roleRegionCommanders
					],
					bullet: 'dot'
				},
				// {
				// 	title: 'Vehicle Lookup',
				// 	root: true,
				// 	icon: 'car_crash',
				// 	page: '/car-plates',
				// 	permission: [
				// 		environment.customKeys.roleAdmin,
				// 		// environment.customKeys.roleInvestigators,
				// 		environment.customKeys.rolePrefectureCommanders,
				// 		environment.customKeys.roleRegionCommanders
				// 	],
				// 	bullet: 'dot'
				// }
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
