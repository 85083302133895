import { Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, LoaderService, RequestService } from "src/app/shared/services";

@Component({
    selector: 'app-support-dialog',
    templateUrl: './support-dialog.component.html',
    styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit, OnDestroy {

    public question: string = '';
    public messages: any = [];
    public loading: boolean = false;

    private threadId: string = '';

    @ViewChild('scrollContainer') scrollContainer!: ElementRef;

    constructor(public dialogRef: MatDialogRef<SupportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private requestService: RequestService, private loader: LoaderService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService) {

    }

    ngOnInit(): void {
        this.loader.display(true);
        this.requestService.getRequest('openai', 'thread/create', (data, error) => {
            if (data) {
                this.threadId = data.results;
            }
            this.loader.display(false);
        });
    }

    ngOnDestroy(): void {

    }

    close() {
        this.dialogRef.close();
    }

    private formatCipherInstructions(text: string): string {
        if (!text) return '';

        // Replace \n with actual line breaks
        let html = text.replace(/\\n/g, '\n');

        // Step headers (e.g., "1. **Access**") → `<h3>Step 1: Access...</h3>`
        html = html.replace(/^(\d+)\.\s\*\*(.*?)\*\*/gm, '<h3>Step $1: $2</h3>');

        // Sub-bullet bold (e.g., "**Case Title***:") → `<strong>Case Title*</strong>:`
        html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Bullets or sub-points (lines starting with "- ")
        html = html.replace(/^ {0,4}- (.*)/gm, '<li>$1</li>');

        // Wrap <li> blocks inside <ul>
        html = html.replace(/(<li>[\s\S]*?<\/li>)/g, '<ul>$1</ul>');

        // Paragraph breaks for other lines
        // html = html.replace(/([^\n]+)(\n|$)/g, '<p>$1</p>');

        // Clean duplicate tags (e.g., nested <ul><ul>)
        if (html.includes('\n')) {
            html = html.replace(/([^\n]+)(\n|$)/g, '<p>$1</p>');
        }

        return html;
    }

    ask(question: string = this.question) {
        this.loading = true;
        if (!question.trim()) return;
        this.question = '';
        this.messages = [...this.messages, { who: this.translate.instant("You"), response: question, isUser: true }];

        this.loader.display(true);
        this.requestService.postRequest('openai', 'sendmessage', {
            threadId: this.threadId,
            message: question,
            // threadId: null,
        }, (data, error) => {
            if (data) {
                // console.log("Assistant replied:", data.results);
                this.messages = [...this.messages, { who: "CIPHER", response: this.formatCipherInstructions(data.results), isUser: false }];
                this.loader.display(false);
                this.loading = false;

                setTimeout(() => {
                    this.scrollContainer.nativeElement.scrollTop =
                        this.scrollContainer.nativeElement.scrollHeight;
                });
            }
            else if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('An error has occurred. Please try again later.'), this.translate.instant('Dismiss'));
                this.loader.display(false);
                this.loading = false;
            }
        });
        // this.requestService.sendMessage(question).then(reply => {
        //     console.log("Assistant replied:", reply);
        //     this.messages = [...this.messages, { who: "CIPHER", response: this.formatCipherInstructions(reply) }];
        //     this.loader.display(false);
        //     this.loading = false;

        //     setTimeout(() => {
        //         this.scrollContainer.nativeElement.scrollTop =
        //             this.scrollContainer.nativeElement.scrollHeight;
        //     });
        // });
    }
}