<div class="col-xl-12 cropPopup">
	<h1 mat-dialog-title>{{data.title | translate}}</h1>
	<div mat-dialog-content class="dialog-content">
		<div class="ImageTabContainer" *ngIf="!imageUrlPath || (pictureLink && !selectedImage)">
			<img src="{{pictureLink}}">
		</div>
		<div *ngIf="imageUrlPath && selectedImage">
			<image-cropper style="height:300px;box-sizing: border-box;" [imageBase64]="imageUrlPath"
				[maintainAspectRatio]="!(selectedRatio === 0)" [aspectRatio]="selectedRatio" format="png"
				[autoCrop]="false" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady()" [roundCropper]="true"
				(loadImageFailed)="loadImageFailed()"></image-cropper>
		</div>

	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="image/*" multiple="false"
					id="image_selector" (change)="onBrowseFiles($event.target)">
				<button mat-raised-button (click)="imageInput.click()" [disabled]="loading">{{'Select' |
					translate}}</button>
				<button class="green-text" mat-raised-button (click)="cropIt()"
					*ngIf="selectedImage && imageUrlPath && imageSelected" [disabled]="loading">{{'Confirm Crop and
					Upload' | translate}}</button>
				<button class="red-text" mat-raised-button (click)="clearIt($event)"
					*ngIf="originalImage && originalImage !== defaultPictureLink" [disabled]="loading">{{'Clear' |
					translate}}</button>
				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>