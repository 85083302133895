import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environment';

interface ViewDialogData {
	title: string;
	data: any;
	currentImage: string;
	profile: boolean;
}

@Component({
	selector: 'app-crop-dialog-modal',
	templateUrl: './custom-crop-dialog.component.html',
	styleUrls: ['./custom-crop-dialog.component.scss']
})
export class ModalCropDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public orgType = environment.orgType;
	public originalImage = undefined;
	public selectedUser: any;
	public errorMessage: string = '';
	public isProfile: boolean = false;
	public targetUserId: string = undefined;
	public passingThru: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public selectedImage: boolean = false;
	public dataTypeDisplay: string = 'Image';
	public dataTypeSingleDisplay: string = 'Image';
	public dataTypeDisplayFolder: string = 'Folder';
	public dataList: any[] = [];
	public dataListFolder: any[] = [];
	public imageSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public folderText: string = '';
	public folderSelected: string = '';
	public tabSelected: number = 0;
	public searchVal: string = '';
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];

	public defaultPictureLink: string = 'assets/images/profile.png';
	public pictureLink: string = JSON.parse(JSON.stringify(this.defaultPictureLink));

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	pageSize = 10;
	pageNumber = 1;

	orderDir = 'asc';
	orderBy = '_id'; // uid

	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalCropDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalGalleryDialogComponent', data);
		this.dataTypeDisplay = this.translate.instant('Image');
		this.dataTypeSingleDisplay = this.translate.instant('Image');
		this.dataTypeDisplayFolder = this.translate.instant('Folder');
		this.isProfile = data.profile || false;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;

					if (this.isProfile) {
						this.targetUserId = this.selectedUser._id;
					} else {
						this.targetUserId = this.data.data._id;
					}
					let pictureLink = '';
					if (this.data.data && this.data.data.pictureLink && this.data.data.pictureLink !== '') {
						pictureLink = this.data.data.pictureLink;
					}
					if (this.isProfile) {
						if (data.pictureLink && data.pictureLink !== '' && data.pictureLink !== this.defaultPictureLink) {
							pictureLink = data.pictureLink;
						} else if (this.data.currentImage && this.data.currentImage !== '') {
							pictureLink = this.data.currentImage;
						}
					}
					if (pictureLink && pictureLink !== '') {
						this.originalImage = pictureLink;
						this.pictureLink = pictureLink;

						this.getBase64ImageFromFile(pictureLink);
						this.imageSelected = { error: false, text: this.getFilename(pictureLink), originalFile: undefined, source_url: null }
					}
				}
			})
		);
	}
	public rationChanged(event) {
		this.selectedRatio = Number(event.value);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public getBase64ImageFromFile(imageUrl) {
		this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
			// this is the image as dataUrl
			this.imageUrlPath = 'data:image/png;base64,' + base64data;
		},
			err => {

				// this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
			}
		);
	}
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	goToCrop(): void {
		this.zone.run(() => {
			// console.log(this.imageUrlPath);
			this.tabSelected = 1;
		});
	}
	getFilename(url) {
		if (url) {
			var m = url.toString().match(/.*\/(.+?)\./);
			if (m && m.length > 1) {
				return m[1];
			}
		}
		return "";
	}
	onBrowseFiles(target: any): void {
		this.readFiles(target.files);
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, index = 0): void {
		let reader = new FileReader();
		if (index in files) {
			let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
			let fileExt = files[index].name.split('.').pop();
			const max_size = 5000000;
			const max_height = 600;
			const max_width = 600;
			if (files[index].size > max_size) {
				this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', this.translate.instant('Dismiss'));
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				currentFile.error = true;
				this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), this.translate.instant('Dismiss'));
			} else {
				this.readFile(files[index], reader, (event) => {
					this.loaderService.display(true);
					var image = new Image();
					this.readImage(event, image, (imgresult) => {
						// console.log('readImage', imgresult);
						var canvas = document.createElement("canvas");
						var context = canvas.getContext("2d");

						var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);


						// console.log('ratio', ratio);
						if (imgresult.width < imgresult.width * ratio && imgresult.height < imgresult.height * ratio) {
							canvas.width = imgresult.width;
							canvas.height = imgresult.height;
						} else {
							canvas.width = imgresult.width * ratio;
							canvas.height = imgresult.height * ratio;
						}
						context.drawImage(image,
							0,
							0,
							image.width,
							image.height,
							0,
							0,
							canvas.width,
							canvas.height
						);
						// console.log(canvas.width, canvas.height);
						// console.log(image.width, max_width, image.height, max_height);
						if (canvas.width <= max_width && canvas.height <= max_height) {
							currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL("image/png", 0.75), currentFile.text + '.png');
							this.imageUrlPath = canvas.toDataURL("image/png", 0.75);
							this.imageSelected = currentFile;
							this.selectedImage = true;
							this.pictureLink = this.imageUrlPath;
							this.loaderService.display(false);
						} else {
							this.layoutUtilsService.showNotification(this.translate.instant('The image is not well formatted'), this.translate.instant('Dismiss'));
							this.selectedImage = false;
							this.loaderService.display(false);
						}
						// this.continueUpload(currentFile);
					});
				});
			}
		} else {
			this.cdr.detectChanges();
		}
	}
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
	continueUpload(currentFile) {
		if (!this.loading && currentFile && currentFile.originalFile) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.onUploadUserImage(currentFile, this.targetUserId)
				.subscribe(
					(results: any) => {
						// console.log('currentFile', currentFile);
						if (results['status']) {
							currentFile.source_url = results.results.pictureLink;
							this.pictureLink = results.results.pictureLink;
							this.layoutUtilsService.showNotification(this.translate.instant('User image uploaded successfully'), this.translate.instant('Dismiss'));
							if (this.isProfile) {
								this.requestService.updateUserData('pictureLink', results.results.pictureLink);
							} else {
								if (this.targetUserId === this.selectedUser._id) {
									this.requestService.updateUserData('pictureLink', results.results.pictureLink);
								}
							}
							this.loading = false;
							this.closeModal(results.results.pictureLink);
						} else {
							this.loading = false;
							currentFile.error = true;
							this.layoutUtilsService.showNotification(this.translate.instant('Error:') + results['message'], 'Dismiss');
						}
						this.cdr.detectChanges();
					},
					(error: any) => {
						// console.log('Error uploading file.', error);
						this.loading = false;
						currentFile.error = true;
						this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Uploading') + ' ' + this.translate.instant('file'), this.translate.instant('Dismiss'));
						this.cdr.detectChanges();
					}
				);
		} else {
			this.closeModal(undefined);
		}
	}
	// fileChangeEvent(event: any): void {
	//     this.imageChangedEvent = event;
	// }
	cropIt() {
		if (this.imageCropper) {
			let croppedImage = this.imageCropper.crop();
			if (!this.loading && this.imageSelected && croppedImage) {
				this.imageSelected['originalFile'] = this.dataURLtoFile(croppedImage.base64, this.imageSelected.text + '.png');
				// this.createPinSVG(croppedImage.base64, svgUrl => {
				// 	console.log('SVG URL:', svgUrl);
				// 	this.downloadSvg(svgUrl);
				// });
				this.continueUpload(this.imageSelected);
			}
		}
	}

	// 	createPinSVG(base64: string, callback: (svgUrl: any) => void): void {
	// 		const size = 100;
	// 		const svg = `

	// <svg viewBox="176.633 103.886 38.979 49.078" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	//   <defs>
	//     <clipPath id="pinClip">
	//       <path d="M 196.144 106.615 C 207.691 106.634 214.887 119.146 209.097 129.136 C 203.307 139.126 188.873 139.102 183.116 129.093 C 177.371 119.103 184.569 106.633 196.094 106.615"/>
	//     </clipPath>
	//   </defs>
	//   <image x="181.16" y="106.889" width="30" clip-path="url(#pinClip)" preserveAspectRatio="preserveAspectRatio" height="30" xlink:href="${base64}"/>
	//   <path d="M 196.144 106.615 C 207.691 106.634 214.887 119.146 209.097 129.136 C 203.307 139.126 188.873 139.102 183.116 129.093 C 177.371 119.103 184.569 106.633 196.094 106.615" fill="none" stroke="#ed37ed" stroke-width="0.5"/>
	//   <path d="M 195.992 150.839 C 195.992 144.974 192.811 139.11 186.448 133.247 C 192.811 135.202 199.173 135.202 205.536 133.247 C 199.173 139.11 195.992 144.974 195.992 150.839" fill="#ed37ed" stroke="#ed37ed" stroke-width="0.5"/>
	// </svg>`

	// 		console.log('base64', base64)
	// 		// Convert the SVG to a data URL
	// 		const svgBlob = new Blob([svg], { type: 'image/svg+xml' });
	// 		const svgUrl = URL.createObjectURL(svgBlob);
	// 		console.log(svg, svgBlob)
	// 		callback(svg);
	// 	}

	// 	downloadSvg(svg): void {
	// 		const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
	// 		const url = URL.createObjectURL(blob);

	// 		const link = document.createElement('a');
	// 		link.href = url;
	// 		link.download = 'pin.svg';
	// 		document.body.appendChild(link);
	// 		link.click();
	// 		document.body.removeChild(link);
	// 	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageHasLoaded(e) {
		//  console.log('imageHasLoaded', e);
	}
	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}
	public clearIt(e) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (!this.loading) {
			const _title: string = this.translate.instant(this.dataTypeDisplay) + ' ' + this.translate.instant('Deletion');
			const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
			const _waitDesciption: string = this.translate.instant('Deleting') + '...';

			const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.realDelete();
			});
		}
	}
	public realDelete() {
		const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('deleted successfully');
		// this.layoutUtilsService.showNotification(_deleteMessage,  this.translate.instant('Dismiss'));
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.deleteProfileImage('user', this.targetUserId, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
					this.imageSelected = undefined;
					this.imageUrlPath = undefined;
					this.pictureLink = JSON.parse(JSON.stringify(this.defaultPictureLink));

					if (this.isProfile) {
						this.requestService.updateUserData('pictureLink', this.defaultPictureLink);
					}
					this.closeModal(this.defaultPictureLink);
				}
			});
		}
	}
	getBase64ImageFromURL(url: string) {
		return Observable.create((observer: Observer<string>) => {
			// create an image object
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url + '?t=' + new Date().getTime();
			if (!img.complete) {
				// This will call another method that will create image from url
				img.onload = () => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err) => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		});
	}
	getBase64Image(img: HTMLImageElement) {
		// We create a HTML canvas object that will create a 2d image
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext("2d");
		// This will draw image
		ctx.drawImage(img, 0, 0);
		// Convert the drawn image to Data URL
		var dataURL = canvas.toDataURL("image/png", 0.75);
		return dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
	}
}
