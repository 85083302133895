import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms"; import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
;
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ConfirmEntityDialogComponent, GoogleMapsComponent, LayoutUtilsService, LoaderService, RequestService } from "src/app/shared";
import { AblyService } from "src/app/shared/services/ably.service";
import { CaseService } from "src/app/shared/services/case.service";
import { DocumentUploadDialogComponent } from "../../documents-dialog/document-upload-dialog/document-upload-dialog.component";
import { Observable, debounceTime, from, map, startWith, switchMap } from "rxjs";
import { CustomCreateTableDialogComponent } from "src/app/shared/components/custom-create-table-dialog/custom-create-table-dialog.component";
import { CustomSelectNewComponent } from "src/app/shared/components/custom-select-new/custom-select-new.component";

interface DialogData {
    caseId: string;
    userId: string;
    title: string;
}

@Component({
    selector: 'app-logs-investigator-dialog',
    templateUrl: './logs-investigator-dialog.component.html',
    styleUrls: ['./logs-investigator-dialog.component.scss']
})
export class LogsInvestigatorMapDialogComponent implements OnInit, OnDestroy {
    public subscriptions: any[] = <any>[];

    public isInvestigator: boolean = false;
    public currentUser: any = undefined;
    public logsList: any[] = [];

    constructor(public dialogRef: MatDialogRef<LogsInvestigatorMapDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private loader: LoaderService, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private formBuilder: FormBuilder, private dialog: MatDialog, private caseService: CaseService,
        @Inject(MAT_DATE_LOCALE) public _locale: string, private _adapter: DateAdapter<any>) {
        if (localStorage.getItem('languageSelected')) {
            this._locale = localStorage.getItem('languageSelected');
        } else {
            this._locale = 'en';
        }
        this._adapter.setLocale(this._locale);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.currentUser = data;
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                    this.fetchUserLogsData();
                }
            })
        );
    }
    async fetchUserLogsData() {
        if (this.data.caseId && this.data.userId) {
            this.loader.display(true);
            this.caseService.getCurrentInvestigatorGroups(this.data.userId).then((data: any) => {
                if (data && data.results) {
                    let currentUserGroupsId = data.results.map(itm => itm._id);

                    let filters = {
                        type: 'call'
                    };
                    filters["filtervisibility"] = {
                        userId: this.data.userId,
                        userGroupsIds: currentUserGroupsId
                    };
                    this.caseService.getTimelinePromise(this.data.caseId, 0, 99999999, filters).then((notes: any) => {
                        if (notes && notes.results) {
                            this.logsList = notes.results;
                        }
                    }).finally(() => {
                        this.loader.display(false);
                    });
                }
            }).catch((e) => {
                this.loader.display(false);
            });
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    closeModal(): void {
        this.dialogRef.close();
    }
    viewProfile(userId: string) {
        this.caseService.getUserMetadata().then((metadata: any) => {
            this.caseService.viewProfile(userId, metadata);
        });
    }
    viewGroupProfile(groupId: string) {
        this.caseService.getInvestigatorGroupMetadata().then((metadata: any) => {
            this.caseService.viewInvestigatorGroupProfile(groupId, metadata);
        });
    }
}