import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TabCoordinatorService implements OnDestroy {
    private readonly channelName = 'app-tab-coordination';
    private channel = new BroadcastChannel('tab-check');
    private sessionKey = 'unique_session_id';

    private broadcastChannel: BroadcastChannel;
    private tabId = Math.random().toString(36).substr(2, 9);
    private activeCallTab: string | null = null;
    private lastHeartbeat: any = Date.now();

    private dataSubject = new Subject<any>();
    private leadershipSubject = new Subject<boolean>();
    private callStateSubject = new Subject<string>();
    public callState: any = 'idle'; // 'idle' | 'ringing' | 'ongoing'

    constructor(private router: Router, private ngZone: NgZone) {
        if (!window.opener && window.location.pathname !== '/block') {
            this.initTabCheck();
            // if (sessionStorage.getItem(this.sessionKey)) {
            //     this.tabId = sessionStorage.getItem(this.sessionKey);
            // } else {
            //     sessionStorage.setItem(this.sessionKey, this.tabId);
            // }

            this.callState = localStorage.getItem('activeCallStatus');
            this.activeCallTab = localStorage.getItem('activeCallTab');
            this.initializeBroadcastChannel();
            this.initializeStorageListeners();
            this.initializeVisibilityListener();
            // this.initializeHeartBeatListener();
        }
    }

    // Initialize HeartBeat Listner
    private initializeHeartBeatListener(): void {
        setInterval(() => {
            if (this.isCurrentTabLeader()) {
                this.broadcastChannel.postMessage({
                    type: 'HEARTBEAT',
                    tabId: this.tabId
                });
            } else if (Date.now() - this.lastHeartbeat > 3000) {
                console.info('Leader tab is unresponsive. Attempting to claim leadership...');
                this.claimLeadership();
            }
        }, 1000)
    }
    // Initialize communication channel
    private initializeBroadcastChannel(): void {
        this.broadcastChannel = new BroadcastChannel(this.channelName);

        this.broadcastChannel.onmessage = (event) => {
            switch (event.data.type) {
                case 'LEADERSHIP_CLAIM':
                    this.handleLeadershipClaim(event.data.tabId);
                    break;
                case 'DATA_UPDATE':
                    this.dataSubject.next(event.data.payload);
                    break;
                case 'CALL_ANSWERED':
                    this.handleCallAnswered(event.data.tabId);
                    break;
                case 'REQUEST_LEADER':
                    if (this.isCurrentTabLeader()) {
                        if (document.hidden && Notification.permission === 'granted') {
                            new Notification('Switch to leader tab', {
                                body: 'Switch back to the leader tab!',
                                icon: '/assets/images/logo-small.png', // Optional
                            });
                        }
                    }
                    break;
                case 'HEARTBEAT':
                    this.lastHeartbeat = Date.now();
                    break;
                case 'CALL_STATE_UPDATE':
                    this.handleCallStateUpdate(event.data.state);
                    break;
            }
        };
    }




    // Handle storage events for tab coordination
    private initializeStorageListeners(): void {
        window.addEventListener('storage', (event) => {
            if (event.key === 'activeCallTab') {
                this.activeCallTab = event.newValue;
                this.leadershipSubject.next(this.isCurrentTabLeader());
            }
        });
    }

    // Handle tab visibility changes
    private initializeVisibilityListener(): void {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.checkLeadership();
            }
        });
    }

    // Claim leadership for handling calls
    public claimLeadership(callstate: any = 'idle'): void {
        localStorage.setItem('activeCallTab', this.tabId);
        this.broadcastChannel.postMessage({
            type: 'LEADERSHIP_CLAIM',
            tabId: this.tabId,
            timestamp: Date.now()
        });
        this.setCallState(callstate);
        this.activeCallTab = this.tabId;
        this.leadershipSubject.next(true);
        this.broadcastChannel.postMessage({
            type: 'HEARTBEAT',
            tabId: this.tabId
        });
    }

    // Check if current tab is the leader
    public isCurrentTabLeader(): boolean {
        return this.activeCallTab === this.tabId;
    }

    // Handle incoming leadership claims
    private handleLeadershipClaim(tabId: string): void {
        if (tabId !== this.tabId) {
            this.activeCallTab = tabId;
            localStorage.setItem('activeCallTab', tabId);
            this.leadershipSubject.next(false);
        }
    }

    // Handle call answering across tabs
    private handleCallAnswered(tabId: string): void {
        this.activeCallTab = tabId;
        localStorage.setItem('activeCallTab', tabId);
        this.leadershipSubject.next(this.isCurrentTabLeader());
    }

    // Notify other tabs when a call is answered
    public notifyCallAnswered(): void {
        // this.claimLeadership();
        this.broadcastChannel.postMessage({
            type: 'CALL_ANSWERED',
            tabId: this.tabId
        });
    }

    // Sync data between tabs
    public sendDataUpdate(payload: any): void {
        this.broadcastChannel.postMessage({
            type: 'DATA_UPDATE',
            payload: payload,
            tabId: this.tabId
        });
    }

    // Get observable for data updates
    public getDataUpdates(): Observable<any> {
        return this.dataSubject.asObservable().pipe(
            filter(data => data !== undefined)
        );
    }

    // Get observable for leadership changes
    public getLeadershipUpdates(): Observable<boolean> {
        return this.leadershipSubject.asObservable();
    }

    // Cleanup
    ngOnDestroy(): void {
        this.broadcastChannel.close();
        this.channel.close();
        if (this.isCurrentTabLeader()) {
            localStorage.removeItem('activeCallTab');
        }
    }

    // Check leadership status periodically
    private checkLeadership(): void {
        const storedLeader = localStorage.getItem('activeCallTab');
        if (storedLeader !== this.activeCallTab) {
            this.activeCallTab = storedLeader;
            this.leadershipSubject.next(this.isCurrentTabLeader());
        }
    }

    public redirectToLeaderTab() {
        this.broadcastChannel.postMessage({
            type: 'REQUEST_LEADER',
            timestamp: Date.now()
        });
    }

    public setCallState(state: 'idle' | 'ringing' | 'ongoing'): void {
        // console.log('setCallState', state);
        this.callState = state;
        localStorage.setItem('activeCallStatus', state);
        this.callStateSubject.next(state);
        this.broadcastCallState();
    }

    private broadcastCallState(): void {
        this.broadcastChannel.postMessage({
            type: 'CALL_STATE_UPDATE',
            state: this.callState,
            tabId: this.tabId
        });
    }
    private handleCallStateUpdate(state: 'idle' | 'ringing' | 'ongoing'): void {
        // console.log('handleCallStateUpdate', state)
        localStorage.setItem('activeCallStatus', state);
        this.callState = state;
        // Notify components or services about the call state change
        this.callStateSubject.next(state);
    }

    public canAnswerCall(): boolean {
        return this.isCurrentTabLeader() && this.callState === 'ringing';
    }

    public canInitiateCall(): boolean {
        return this.isCurrentTabLeader() && this.callState === 'idle';
    }
    public canInitiateCallByState(): boolean {
        // console.log(localStorage.getItem('activeCallStatus'), this.callState)
        return this.callState === 'idle';
    }

    private initTabCheck() {
        this.tabId = this.generateUniqueId();

        // If no primary tab exists, set this tab as primary
        if (!sessionStorage.getItem(this.sessionKey)) {
            sessionStorage.setItem(this.sessionKey, this.tabId);
        }

        // Listen for other tabs opening
        this.channel.onmessage = (event) => {
            if (event.data?.type === 'check-tab') {
                this.channel.postMessage({ type: 'tab-exists', primaryTabId: sessionStorage.getItem(this.sessionKey) });
                this.ngZone.run(() => {
                    this.router.navigate([event.data.route]); // Navigate within Angular
                });
            } else if (event.data?.type === 'tab-exists' && event.data?.primaryTabId !== this.tabId) {
                console.warn('Duplicate tab detected! Closing...');
                window.location.href = `/block`;
            }
        };
        // Ask other tabs if they exist
        this.channel.postMessage({ type: 'check-tab', route: window.location.pathname });
    }

    private generateUniqueId(): string {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }
}