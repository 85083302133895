import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, HostListener, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss']
})
export class CustomImageComponent implements OnInit {
  @Input() name: any = '';
  @Input() url: any = undefined;

  @ViewChild('image', { static: true }) imageRef!: ElementRef;
  position = { x: 0, y: 0 };
  scale = 1;
  isDragging = false;
  lastMousePosition = { x: 0, y: 0 };
  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
  }

  // @HostListener('document:click', ['$event'])
  // onClickOutside(event: Event) {
  //   if (!this.img.nativeElement.contains(event.target)) {
  //     this.isZoomed = false;
  //     this.container.nativeElement.style.overflow = 'hidden';
  //     this.img.nativeElement.style.width = '100%';
  //     this.img.nativeElement.style.cursor = 'zoom-in';
  //   }
  // }

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.lastMousePosition = { x: event.clientX, y: event.clientY };
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }



  onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (!this.isDragging) return;

    // const dx = event.clientX - this.lastMousePosition.x;
    // const dy = event.clientY - this.lastMousePosition.y;
    // const container = this.imageRef.nativeElement.parentElement;
    // const imgWidth = this.imageRef.nativeElement.offsetWidth * this.scale;
    // const imgHeight = this.imageRef.nativeElement.offsetHeight * this.scale;
    // const containerWidth = container.offsetWidth;
    // const containerHeight = container.offsetHeight;

    // Check boundaries only if not zoomed in
    // if (this.scale === 1) {
    //   const minX = 0;
    //   const minY = 0;
    //   const maxX = containerWidth - imgWidth;
    //   const maxY = containerHeight - imgHeight;

    //   this.position.x = Math.min(Math.max(this.position.x + dx, minX), maxX);
    //   this.position.y = Math.min(Math.max(this.position.y + dy, minY), maxY);
    // } else {
    // Allow dragging freely when zoomed in, but keep within limits
    const dx = event.clientX - this.lastMousePosition.x;
    const dy = event.clientY - this.lastMousePosition.y;
    this.position.x += dx;
    this.position.y += dy;
    // }
    this.lastMousePosition = { x: event.clientX, y: event.clientY };
  };

  onMouseUp = () => {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  onWheel(event: WheelEvent) {
    event.preventDefault();
    let maxzoom = 4;
    const zoomFactor = 0.1;
    if (event.deltaY < 0) {
      if (this.scale + zoomFactor > maxzoom) {
        return;
      }
      this.scale += zoomFactor;
    } else {
      this.scale = Math.max(0.3, this.scale - zoomFactor);
    }
  }

}