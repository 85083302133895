<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<mat-card>
			<mat-card-content>
				<div class="form-group" [formGroup]="passwordForm">
					<mat-form-field style="margin-bottom: 5px;width:100%" *ngIf="!toVerify">
						<input matInput [type]="showOldPassword ? 'text' : 'password'" name="oldpassword"
							formControlName="oldpassword" autocomplete="off"
							placeholder="{{'Old Password' | translate}}">
						<mat-icon *ngIf="showOldPassword && passwordForm.controls.oldpassword.value.length"
							class="password-eye" (click)="showOldPassword = !showOldPassword">
							visibility</mat-icon>
						<mat-icon *ngIf="!showOldPassword && passwordForm.controls.oldpassword.value.length"
							class="password-eye" (click)="showOldPassword = !showOldPassword">
							visibility_off</mat-icon>
						<mat-error *ngIf="passwordForm.controls.oldpassword.errors?.incorrect">
							{{'The password you entered is incorrect' | translate}}
						</mat-error>
					</mat-form-field>
					<mat-form-field style="margin-bottom: 5px;width:100%" *ngIf="toVerify">
						<input matInput type="text" name="oldpassword" formControlName="oldpassword"
							placeholder="{{'Verification Code' | translate}}">
						<mat-error *ngIf="passwordForm.controls.oldpassword.errors?.incorrect">
							{{'The password you entered is incorrect' | translate}}
						</mat-error>
					</mat-form-field>
					<mat-form-field style="margin-bottom: 45px;width:100%">
						<input matInput [type]="showNewPassword ? 'text' : 'password'" name="password"
							formControlName="password" autocomplete="off" placeholder="{{'New Password' | translate}}">
						<mat-icon *ngIf="showNewPassword && passwordForm.controls.password.value.length"
							class="password-eye" (click)="showNewPassword = !showNewPassword">
							visibility</mat-icon>
						<mat-icon *ngIf="!showNewPassword && passwordForm.controls.password.value.length"
							class="password-eye" (click)="showNewPassword = !showNewPassword">
							visibility_off</mat-icon>
						<mat-error *ngIf="passwordForm.controls.password.errors?.pattern">
							{{'The password you entered does not meet the minimum security requirements' | translate}}
						</mat-error>
						<mat-error
							*ngIf="passwordForm.controls.password.errors?.hasOwnProperty('same') && passwordForm.controls.password.errors.same !== null">
							{{'The password you entered is the same as the old password. Please enter a new password'|
							translate}}
						</mat-error>
						<mat-hint>{{'Your password must contain at least 8 characters including lower and upper case
							letters, numbers, and special characters'| translate}}</mat-hint>
					</mat-form-field>
					<mat-form-field style="width:100%">
						<input matInput [type]="showConfirmPassword ? 'text' : 'password'" name="confirmpassword"
							formControlName="confirmpassword" autocomplete="off"
							placeholder="{{'Confirm Password' | translate}}">
						<mat-icon *ngIf="showConfirmPassword && passwordForm.controls.confirmpassword.value.length"
							class="password-eye" (click)="showConfirmPassword = !showConfirmPassword">
							visibility</mat-icon>
						<mat-icon *ngIf="!showConfirmPassword && passwordForm.controls.confirmpassword.value.length"
							class="password-eye" (click)="showConfirmPassword = !showConfirmPassword">
							visibility_off</mat-icon>
						<mat-error>
							{{'The passwords you entered do not match' | translate}}
						</mat-error>
					</mat-form-field>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button class="green-text" mat-raised-button
					[disabled]="loading || passwordForm.controls.password.errors?.pattern" (click)="saveData()"
					cdkFocusInitial>{{'Save' | translate}}</button>
				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close'
					|translate}}</button>
			</div>
		</div>
	</div>
</div>