function getItemFromListContainsDisplay(lst: any[], val: string, idKey: string = '_id', displayName: string = 'name'): any {
    if (lst) {
        for (let itm of lst) {
            if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
                return itm[displayName];
            }
        }
    }
    return '';
}
export function prepareCase(itm, metaFieldSetting = undefined, showLabel: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    if (['inactive', 'completed'].includes(itm.status)) { // inactive and complete
        itm['icon'] = '/assets/images/icon/case-sign-inactive.png';
    } else {
        itm['icon'] = '/assets/images/icon/case-sign.png';
    }
    itm['markerType'] = 'case';
    if (metaFieldSetting) {
        itm['type'] = itm['casetype']?.name || '';
    }
    if (showLabel)
        itm['showLabel'] = showLabel;
    return itm;
}
export function prepareCamera(itm, metaFieldSetting = undefined, useSelected: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    // let randomNumbers = this.getRandomElement(this.baseImageSetDegrees);
    // itm['icon'] = this.baseImageSet[randomNumbers];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/cam.png';
            itm['labelColor'] = 'red';
        } else {
            itm['icon'] = '/assets/images/icon/cam-grayed.png';
            itm['labelColor'] = 'black';
        }
    } else {
        itm['icon'] = '/assets/images/icon/cam.png';
    }
    itm['markerType'] = 'camera';
    return itm;
}
export function prepareInvestigator(itm, metaFieldSetting = undefined, useSelected: boolean = false, useIcon: boolean = false) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    itm['label'] = itm['alternative_name'] ? itm['alternative_name'] : itm['name'];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/police-pin-blue.png';
            itm['labelColor'] = '#7030A0';
        } else {
            itm['icon'] = '/assets/images/icon/police-pin-black.png';
        }
    } else {
        if (itm.squad_ref && itm.squad_ref.imageLink && itm.squad_ref.imageLink !== '') {
            itm['icon'] = itm.squad_ref.imageLink;
        } else {
            if (itm.picture_icon && !useIcon) {
                itm['icon'] = itm.picture_icon;
            }
            else {
                itm['icon'] = '/assets/images/icon/police-pin-black.png';
            }
        }

        itm['labelColor'] = '#7030A0';
    }
    itm['picture'] = itm['pictureLink'];
    itm['rank'] = itm['rankName'] ? itm['rankName'] : itm['rank'];
    itm['years_in_service'] = itm['years_in_service'];
    itm['markerType'] = 'investigator';
    return itm;
}
export function prepareGroupInvestigator(itm, metaFieldSetting = undefined, useSelected: boolean = false, useIcon: boolean = true) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    itm['label'] = itm['name'];
    if (useSelected) {
        if (itm.selected) {
            itm['icon'] = '/assets/images/icon/group-default.png';
            itm['labelColor'] = '#255290';
        } else {
            itm['icon'] = '/assets/images/icon/group.png';
        }
    } else {
        if (itm.picture_icon && useIcon) {
            itm['icon'] = itm.picture_icon;
        }
        else
            itm['icon'] = '/assets/images/icon/group-default.png';
        itm['labelColor'] = '#255290';
    }
    itm['markerType'] = 'investigator-group';
    return itm;
}
export function prepareAnnotation(itm, metaFieldSetting = undefined) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    if (itm.document && itm.document.url && itm.document.url !== '') {
        itm['picture'] = itm.document.url;
    }
    itm['label'] = itm['name'];
    itm['icon'] = '/assets/images/icon/annotation.png';
    itm['markerType'] = 'annotation';


    if (itm.car_of_interest && itm.car_color && itm.car_color != '') {
        itm['icon'] = '/assets/images/icon/car_' + itm.car_color + '.png';
    }
    return itm;
}

export function prepareNote(itm, metaFieldSetting = undefined) {
    if (itm.geolocation && itm.geolocation !== '') {
        try {
            let lngLat = JSON.parse(itm.geolocation);
            if (lngLat.lat && lngLat.lng) {
                itm['lat'] = lngLat.lat;
                itm['lng'] = lngLat.lng;
            }
        } catch (e) {
            itm['geolocation'] = ''
        }
    }
    if (itm.document && itm.document.url && itm.document.url !== '') {
        itm['picture'] = itm.document.url;
    }
    itm['label'] = itm['name'];

    switch (itm.type) {
        case 'annotation':
            itm['icon'] = '/assets/images/icon/annotation.png';
            itm['markerType'] = 'annotation';
            break;
        case 'location':
            itm['icon'] = '/assets/images/icon/location.png';
            itm['markerType'] = 'location';
            break;
        case 'note':
            itm['icon'] = '/assets/images/icon/note.png';
            itm['markerType'] = 'note';
            break;
        default:
            if (itm.document) {
                switch (itm.document.type) {
                    case 'video':
                        itm['icon'] = '/assets/images/icon/video.png';
                        itm['markerType'] = 'video';
                        break;
                    case 'audio':
                        itm['icon'] = '/assets/images/icon/audio.png';
                        itm['markerType'] = 'audio';
                        break;
                    case 'file':
                        itm['icon'] = '/assets/images/icon/file.png';
                        itm['markerType'] = 'file';
                        break;
                    case 'image':
                        itm['icon'] = '/assets/images/icon/image.png';
                        itm['markerType'] = 'image';
                        break;
                }
            }
            break;
    }

    if (itm.car_of_interest) {
        if (itm.last) {
            if (itm.car_color && itm.car_color != '')
                itm['icon'] = '/assets/images/icon/car_' + itm.car_color + '.png';
            else {
                itm['icon'] = '/assets/images/icon/car_blue.png';
            }
        } else {
            itm['icon'] = '/assets/images/icon/car_trail.png';
        }
    }
    else if (itm.person_of_interest) {
        if (itm.last) {
            if (itm.person_of_interest_gender == 'female')
                itm['icon'] = '/assets/images/icon/person-red-female.png';
            else
                itm['icon'] = '/assets/images/icon/person-red-male.png';
        } else {
            if (itm.person_of_interest_gender == 'female')
                itm['icon'] = '/assets/images/icon/person-orange-female.png';
            else
                itm['icon'] = '/assets/images/icon/person-orange-male.png';
        }
    }
    // console.log(itm);
    return itm;
}