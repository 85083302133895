<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="ImageTabContainer" *ngIf="data.data">
			<img style="max-width: 100%;" src="{{data.data}}">
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button mat-raised-button (click)="closeModal(undefined)">Close</button>
			</div>
		</div>
	</div>
</div>