<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label>{{placeholder | translate}}</mat-label>
    <input type="text" matInput matInput [autofocus]="colName===focusField" [maxlength]="maxLength"
      [matAutocomplete]="autolist" name="{{colName}}" [(ngModel)]="value" [required]="required"
      placeholder="{{placeholder | translate}}" [errorStateMatcher]="esMatcher" [disabled]="disabled || loading"
      (input)="loadData($event)">
    <mat-icon *ngIf="clearable" matSuffix (click)="clearIt($event)" class="cancelIcon"
      matTooltip="{{'Clear' | translate}}" matTooltipClass="tooltip-red">close</mat-icon>
    <mat-icon matSuffix (click)="manageData($event)" class="cancelIcon" matTooltip="{{'Manage' | translate}}"
      matTooltipClass="tooltip-red">edit_note</mat-icon>
    <mat-autocomplete autoActiveFirstOption #autolist="matAutocomplete" (optionSelected)="onSelection($event)"
      [displayWith]="displayFn" (closed)="onAutocompleteClosed()">
      <mat-option *ngFor="let itm of filteredList" [value]="itm" [selected]="itm.name === value?.name">
        {{itm.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="required && (value === undefined || value === '')">
      {{placeholder | translate}} {{'is required' | translate}}
    </mat-error>
  </mat-form-field>
</form>