<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-card>
				<mat-card-content *ngIf="data.data">
					<mat-list>
						<mat-list-item><span>{{'Name' | translate}}:</span> {{data.data.name}}</mat-list-item>
						<mat-list-item><span>{{'Role' | translate}}:</span> {{data.data.role.name}}</mat-list-item>
						<mat-list-item><span>{{'Email' | translate}}:</span> {{data.data.email}}</mat-list-item>
					</mat-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>

		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
	</div>
	<br />
</div>