import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ModalPasswordDialogComponent } from '../../../shared/components/custom-password-dialog/custom-password-dialog.component';

import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';
import { ModalCropDialogComponent } from '../custom-crop-dialog/custom-crop-dialog.component';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { NotificationService } from '../../services/notification.service';
import { openCenteredPopup } from '../../helpers';

@Component({
	selector: 'app-user-view-dialog-modal',
	templateUrl: './custom-user-view-dialog.component.html',
	styleUrls: ['./custom-user-view-dialog.component.scss']
})
export class ModalUserViewDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	// public organization: any = undefined;
	public orgId = environment.orgId;
	public scopes: string[] = [];
	public loading: boolean = false;
	public dataType: any = 'user'
	public hasFormErrors: boolean = false;
	public pictureLink: string = 'assets/images/profile.png';
	public fieldsDetails: any = undefined;
	public permission: any[] = [];
	public metaFieldTabsSetting = [];
	public tableDataTemp: any = undefined;
	public DND: boolean = false;
	public isProfile: boolean = false;
	public showChangePassword: boolean = false;
	public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea'];
	public isDnd: boolean = false;
	public isAdmin: boolean = false;
	public isSuperAdmin: boolean = false;

	constructor(
		public dialog: MatDialog, private translate: TranslateService,
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalUserViewDialogComponent>,
		private loaderService: LoaderService,
		@Inject(MAT_DIALOG_DATA) public data: any, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService) {
		// console.log('UserViewDialogData', data);
		if (data && data.profile) {
			this.isProfile = data.profile;
		}
	}

	ngOnInit() {
		// this.subscriptions.push(
		// 	this.requestService.pageOrganization.subscribe((data) => {
		// 		if (data) {
		// 			this.organization = data;
		// 			this.orgId = data._id;
		// 			this.buildSetting();
		// 		}
		// 	})
		// );

		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					// console.log('currentUserSubject', data);
					this.selectedUser = data;
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();

					this.buildSetting();
				}
			})
		);
	}
	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	changePassword() {
		const dialogRef = this.dialog.open(ModalPasswordDialogComponent, {
			autoFocus: false,
			width: '550px',
			disableClose: true,
			data: {
				title: this.translate.instant('Change Password')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			// console.log('afterClosed', result);
			if (result) {
				//this.validateUser(userData);
			}
		});
	}
	private buildSetting() {
		if (!this.loading) {
			this.DND = JSON.parse(localStorage.getItem("DND"));
			this.loading = true;
			this.loaderService.display(true);
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				this.loaderService.display(false);
				if (data) {
					this.data.modalSetting = data.results;
					this.fieldsDetails = this.buildMetaSetting(data.results);
					// console.log('this.fieldsDetails', this.fieldsDetails);
					if (this.data.data.hasOwnProperty('_id')) {
						this.loadData();
					}
				} else {
					this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
				}
			});
		}
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.loaderService.display(true);
			this.errorMessage = '';
			var isGetValue = false;
			let dataId = this.data.data['_id'];

			if (this.data.dataType == 'user') {
				isGetValue = true;
			}
			this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.showChangePassword = data.results.is_original_email;
					this.tableDataTemp = Object.assign({}, data.results);
					this.data.confirmData = Object.assign({}, data.results);
					// console.log('this.data.confirmData ', this.data.confirmData);
					if (data.results['pictureLink']) {
						this.pictureLink = data.results['pictureLink'];
					}
					this.isDnd = data.results.dnd;
				}
				this.loading = false;
				this.loaderService.display(false);
			});
		}
	}
	convertArrayToObject(arr: any[]) {
		let obj = {};
		for (let a of arr) {
			obj[a._id] = a;
		}
		return obj;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
			} else if (col.type === 'table') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}
	resetPassword() {
		const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
			autoFocus: false,
			width: '300px',
			data: {
				title: this.translate.instant('Reset Password'),
				description: this.translate.instant('Are you sure you want to reset this user password?'),
				cancelbtn: this.translate.instant('Close'),
				confirmbtn: this.translate.instant('Reset')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				console.log('result', result);
				if (result) {
					this.confirmResetPassword();
				}
			}
		});
	}
	confirmResetPassword() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.resetUserPassword(this.data.data['_id'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.layoutUtilsService.showNotification(this.translate.instant('Password Reset Confirmed'), this.translate.instant('Dismiss'));
				}
				this.loading = false;
			});
		}
	}
	public buildTabsMetaSetting() {
		if (this.data.confirmData) {
			for (let tab of this.metaFieldTabsSetting) {
				if (!this.tableDataTemp.hasOwnProperty(tab.name)) {
					if (this.data.confirmData.hasOwnProperty(tab.name)) {
						delete this.data.confirmData[tab.name];
					}
				}
				if (this.data.confirmData.hasOwnProperty(tab.name) || this.data.confirmData['role']['targetfield'] === tab.name) {
					if (!this.data.confirmData.hasOwnProperty(tab.name)) {
						this.data.confirmData[tab.name] = {}
					}
				}
			}
		}
	}
	public viewTranscript(transcript: any) {
		// window.open(transcript.fileLink, '_blank');
		openCenteredPopup(transcript.fileLink, this.translate.instant('Transcript'));
	}
	changeDND(event) {
		console.log(event.checked);
		localStorage.setItem("DND", JSON.stringify(event.checked))
	}

	editProfile() {
		const dialogRef = this.dialog.open(ModalDialogComponent, {
			autoFocus: false,
			width: "850px",
			data: {
				dataType: 'user',
				dataTypeTitle: 'User',
				title: this.translate.instant("Edit") + " " + this.translate.instant("User"),
				data: { _id: this.data.data._id },
				modalSetting: this.getSetting({}),
				profile: this.isProfile,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result && result.action === 'refresh') {
				if (result.data) {
					this.requestService.updateCommonUserData(result.data);
				}
			}
			// setTimeout(function () {
			// 	window.location.reload();
			// }, 300);

		});

	}
	protected getSetting(data) {
		let tableSetting = data;
		tableSetting['target'] = 'self';

		tableSetting['customSettings'] = {
			organizationId: { visible: false },
			appId: { visible: false },
			locationId: { visible: false },
			resources: { visible: false },
			ref_id: { disabled: true }
		};
		if (!this.isSuperAdmin) {
			tableSetting['customSettings']['region'] = {
				disabled: true
			}
		}
		if (!this.isSuperAdmin) {
			tableSetting['customSettings']['prefecture'] = {
				disabled: true
			}
		}
		// if (this.requestService.checkListContains(this.data.data['resources'], environment.customKeys.roleClient)) {
		// 	tableSetting['customSettings']['ref_id']['visible'] = true;
		// } else {
		// 	tableSetting['customSettings']['ref_id']['visible'] = false;
		// }
		return tableSetting;
	}
	editProfileImage() {
		// console.log(this.data);
		const dialogRef = this.dialog.open(ModalCropDialogComponent, {
			width: '800px',
			disableClose: false,
			autoFocus: false,
			data: {
				title: 'Upload image',
				data: this.data.data,
				currentImage: this.pictureLink,
				profile: this.data.profile
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result || result === '') {
				this.pictureLink = result;
				// if (result['status']) {
				// 	window.location.reload();
				// }
				//do nothing
			}
		});
	}

	saveDnd() {
		this.loaderService.display(true);
		this.requestService.postRequest('user/' + this.data.data._id, '', {
			dnd: this.isDnd
		}, async (data, error) => {
			if (data) {
				if (this.isDnd) {
					await this.firebaseHandler.unregisterDevice(this.data.data._id, this.firebaseHandler.token, () => {
						this.layoutUtilsService.showNotification(this.translate.instant('updated successfully'), this.translate.instant('Dismiss'));
					}).catch((e) => {
						this.layoutUtilsService.showNotification('Error: ' + e, this.translate.instant('Dismiss'));
					}).finally(() => this.loaderService.display(false));
				}
				else {
					this.firebaseHandler.registerToken(this.firebaseHandler.token).then(() => {
						this.loaderService.display(false);
						this.layoutUtilsService.showNotification(this.translate.instant('updated successfully'), this.translate.instant('Dismiss'));
					});
				}
			}
			else if (error) {
				this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
				this.loaderService.display(false);
			}
			this.notificationService.dnd.next(this.isDnd);
		});
	}
}
