<h1 mat-dialog-title>
    {{ data.title }}
</h1>

<div mat-dialog-content>
    <table class="notes">
        <thead>
            <td style="width:30%;  padding-left: 1vw;">{{'Caller/Receiver\'s' | translate}}</td>
            <td style="width:20%;  padding-left: 1vw;">{{'Communication' | translate}}</td>
            <td style="width:10%">{{'Date' | translate}}</td>
        </thead>
        <tbody>
            <tr *ngFor="let note of logsList;let noteIndex = index" class="note">
                <td>

                    <span class="nameAlone">{{note.createdName}}</span>
                </td>
                <td>
                    <div class="borderContent">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <ng-container *ngIf="!note.initiatedFrom?._id">
                                <span class="center">L1</span>
                                <ng-container *ngIf="note.investigators?.length || note.investigatorgroup?.length">
                                    <img src="assets/images/double-arrow.png" style="width: 1.4vw; margin: 0 0.2vw;" />
                                    <div fxLayout="column" fxLayoutGap="1vh" class="recepients">
                                        <div fxFlex *ngFor="let investigator of note.investigators">
                                            <span>{{investigator.alternative_name
                                                ?
                                                investigator.alternative_name: investigator.name}}</span>
                                        </div>
                                        <div fxFlex *ngFor="let group of note.investigatorgroup">
                                            <span>{{group.name}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="note.initiatedFrom?._id">
                                <div class="recepients">
                                    <span>{{note.initiatedFrom.name}}</span>
                                </div>
                                <img src="assets/images/double-arrow.png" style="width: 1.4vw; margin: 0 0.2vw;" />
                                <span *ngIf="!(note.investigators?.length || note.investigatorgroup?.length)"
                                    class="center">L1</span>
                                <ng-container *ngIf="note.investigators?.length || note.investigatorgroup?.length">
                                    <div fxLayout="column" fxLayoutGap="1vh" class="recepients">
                                        <div fxFlex *ngFor="let investigator of note.investigators">
                                            <span>{{investigator.alternative_name
                                                ?
                                                investigator.alternative_name: investigator.name}}</span>
                                        </div>
                                        <div fxFlex *ngFor="let group of note.investigatorgroup">
                                            <span>{{group.name}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </td>

                <td>
                    <div>
                        <div fxLayout="column" fxGrow="0" fxLayoutAlign="center">
                            <span fxFlex class="date" fxGrow="0"
                                [class.updated]="note.updatedAt != note.createdAt">{{note.updatedAt
                                | date: "yyyy/MM/dd"}}</span>
                            <span fxFlex class="time" fxGrow="0"
                                [class.updated]="note.updatedAt != note.createdAt">{{note.updatedAt
                                | date: "H:mm"}}</span>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <div fxFlex align="end">
            <button class="red-text" mat-raised-button mat-dialog-close (click)="closeModal()">
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</div>