<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="fieldItem" [style.height]="iframeHeight">
			<span *ngIf="loading">{{'Loading...' | translate}}</span>
			<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0" (load)="onload($event)"
				[height]="iframeHeight"></iframe>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">

				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
	</div>
	<br />
</div>