import { commonKanagawaEnvironment } from "./environment.common.kanagawa";

export const environment = {
  ...commonKanagawaEnvironment,
  languageSelection: true,
  production: false,
  serverLoginUrl: 'https://staging.api.auth.interactivelife.me/api/',
  serverMainUrl: 'https://staging.cipher.interactivelife.me/',
  serverUrl: 'https://staging.api.cipher.interactivelife.me/api/',
  serverHostUrl: 'https://staging.cipher.interactivelife.me/',
  scheduleUrl: 'https://staging.api.cipher.interactivelife.me/api/',
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  environment: 'staging',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
