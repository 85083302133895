import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { openCenteredPopup } from "src/app/shared/helpers";
import { ChatService } from "src/app/shared/services/chat.service";

interface Unread {
    unread: any[];
    currentUserId: string;
}

@Component({
    selector: 'app-unread-chat-dialog',
    templateUrl: './unread-chat-dialog.component.html',
    styleUrls: ['./unread-chat-dialog.component.scss']
})
export class UnReadChatDialogComponent implements OnInit, OnDestroy {

    constructor(protected translate: TranslateService, public dialogRef: MatDialogRef<UnReadChatDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Unread, private router: Router, private chatService: ChatService) {

        data.unread = data.unread.map(msg => ({
            ...msg,
            displayName: (msg.receiverId && msg.receiverId !== data.currentUserId) ? msg.receiverName : msg.senderName,
            displayId: (msg.receiverId && msg.receiverId !== data.currentUserId) ? msg.receiverId : msg.senderId,
            isGroup: (msg.receiverId && msg.receiverId !== data.currentUserId)
        })).sort((a, b) => b.createdAt - a.createdAt);
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    gotoCase(message: any) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/case/${message.caseId}`).then(() => {
            this.chatService.autoSelectedChat.next({ _id: message.displayId, alternative_name: message.displayName, caseName: message.caseName, isGroup: message.isGroup, });
        });
    }

    gotoInvestigator(message: any) {
        this.dialogRef.close();
        let url = '/global-chat/' + message.displayId;
        // window.open(url);
        openCenteredPopup(url, this.translate.instant('investigator') + ':' + message.displayId);
    }
}