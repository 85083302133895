import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

export class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-select-new',
  templateUrl: './custom-select-new.component.html',
  styleUrls: ['./custom-select-new.component.scss']
})
export class CustomSelectNewComponent implements OnInit {
  public filteredList: any[] = [];
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: any = undefined;
  public selectedUser: any;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public errorMessage: string = '';
  public firstValueSet: boolean = false;
  public loading: boolean = false;
  selectData: Array<any> = [];
  /* pagination Info */
  pageSize = 100;
  pageNumber = 1;
  @Input() orderDir = 'asc';
  @Input() orderBy = 'name';
  searchText = '';

  public region: string = undefined;
  public prefecture: string = undefined;
  myControl = new FormControl();
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  lastSelectedValue: any;
  private _parentFormSubmitted = false;
  @Input() clearable: boolean = true;
  @Input() required: boolean = false;
  public esMatcher = new My2ErrorStateMatcher();
  @Input() filters: any;
  @Input() colName: string = '';
  @Input() focusField: string = undefined;
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() apiTarget: string = 'search';
  _value: any;
  @Input() disabled: boolean = false;
  @Input() maxLength: number = 150;

  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean) {
    this._parentFormSubmitted = parentFormSubmitted;
    if (parentFormSubmitted) {
      if (this.form)
        this.form.onSubmit(undefined)

    }
  }
  get parentFormSubmitted(): boolean {
    return this._parentFormSubmitted;
  }
  @Input()
  set value(value: any) {
    this._value = value;
    if (!this.firstValueSet && value) {
      // console.log('value', value);
      this.firstValueSet = true;
      this.lastSelectedValue = this.value;
    }
  }
  get value(): any {
    return this._value;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @Output() onManageReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  @ViewChild('autolist') autolist: MatAutocomplete;
  constructor(private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }
  ngAfterViewInit() {
    if (this.parentFormSubmitted) {
      if (this.form)
        this.form.onSubmit(undefined);
    }
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();

          this.lastSelectedValue = this.value;
          this.region = this.selectedUser.region;
          this.prefecture = this.selectedUser.prefecture;
          if (this.dataType == 'casetype' || this.dataType == 'squad') {
            if (!this.filters) {
              let filters = { '$and': [] };
              filters['$and'].push({ 'organizationId._id': { '$eq': this.requestService.orgId } });
              filters['$and'].push({ 'prefecture': { '$eq': this.prefecture } });
              filters['$and'].push({ 'region': { '$eq': this.region } });
              this.filters = filters;
            }
          }
          this.loadData();

        }
      })
    )
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }
  }
  refresh() {
    this.loadData(undefined, true);
  }
  public onSubmit() {
    // do nothing
  }
  // displayFn = (id: any): string => {
  //   const foundItem = this.filteredList.find(item => item._id === id);
  //   return foundItem ? foundItem.name : '';
  // };
  displayFn = (item: any): string => {
    if (!item) return '';  // Handle cases where item is null or undefined
    return item.name || ''; // Return name if available
  };
  setPredefinedValue() {
    if (this.value) {
      let currentValue = JSON.parse(JSON.stringify(this.value));
      this.value = undefined;
      setTimeout(() => {
        this.loading = false;
        const foundItem = this.filteredList.find(item => item._id === currentValue._id);
        if (foundItem) {
          this.value = foundItem; // Convert ID to object
        }
      }, 300);
    }
  }
  public loadData(event = undefined, refreshValue = false) {
    if (this.apiTarget === 'summary') {
      this.loadDataSearch(event, refreshValue);
    } else {
      this.loadDataSearch(event, refreshValue);
    }
  }
  public loadDataSearch(event = undefined, refreshValue = false) {
    let term = undefined;
    if (event) {
      term = (event.target as HTMLInputElement).value;
    }
    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }
    this.errorMessage = '';

    if (refreshValue) {
      this.loading = true;
    }
    let fieldKeys = ['name'];
    let include = [];
    if (this.lastSelectedValue && this.lastSelectedValue._id && this.lastSelectedValue._id !== '' && this.lastSelectedValue._id !== null && this.lastSelectedValue._id !== undefined) {
      include.push(this.lastSelectedValue._id);
    }
    let filterConfiguration = this.filters;
    this.apiCallSubscription = this.requestService.getDataListSummary(this.dataType, { page: this.pageNumber, term: term, perpage: this.pageSize, filter: filterConfiguration, fieldKeys: fieldKeys, include: include, orderDir: this.orderDir, orderBy: this.orderBy }, (data, error) => {
      if (error) {
        this.errorMessage = error;
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
      }
      if (data && data.results) {
        let selectData: any[] = data.results.results || [];
        selectData = selectData.map((itm) => {
          return { _id: itm._id, name: itm.text }
        });
        this.filteredList = selectData;
      } else {
        this.filteredList = [];
      }
      setTimeout(() => {
        this.setPreselectedValue();
        if (refreshValue) {
          this.setPredefinedValue();
        }
      }, 300)
    });
  }
  public onSelection(event) {
    let valT = event.option.value;
    this.lastSelectedValue = valT;
    this.onSelectReturn.emit(valT);
  }
  public manageData(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.onManageReturn.emit(true);
  }
  public clearIt(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.value = '';
    this.lastSelectedValue = '';
    this.onSelectReturn.emit({ _id: undefined, name: '' });
    this.refresh();
  }
  setPreselectedValue() {
    if (this.value) {
      const selectedItem = this.filteredList.find(item => item._id === this.value._id);
      if (selectedItem) {
        this.autolist.options.toArray().forEach((option: MatOption) => {
          if (option.value._id === selectedItem._id) {
            option.select();
          }
        });
      }
    }
  }
  onAutocompleteClosed() {
    // console.log('this.lastSelectedValue', this.lastSelectedValue);
    this.value = this.lastSelectedValue;
    this.loadData(undefined, false);
  }
}
