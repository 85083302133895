<div class="col-xl-12">
	<div mat-dialog-title class="dialog-title">
		<h2>{{data.title}}</h2>
	</div>
	<div mat-dialog-content class="dialog-content">
		<mat-form-field style="width: 100%;">
			<label>Video URL? <span style="color:grey; font-size: 10px;">(YouTube, Vimeo, Vine, Instagram, DailyMotion
					or Youku)</span></label>
			<input matInput placeholder="" [(ngModel)]="dataVal">
		</mat-form-field>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button mat-raised-button (click)="closeModal(dataVal)">Confirm</button>

				<button mat-raised-button (click)="closeModal(undefined)">Close</button>
			</div>
		</div>
	</div>
	<br />
</div>