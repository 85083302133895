<div mat-dialog-title>
    {{'AI Assistant' | translate}}
    <div class="close-dialog">
        <mat-icon (click)="close()" [matTooltip]="'Close' | translate">cancel</mat-icon>
    </div>
</div>
<div mat-dialog-content style="height: 60vh;">
    <div fxLayout="column" fxLayoutGap="1vh" fxFlexFill>
        <div fxFlex class="answers" fxFill #scrollContainer>
            <div *ngFor="let message of messages" class="chat-bubble" [class.me]="message.isUser"
                [class.assistant]="!message.isUser" fxLayout="row" fxLayoutGap="1vw">
                <span fxFlex class="who" fxGrow="0">{{message.who}}: </span>
                <div fxFlex fxLayoutAlign="start">
                    <div [innerHTML]="message.response"></div>
                </div>
            </div>
        </div>
        <div fxFlex fxLayout="row" fxGrow="0" fxLayoutGap="1vw" fxLayoutAlign="center center">
            <div fxFlex fxGrow="1">
                <mat-form-field class="question">
                    <input matInput [placeholder]="'Type a question' | translate" rows="2" [(ngModel)]="question"
                        (keydown.enter)="ask()" />
                </mat-form-field>
            </div>
            <div fxFlex fxGrow="0" fxLayoutAlign="center center">
                <mat-icon (click)="ask()" class="enter-icon" [matTooltip]="'Send' | translate">send</mat-icon>
                <!-- <button mat-raised-button (click)="ask()" style="white-space: nowrap;" [disabled]="loading">{{'Ask' |
                    translate}}</button> -->
            </div>
        </div>
    </div>
</div>