import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	data: any;
	galleryType: any;
	region: any;
	prefecture: any;
	selectedImage: any;
	parentClear: boolean;
}

@Component({
	selector: 'app-gallery-dialog-modal',
	templateUrl: './custom-gallery-dialog.component.html',
	styleUrls: ['./custom-gallery-dialog.component.scss']
})
export class ModalGalleryDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public passingThru: boolean = false;
	public loading: boolean = false;
	public isloading: boolean = false;
	public hasFormErrors: boolean = false;
	public selectedImage: boolean = false;
	public dataType: string = 'img';
	public dataTypeDisplay: string = 'Image';
	public dataList: any[] = [];
	public imageSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public tabSelected: string = '0';
	public searchVal: string = '';
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];

	public originalImage = undefined;
	public pictureLink: string = undefined;

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	pageSize = 10;
	pageNumber = 0;

	orderDir = 'desc';
	orderBy = 'createdAt'; // uid

	// @ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	@ViewChild('imageInput') myInputVariable: ElementRef;
	constructor(private zone: NgZone,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalGalleryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private translate: TranslateService) {
		// console.log('ModalGalleryDialogComponent', data);
	}

	ngAfterViewInit() {
		// const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
		// 	debounceTime(150),
		// 	distinctUntilChanged(),
		// 	tap(() => {
		// 		this.paginatorTotal.next(0);
		// 		this.loadData();
		// 	})
		// )
		// .subscribe();
		// this.subscriptions.push(searchSubscription);
		const paginatorSubscriptions = merge(this.paginator.page).pipe(
			tap(() => {
				this.getTableVariables();
				this.loadData();
			})
		)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);
		this.loadData();
	}
	ngOnInit() {
		if (this.data.selectedImage && this.data.selectedImage !== '') {
			this.originalImage = this.data.selectedImage;
			this.pictureLink = this.data.selectedImage;

			// this.getBase64ImageFromFile(this.pictureLink);
			// this.imageSelected = { error: false, text: this.getFilename(this.data.selectedImage), originalFile: undefined, source_url: null }
		}
		this.loadData();
	}
	getFilename(url) {
		if (url) {
			var m = url.toString().match(/.*\/(.+?)\./);
			if (m && m.length > 1) {
				return m[1];
			}
		}
		return "";
	}
	public getTableVariables() {
		// this.orderBy = this.sort.active || 'uid';
		// this.orderDir = this.sort.direction || 'asc';
		this.pageNumber = this.paginator.pageIndex;
		this.pageSize = this.paginator.pageSize;
	}
	public rationChanged(event) {
		this.selectedRatio = Number(event.value);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let termConfiguration = '';
			let filter = {};
			filter['$and'] = [
				{ "organizationId._id": { "$in": [this.requestService.orgId] } },
				{ "type": { "$eq": this.data.galleryType } }
			];
			if (this.data.region) {
				filter['$and'].push({ "region": { "$eq": this.data.region } });
			}
			if (this.data.prefecture) {
				filter['$and'].push({ "prefecture": { "$eq": this.data.prefecture } });
			}
			let filterObj = {
				perpage: this.pageSize, page: this.pageNumber + 1, orderBy: this.orderBy, orderDir: this.orderDir, term: termConfiguration, filter: filter
			};
			//, fieldKeys:["title", "art", "isWeight", "Procedure", "hsrRuleEngine", "smart", "notification"] // try decrease fields names
			this.requestService.getDataListByList(this.dataType, filterObj, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
				}
				if (data) {
					// console.log('dataList', data);
					this.dataList = data.results;

					if (data.pagination) {
						this.paginatorTotal.next(data.pagination.total);
					}
				}
				this.loading = false;
			});
		}
	}
	public delete(e, id: any) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (!this.loading) {
			const _title: string = this.dataTypeDisplay + ' ' + this.translate.instant('Deletion');
			const _description: string = 'Are you sure to permanently delete this ' + this.dataTypeDisplay + '?';
			const _waitDesciption: string = this.translate.instant('Deleting') + '...';

			const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.realDelete(id);
			});
		}
	}
	public realDelete(id: any) {
		const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('deleted successfully');
		this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.deleteImageData(this.dataType, id, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error') + ':' + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
					this.imageSelected = undefined;
					this.imageUrlPath = undefined;
					this.pageNumber = 0;
					this.loadData();
				}
			});
		}
	}
	public setFolderSelected(val) {
		this.passingThru = true;
		this.loadData();
	}

	changeMode(mode): void {
		// console.log('changeMode', mode);
	}
	public setImageSelected(idx, val) {
		this.imageSelected = val;
		this.imageUrlPath = undefined;
		// if (val['base64img']) {
		// 	this.imageUrlPath = val['base64img'];
		// } else {
		// 	this.getBase64ImageFromFile(val.imageUrl, (img) => {
		// 		val['base64img'] = img
		// 	});
		// }
	}
	public prepareImageCrop() {
		if (this.imageSelected && this.imageSelected['_id']) {
			if (this.imageSelected['base64img']) {
				this.imageUrlPath = this.imageSelected['base64img'];
			} else {
				this.getBase64ImageFromFile(this.imageSelected.imageUrl, (img) => {
					this.imageSelected['base64img'] = img
				});
			}
		}
	}
	public switchTab(event) {
		this.tabSelected = event;
		if (this.tabSelected === '1') {
			this.prepareImageCrop()
		}
	}
	// termConfiguration(): any {
	// 	const searchText: string = this.searchInput.nativeElement.value;
	// 	return searchText;
	// }
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	onBrowseFiles(target: any): void {
		this.readFiles(target.files);
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, index = 0): void {
		let reader = new FileReader();
		if (index in files) {
			let currentFile = { error: false, text: files[index].name, name: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };

			let fileExt = files[index].name.split('.').pop();
			const max_size = 5000000;
			const max_height = 600;
			const max_width = 600;
			if (files[index].size > max_size) {
				this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', this.translate.instant('Dismiss'));
				this.myInputVariable.nativeElement.value = "";
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				currentFile.error = true;
				this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), this.translate.instant('Dismiss'));
				this.myInputVariable.nativeElement.value = "";
			} else {
				this.loaderService.display(true);
				this.readFile(files[index], reader, (event) => {
					var image = new Image();
					this.readImage(event, image, (imgresult) => {
						// console.log('readImage', imgresult);
						var canvas = document.createElement("canvas");
						var context = canvas.getContext("2d");

						var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);

						if (imgresult.width < imgresult.width * ratio && imgresult.height < imgresult.height * ratio) {
							canvas.width = imgresult.width;
							canvas.height = imgresult.height;
						} else {
							canvas.width = imgresult.width * ratio;
							canvas.height = imgresult.height * ratio;
						}
						context.drawImage(image,
							0,
							0,
							image.width,
							image.height,
							0,
							0,
							canvas.width,
							canvas.height
						);
						if (canvas.width <= max_width && canvas.height <= max_height) {
							currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL("image/png", 0.75), currentFile.text + '.png');
							this.imageUrlPath = canvas.toDataURL("image/png", 0.75);
							this.imageSelected = currentFile;
							this.selectedImage = true;
							this.pictureLink = this.imageUrlPath;
							this.tabSelected = '1';
							this.loaderService.display(false);
							this.myInputVariable.nativeElement.value = "";
						} else {
							this.layoutUtilsService.showNotification(this.translate.instant('The image is not well formatted'), this.translate.instant('Dismiss'));
							this.selectedImage = false;
							this.tabSelected = '0';
							this.loaderService.display(false);
							this.myInputVariable.nativeElement.value = "";
						}
					});
				});
				// this.readFile(files[index], reader, (event) => {
				// 	this.loaderService.display(true);
				// 	var image = new Image();
				// 	this.readImage(event, image, (imgresult) => {
				// 		// console.log('readImage', imgresult);
				// 		if (imgresult.width <= max_width && imgresult.height <= max_height) {
				// 			this.continueUpload(currentFile);
				// 		} else {
				// 			this.layoutUtilsService.showNotification(this.translate.instant('The image is not well formatted'), this.translate.instant('Dismiss'));

				// 			this.loaderService.display(false);
				// 		}
				// 	});
				// });

			}
		} else {
			this.cdr.detectChanges();
		}
	}
	continueUpload(currentFile) {
		this.requestService.onUploadFiles(currentFile, this.data.region, this.data.prefecture, this.data.galleryType)
			.subscribe(
				(results: any) => {
					// console.log('results', results);
					if (results['status']) {
						currentFile.source_url = results;
						this.pageNumber = 0;
						this.loadData();
						this.tabSelected = '0';
						this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Successfully Uploaded', 'Dismiss');
					} else {
						currentFile.error = true;
						this.layoutUtilsService.showNotification(this.translate.instant('Error:') + results['message'], 'Dismiss');
					}
					this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					// this.currentFile = currentFile;
					this.loaderService.display(false);
				},
				error => {
					console.log('Error uploading file.', error);
					currentFile.error = true;
					// this.currentFile = currentFile;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Uploading') + ' ' + this.translate.instant('file'), this.translate.instant('Dismiss'));
					this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					this.loaderService.display(false);
				}
			);
	}
	public changeText(e) {
		// console.log('changeText',e);
		if (e.length === 0) {
			this.loadData();
		}
	}
	// fileChangeEvent(event: any): void {
	//     this.imageChangedEvent = event;
	// }
	cropIt() {
		let croppedImage = this.imageCropper.crop();
		if (!this.loading && this.imageSelected && croppedImage) {
			let name = this.imageSelected.name ? this.imageSelected.name : this.imageSelected.text
			this.imageSelected['originalFile'] = this.dataURLtoFile(croppedImage.base64, name + '.png');
			this.continueUpload(this.imageSelected);
		}
	}
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	cropItOld() {
		let croppedImage = this.imageCropper.crop();
		// console.log('CropIt', this.imageCropper.crop());
		if (!this.loading && this.imageSelected && croppedImage) {
			this.loading = true;
			this.errorMessage = '';
			let objData = {
				src: this.imageSelected.url,
				x: croppedImage.cropperPosition.x1 + '',
				y: croppedImage.cropperPosition.y1 + '',
				w: croppedImage.width + '',
				h: croppedImage.height + '',
				uploadedImage: true
			};
			this.requestService.cropImageByOrg(objData, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
					this.closeModal({ action: 'select', image: data.results.imageUrl });
				}
			});
		}

	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageHasLoaded(e) {
		console.log('imageHasLoaded', e);
	}
	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}
	// public getBase64Image(imgUrl, callback) {
	//
	//     var img = new Image();
	//
	//     // onload fires when the image is fully loadded, and has width and height
	//
	//     img.onload = function(){
	//
	//       var canvas = document.createElement("canvas");
	//       canvas.width = img.width;
	//       canvas.height = img.height;
	//       var ctx = canvas.getContext("2d");
	//       ctx.drawImage(img, 0, 0);
	//       var dataURL = canvas.toDataURL("image/png"),
	//           dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
	//
	//       callback(dataURL); // the base64 string
	//
	//     };
	//
	//     // set attributes and src
	//     img.setAttribute('crossOrigin', 'anonymous'); //
	//     img.src = imgUrl;
	//
	// }
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
	getBase64ImageFromURL(url: string) {
		return Observable.create((observer: Observer<string>) => {
			// create an image object
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url + '?t=' + new Date().getTime();
			if (!img.complete) {
				// This will call another method that will create image from url
				img.onload = () => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err) => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		});
	}
	public getBase64ImageFromFile(imageUrl, callback = undefined) {
		this.isloading = true;
		this.loaderService.display(true);
		this.imageUrlPath = undefined;
		this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
			// this is the image as dataUrl
			this.imageUrlPath = 'data:image/png;base64,' + base64data;
			if (callback) {
				callback(this.imageUrlPath)
			}
			this.isloading = false;
			this.loaderService.display(false);
		},
			err => {
				console.log("error1", err);
				this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
				this.isloading = false;
				this.loaderService.display(false);
			}
		);
	}
	getBase64Image(img: HTMLImageElement) {
		// We create a HTML canvas object that will create a 2d image
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext("2d");
		// This will draw image
		ctx.drawImage(img, 0, 0);
		// Convert the drawn image to Data URL
		var dataURL = canvas.toDataURL("image/png");
		return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
	}
	public clearIt(e) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (this.data.parentClear) {
			this.closeModal({ action: 'clear' });
		} else {
			if (!this.loading) {
				const _title: string = this.translate.instant('Clear Selected Image');
				const _description: string = this.translate.instant('Are you sure you want to clear selected Image?');
				const _waitDesciption: string = this.translate.instant('Clearing') + '...';

				const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
				dialogRef.afterClosed().subscribe(res => {
					if (!res) {
						return;
					}
					this.closeModal({ action: 'clear' });
				});
			}
		}
	}
}
