<div mat-dialog-title>
    {{'Unread Notifications' | translate}}
</div>
<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="1vh">
        <div fxFlex *ngFor="let message of data.unread">
            <div *ngIf="message.caseId" (click)="gotoCase(message)" class="link">
                <ng-container *ngIf="message.count == 1">
                    {{ 'You have X unread notification in case Y' | translate: {
                    count: message.count,
                    case: message.caseName
                    } }}
                </ng-container>
                <ng-container *ngIf="message.count > 1">
                    {{ 'You have X unread messagess in case Y' | translate: {
                    count: message.count,
                    case: message.caseName
                    } }}
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>
        {{ "Close" | translate }}
    </button>
</div>