<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-form-field class="language hide-sm" style="width: 100%;">
				<mat-label>{{'Name' | translate}}</mat-label>
				<input matInput [required]="true" [(ngModel)]="collectionName" />
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button class="green-text" mat-raised-button color="secondary"
					(click)="closeModal({action: 'create', name: collectionName})">{{'Confirm' |
					translate}}</button>
				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>