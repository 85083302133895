<h1 mat-dialog-title>
    {{ data.title }}
</h1>

<div mat-dialog-content>
    <form fxLayout="column" fxLayoutGap="1vh" *ngIf="form" [formGroup]="form">
        <!-- <mat-form-field fxFlex>
            <mat-label>{{'Squad' | translate}}</mat-label>
            <input type="text" matInput maxlength="150" formControlName="squad" [matAutocomplete]="squad">
            <mat-autocomplete autoActiveFirstOption #squad="matAutocomplete"
                (optionSelected)="onSquadSelection($event)">
                <mat-option *ngFor="let squad of filteredSquad" [value]="squad">
                    {{squad.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> -->
        <app-custom-select-new #squadSelect [parentFormSubmitted]="isSubmitted" [value]="squad" [colName]="'squad'"
            [required]="false" [dataType]="'squad'" [apiTarget]="'search'" [placeholder]="'Squad'" [maxLength]="100"
            (onSelectReturn)="onSquadSelection( $event)" (onManageReturn)="manageSquad()"></app-custom-select-new>
        <mat-form-field fxFlex>
            <mat-label>{{'Call sign (Radio)' | translate}}</mat-label>
            <input matInput formControlName="call_sign" maxlength="50" />
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>{{'Remark' | translate}}</mat-label>
            <textarea matInput rows="5" maxlength="1000" [placeholder]="'Remark' | translate"
                formControlName="remarks"></textarea>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>{{'Timeline Visibility' | translate}}</mat-label>
            <mat-select formControlName="timeline_visibility">
                <mat-option *ngFor="let itm of metadata.get('timeline_visibility').enum" [value]="itm.value">
                    {{itm.displayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>


    </form>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <div fxFlex align="end">
            <button class="green-text" mat-raised-button [disabled]="!form.valid" *ngIf="form" (click)="save()">
                {{ "Update" | translate }}
            </button>
            <button class="red-text" mat-raised-button mat-dialog-close (click)="closeModal(undefined)">
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</div>