import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

interface Unread {
    unread: any[];
    currentUserId: string;
}

@Component({
    selector: 'app-unread-notifications-dialog',
    templateUrl: './unread-notifications-dialog.component.html',
    styleUrls: ['./unread-notifications-dialog.component.scss']
})
export class UnReadNotificationsDialogComponent implements OnInit, OnDestroy {

    constructor(protected translate: TranslateService, public dialogRef: MatDialogRef<UnReadNotificationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Unread, private router: Router) {

        data.unread = data.unread.sort((a, b) => b.createdAt - a.createdAt);
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    gotoCase(message: any) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/case/${message.caseId}/notifications`);
    }
}