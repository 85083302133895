export function fromArray(data: [any] | any[]): any | undefined {
  for (let json of data) {
    if (json === undefined) {
      return undefined;
    }
  }
  return data;
}
export function containsObject(obj: any, list: [any] | any[]): boolean {
  for (let i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
}
export function roundDecimal(value, decimals): number {
  return Number(Number(value).toFixed(decimals));
}
export function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}
export function containsSlashBeg(myUrl) {
  if (myUrl.substr(0, 1) === '/') {
    myUrl = myUrl.substr(1, myUrl.length);
  }

  return myUrl;
}
export function getRandomInt(min, max) {
  // public random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomExcluded(start, end, excluded) {
  if (start >= end) {
    return start;
  }
  var n = excluded
  while (n == excluded)
    n = Math.floor((Math.random() * (end - start + 1) + start));
  return n;

}
export function parseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function validateSite(email) {
  const re = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!re.test(email);
}
export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:" || url.protocol === "rtmp:";
}
export function isValidHttpsUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "https:";
}
export function matchYoutubeUrl(url) {
  let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
}
export function matchFacebookUrl(url) {
  // let p = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
  let p = /^https?:\/\/(www\.)?(facebook\.com)\/(video(s)?|watch|story)?(\/)?.*(\/)?$/;
  // let p = /^https?:\/\/(www\.)?(facebook\.com|fb.watch)\/(video(s)?|watch|story)?(\/)?.*(\/)?$/;
  // let p = /^(https?://www\.facebook\.com\/(?:video\.php\?v=\d+|.*?/videos/\d+))$/;
  if (url.match(p)) {
    return url.match(p)[0];
  }
  return false;
}
export function Utf8Encode(data) {
  data = data.replace(/\r\n/g, '\n');
  let utftext = '';

  for (let n = 0; n < data.length; n++) {

    let c = data.charCodeAt(n);

    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if ((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }

  }

  return utftext;
};
export function hexToRgbA(hex, opacity = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}
export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function getFileName(url, includeExtension: boolean = true) {
  var matches = url && typeof url.match === "function" && url.match(/\/?([^/.]*)\.?([^/]*)$/);
  if (!matches)
    return null;

  if (includeExtension && matches.length > 2 && matches[2]) {
    return matches.slice(1).join(".");
  }
  return matches[1];
}
export function detectMob() {
  const toMatch = [
    /Mobile/i,
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
export function calculateRatio(num_1, num_2) {
  for (let num = num_2; num > 1; num--) {
    if ((num_1 % num) == 0 && (num_2 % num) == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  return [num_1, num_2];
}
export function getOtherImage(preAdd, url, postAdd = '') {
  if (url) {
    let file = url.substring(url.lastIndexOf('/') + 1);
    let fileExt = file.split('.').pop();
    let fileName = file.split('.').slice(0, -1).join('.');
    let returnUrl = url.substring(0, url.lastIndexOf("/"));
    return returnUrl + '/' + preAdd + fileName + postAdd + '.' + fileExt;
  }
  return url;
}
export function addUrlParam(url, name, value) {
  const separator = url.indexOf('?') > -1 ? '&' : '?';
  return `${url}${separator}${name}=${encodeURIComponent(value)}`;
}
export function deepEqual(obj1, obj2) {
  // Check if both arguments are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key and its value
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function sanitizeFilename(filename) {
  // Define a regular expression to match characters not allowed in filenames
  const illegalCharsRegex = /[\/\?<>\\:*|"]/g;

  // Replace illegal characters with underscores
  const sanitizedFilename = filename.replace(illegalCharsRegex, '_');

  return sanitizedFilename;
}
export function arrayToCSV(arr) {
  return arr.join(', ');
}
export function prependUniqueItems(originalArray, newItems) {
  const combined = [...newItems, ...originalArray];

  // Use Map to filter out duplicates based on _id
  const uniqueItems = Array.from(new Map(combined.map(item => [item._id, item])).values());

  return uniqueItems;
}
export function appendUniqueItems(originalArray, newItems) {
  const combined = [...originalArray, ...newItems];

  // Use Map to filter out duplicates based on _id
  const uniqueItems = Array.from(new Map(combined.map(item => [item._id, item])).values());

  return uniqueItems;
}
export function openCenteredPopup(url, title, wRatio = 0.8, hRatio = 0.8, width = undefined, height = undefined, unique = true) {
  if (url.includes('global-chat')) {
    wRatio = 0.6;
    hRatio = 0.5;
  } else if (url.includes('case-cameras')) {
    wRatio = 0.6;
    hRatio = 0.6;
  }
  var w = screen.width * wRatio;
  var h = screen.height * hRatio;
  if (width)
    w = width;
  if (height)
    h = height;
  // Calculate the position to center the window
  var left = (screen.width - w) / 2;
  var top = (screen.height - h) / 2;

  let titleNew = title;
  if (unique) {
    titleNew = titleNew + new Date().getTime();
  }

  // Open the popup window
  var popup = window.open(url, titleNew, 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left + ',toolbar=0;menubar=0;location=0;status=0');

  // Focus the window if it's already opened
  if (window.focus) {
    popup.focus();
  }

  return popup;
}