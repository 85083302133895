import { Component, OnInit, ViewChild, HostListener, TemplateRef, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutUtilsService, LoaderService, RequestService } from '../../../shared/services';
// import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
// import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import * as moment from 'moment';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
// import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';
import { ModalPasswordDialogComponent } from '../custom-password-dialog/custom-password-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalCropDialogComponent } from '../custom-crop-dialog/custom-crop-dialog.component';
import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { ChatService } from '../../services/chat.service';
import { UnReadChatDialogComponent } from './unread-chat-dialog/unread-chat-dialog.component';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { UnReadNotificationsDialogComponent } from './unread-notifications-dialog/unread-notifications-dialog.component';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public languageSelected: string = 'en';
  // public title = environment.serverName;
  public subTitle = environment.subServerName;
  public pushRightClass: string = 'push-right';
  // public selectedOrganization: string = '';
  // public userType: string = 'default';
  // public dataType: string = 'organization';
  // public dataTypeDisplay: string = 'Organization';
  // public selectedDatafilters: any = undefined;
  // public prefectureTitle: string = '';
  // public prefectureEnums: any[] = [];
  public prefecture: string = '';
  public hasNew: boolean = false;
  // public pageSize = 10;
  // public showNotificationUnRead: boolean = false;
  // public joinCallInvite: any = {};
  // public currentNotification: any = undefined;
  // public notificationTimer: any = undefined;
  // public notificationAudio: any = undefined;
  // public newCase: any = undefined;
  // public notificationTimeout: any = undefined;
  public isSuperAdmin: boolean = false;
  public loading: boolean = false;
  // public metaFieldTabsSetting = [];
  // public showChangePassword: boolean = false;
  // public DND: boolean = false;
  public date: Date;
  // public screenWidth: number;
  public pictureLink: string = 'assets/images/profile.png';
  public isDnd: boolean = true;
  public languageSelection: boolean = environment.languageSelection;
  public role: string = '';
  // public hasNewChatMessages: boolean = false;
  public currentRoute: string = '';
  public unread: any = [];
  public unreadNotifications: any = [];
  public unreadNumber: number = 0;
  public unreadNotificationsNumber: number = 0;
  // public tableDataTemp: any = undefined;
  // public fieldsDetails: any = undefined;

  // @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  // @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
  //   if (ref)
  //     this.notificationAudio = ref.nativeElement;
  // }
  @Input() headerTemplate: TemplateRef<any>;

  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService, private loaderService: LoaderService, private chatService: ChatService,
    public dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService, private activatedRoute: ActivatedRoute) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    }
    // this.userType = this.requestService.getUserType();
    this.prefecture = this.translate.instant(environment.prefecture + ' Prefectural Police Headquarters');

    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd ||
        data instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
      }
    });
  }

  ngOnDestroy(): void {
    // this.ablyService.unsubscribe('new-case', 'global');
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngOnInit() {
    // this.screenWidth = window.innerWidth;
    this.date = new Date();
    // this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          // this.prefectureTitle = this.selectedUser.prefecture;
          this.pictureLink = data.pictureLink;
          // this.getUserPrefectureMetadata(this.selectedUser.prefecture);
          this.role = this.requestService.getUserRoleByText();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();

          // this.buildSetting();
          this.loadData();
        }
      })
    );

    this.subscriptions.push(this.chatService.unreadSubject.subscribe((unread) => {
      // const filtered = unread.filter(item => item.id !== this.chatService.getSelectedChat());

      this.unread = unread;
      this.unreadNumber = this.unread.reduce((sum, item) => sum + item.count, 0);
    }));

    // this.subscriptions.push(
    //   this.requestService.pageOrganization.subscribe((data) => {
    //     if (data) {
    //       this.selectedOrganization = data._id;
    //     }
    //   })
    // );
    this.subscriptions.push(
      this.requestService.currentPageTitle.subscribe((data) => {
        if (data) {
          this.subTitle = data;
        }
      })
    );

    this.subscriptions.push(this.notificationService.dnd.subscribe((isDnd) => {
      this.isDnd = isDnd;
    }));

    this.subscriptions.push(this.notificationService.unread.subscribe((unread: any) => {
      this.unreadNotifications = unread;
      this.unreadNotificationsNumber = unread.reduce((sum, item) => sum + item.count, 0);
      // if (this.currentRoute !== '/notifications' || !unread?.length)
      //   this.showNotificationUnRead = unread?.length;
    }));

    // this.subscriptions.push(this.ablyService.isConnected.subscribe(isConnected => {
    //   if (isConnected) {
    //     this.ablyService.subscribe('new-case', 'global', (message) => {
    //       this.caseService.setNewCaseSubject.next(message);
    //     });
    //     // this.ablyService.publish('new-case', JSON.stringify({ send: true }), 'global', (message) => {
    //     //   console.log(message)
    //     // });
    //   }
    // }));

    // this.subscriptions.push(this.caseService.setNewCaseSubject.subscribe(profile => {
    //   if (profile) {
    //     this.newCase = profile;
    //     // const typeEnum = this.caseService.caseMetadata.fields?.find(i => i.name == 'casetype')?.enum;
    //     // this.newCase.casetype = this.enumPipe.transform(profile.casetype, typeEnum);
    //     clearTimeout(this.notificationTimeout);
    //     this.notificationTimeout = setTimeout(() => {
    //       this.newCase = undefined;
    //     }, 2 * 60 * 1000);
    //   }
    // }));

    // this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe(message => {
    //   if (message?.data.senderId != this.selectedUser?._id) {
    //     if (message?.notification) {
    //       // this.notificationService.showNotificationUnRead.next(true);
    //       // this.notificationService.refreshData.next(true);
    //       // console.log(message)
    //       if (!this.isDnd) {
    //         if (this.notificationAudio) {
    //           this.notificationAudio.load();
    //           this.notificationAudio.loop = 0;
    //           this.notificationAudio.volume = 0.25;
    //           this.notificationAudio.play().catch((error: any) => { });
    //         }

    //         this.currentNotification = message.notification.body;
    //         if (message.data.invitedBy) {
    //           this.joinCallInvite = message.data;
    //         }
    //         if (this.notificationTimer) {
    //           clearTimeout(this.notificationTimer);
    //           this.notificationTimer = undefined;
    //         }
    //         this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
    //       }
    //     }
    //     else
    //       this.currentNotification = null;
    //   }
    // }));
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.screenWidth = window.innerWidth;
  // }


  // setSelectedOrganization(e, reload = true) {
  //   this.requestService.orgId = "5e5e69dd2023950162466258";
  //   this.storeService.set('orgId', '5e5e69dd2023950162466258');
  // }

  // isToggled(): boolean {
  //   const dom: Element = document.querySelector('body');
  //   return dom.classList.contains(this.pushRightClass);
  // }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    // this.requestService.logout();
    this.firebaseHandler.logout();
  }

  // changeLang(language: string) {
  //   localStorage.setItem('languageSelected', language);
  //   this.languageSelected = language;
  //   this.translate.use(language);
  //   location.reload();
  // }

  // formatDate(seconds) {


  //   let output = new Date(seconds * 1000);

  //   return moment(output).format("YYYY-MM-DD HH:mm");


  // }

  // viewProfile() {
  //   const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
  //     width: '850px',
  //     data: {
  //       dataType: 'user',
  //       title: this.translate.instant('My Profile'),
  //       profile: true,
  //       data: this.selectedUser,
  //       modalSetting: undefined
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }

  // changeLanguage() {
  //   const dialogRef = this.dialog.open(ModalLanguageDialogComponent, {
  //     width: '500px',
  //     data: {
  //       title: this.translate.instant('Language'),
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }

  /**
   * @description - remove the red dot on the notifications if the menu is opened
   */
  // public handleMenuOpened() {
  //   if (this.hasNew) this.hasNew = !this.hasNew;
  // }
  // public goHome() {
  //   this.router.navigate(['/cases/list']);
  // }

  // closeNotification() {
  //   clearTimeout(this.notificationTimer);
  //   this.currentNotification = null;
  // }

  // joinMeeting(invited) {
  //   this.chatService.showCallDialogSubject.next({
  //     show: true,
  //     caseId: invited.caseId,
  //     invitedBy: invited.invitedBy,
  //     // investigators: invited.investigators,
  //   });
  // }
  // private getUserPrefectureMetadata(prefecture) {
  //   this.requestService.getMetaData('user', ['prefecture'], (data, error) => {
  //     if (error) {
  //     }
  //     // if (data && data.results && data.results.fields && data.results.fields.length > 0) {
  //     //   this.prefectureEnums = data.results.fields[0]?.enum || [];

  //       // console.log(this.prefectureEnums);
  //       // let prefectureObj = this.prefectureEnums.find((itm) => itm.value == prefecture);
  //       // console.log(prefectureObj);
  //       // if (prefectureObj?.length) {
  //         // this.prefectureTitle = prefectureObj[0].displayName;
  //       // }
  //     // }
  //   });
  // }

  editProfile() {
    this.menuTrigger.closeMenu();
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      autoFocus: false,
      width: "850px",
      data: {
        dataType: 'user',
        dataTypeTitle: 'User',
        title: this.translate.instant("Edit") + " " + this.translate.instant("User"),
        data: { _id: this.selectedUser._id },
        modalSetting: this.getSetting({}),
        profile: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.action === 'refresh') {
        if (result.data) {
          this.requestService.updateCommonUserData(result.data);
        }
      }
      // setTimeout(function () {
      // 	window.location.reload();
      // }, 300);

    });
  }

  protected getSetting(data) {
    let tableSetting = data;
    tableSetting['target'] = 'self';

    tableSetting['customSettings'] = {
      organizationId: { visible: false },
      appId: { visible: false },
      locationId: { visible: false },
      resources: { visible: false },
      ref_id: { disabled: true }
    };
    if (!this.isSuperAdmin) {
      tableSetting['customSettings']['region'] = {
        disabled: true
      }
    }
    if (!this.isSuperAdmin) {
      tableSetting['customSettings']['prefecture'] = {
        disabled: true
      }
    }
    // if (this.requestService.checkListContains(this.data.data['resources'], environment.customKeys.roleClient)) {
    // 	tableSetting['customSettings']['ref_id']['visible'] = true;
    // } else {
    // 	tableSetting['customSettings']['ref_id']['visible'] = false;
    // }
    return tableSetting;
  }

  // private buildSetting() {
  //   if (!this.loading) {
  //     // this.DND = JSON.parse(localStorage.getItem("DND"));
  //     this.loading = true;
  //     this.loaderService.display(true);
  //     this.requestService.getMetaData('user', undefined, (data, error) => {
  //       if (error) {
  //         this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
  //       }
  //       this.loading = false;
  //       this.loaderService.display(false);
  //       if (data) {
  //         // this.data.modalSetting = data.results;
  //         // this.fieldsDetails = this.buildMetaSetting(data.results);
  //         // console.log('this.fieldsDetails', this.fieldsDetails);
  //         if (this.selectedUser.hasOwnProperty('_id')) {
  //           this.loadData();
  //         }
  //       } else {
  //         this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
  //       }
  //     });
  //   }
  // }

  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      // let isGetValue = false;
      // let dataId = this.selectedUser._id;

      // if (this.data.dataType == 'user') {
      // isGetValue = true;
      // }
      this.requestService.getSingleData('user', this.selectedUser._id, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          // this.showChangePassword = data.results.is_original_email;
          // this.tableDataTemp = Object.assign({}, data.results);
          // this.data.confirmData = Object.assign({}, data.results);
          // console.log('this.data.confirmData ', this.data.confirmData);
          if (data.results['pictureLink']) {
            this.pictureLink = data.results['pictureLink'];
          }
          this.isDnd = data.results.dnd;
        }
        this.loading = false;
        this.loaderService.display(false);
      });
    }
  }

  // buildMetaSetting(data, parent = undefined) {
  //   let dataObject = {};
  //   let tabObject = [];
  //   for (let col of data.fields) {
  //     if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
  //       if (parent) {
  //         col['inputName'] = parent + col['name'];
  //       }
  //       dataObject[col.name] = col;
  //     } else if (col.type === 'object') {
  //       dataObject[col.name] = this.buildMetaSetting(col);
  //       tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
  //     } else if (col.type === 'table') {
  //       dataObject[col.name] = this.buildMetaSetting(col);
  //     }
  //   }
  //   this.metaFieldTabsSetting = tabObject;
  //   return dataObject;
  // }

  goNotifications() {
    this.menuTrigger.closeMenu();
    this.router.navigate(['/notifications']);
  }

  changePassword() {
    this.menuTrigger.closeMenu();
    const dialogRef = this.dialog.open(ModalPasswordDialogComponent, {
      autoFocus: false,
      width: '550px',
      disableClose: true,
      data: {
        title: this.translate.instant('Change Password')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('afterClosed', result);
      if (result) {
        //this.validateUser(userData);
      }
    });
  }

  saveDnd() {
    this.loaderService.display(true);
    this.isDnd = !this.isDnd;
    this.requestService.postRequest('user/' + this.selectedUser._id, '', {
      dnd: this.isDnd
    }, async (data, error) => {
      if (data) {
        if (this.isDnd) {
          await this.firebaseHandler.unregisterDevice(this.selectedUser._id, this.firebaseHandler.token, () => {
            this.firebaseHandler.token = '';
            this.layoutUtilsService.showNotification(this.translate.instant('updated successfully'), this.translate.instant('Dismiss'));
          }).catch((e) => {
            this.layoutUtilsService.showNotification('Error: ' + e, this.translate.instant('Dismiss'));
          }).finally(() => this.loaderService.display(false));
        }
        else {
          this.firebaseHandler.registerToken(this.firebaseHandler.token).then(() => {
            this.loaderService.display(false);
            this.layoutUtilsService.showNotification(this.translate.instant('updated successfully'), this.translate.instant('Dismiss'));
          });
        }
      }
      else if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        this.loaderService.display(false);
      }
      this.notificationService.dnd.next(this.isDnd);
    });
  }

  editProfileImage() {
    this.menuTrigger.closeMenu();
    const dialogRef = this.dialog.open(ModalCropDialogComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: 'Upload image',
        data: this.selectedUser,
        currentImage: this.pictureLink,
        profile: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result || result === '') {
        this.pictureLink = result;
        // if (result['status']) {
        // 	window.location.reload();
        // }
        //do nothing
      }
    });
  }

  changeLanguage() {
    const dialogRef = this.dialog.open(ModalLanguageDialogComponent, {
      autoFocus: false,
      width: '500px',
      data: {
        title: this.translate.instant('Language'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
      }
    });
  }

  openUnReadDialog() {
    this.menuTrigger.closeMenu();
    const dialog = this.dialog.open(UnReadChatDialogComponent, {
      autoFocus: false,
      disableClose: false,
      width: 'fit-content',
      data: {
        // unread: Object.entries(this.unread).map(([key, value]) => ({ senderId: key, messages: value }))
        unread: this.unread,
        currentUserId: this.selectedUser._id,
      }
    });
  }

  openUnReadNotificationsDialog() {
    this.menuTrigger.closeMenu();
    const dialog = this.dialog.open(UnReadNotificationsDialogComponent, {
      autoFocus: false,
      disableClose: false,
      width: 'fit-content',
      data: {
        unread: this.unreadNotifications,
        currentUserId: this.selectedUser._id,
      }
    });
  }

  askSupport() {
    this.menuTrigger.closeMenu();
    const dialog = this.dialog.open(SupportDialogComponent, {
      disableClose: false,
      width: '50vw',
    })
  }
}
