import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagesStatusService } from "../../../../shared/services/messagesStatus.service";
import { Subscription } from 'rxjs';
@Component({
  selector: 'custom-dynamic-dialog',
  templateUrl: './custom-dynamic-dialog.component.html',
  styleUrls: ['./custom-dynamic-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomDynamicDialogComponent implements AfterContentInit, OnDestroy {
  @Input() name: string;
  @Input() width = '500px';
  @Input() keyName = undefined;
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;

  dialogRef: MatDialogRef<any>;
  componentSubscription: Subscription;

  constructor(private dialog: MatDialog, private messagesStatusService: MessagesStatusService) { }

  ngOnDestroy() {
    this.close();
  }
  ngAfterContentInit() {
    // this.open();
  }
  public open() {
    if (this.keyName) {
      let keyValue = this.messagesStatusService.getStatus(this.keyName);
      if (!keyValue) {
        this._open();
      }
    } else {
      this._open();
    }
  }
  private _open() {
    this.dialogRef = this.dialog.open(this.templateRef, {
      width: this.width,
      autoFocus: false, disableClose: true, restoreFocus: false, panelClass: 'custom-dynamic-dialog'
    });
    this.opened.emit();
  }

  public close() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
      this.closed.emit();
    }
  }
}
