<h1 mat-dialog-title class="titleMain">
    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <div fxFlex>
            <img class="profile" [src]="data.pictureLink" /> {{ data.name }}
        </div>
        <div fxFlex align="end" *ngIf="!data.hideCall && isInvestigatorIncluded">
            <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="end center">
                <div fxFlex fxGrow="0">
                    <button *ngIf="!isPopup" mat-fab (click)="callGroup()" [matTooltip]="'Call' | translate">
                        <mat-icon>call</mat-icon>
                    </button>
                </div>
                <div fxFlex fxGrow="0">
                    <button *ngIf="!isPopup" mat-fab (click)="chat(data , true)" [matTooltip]="'Chat' | translate">
                        <mat-icon>chat_bubble</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</h1>

<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="1vh" fxLayoutAlign="center start" style="min-width: 30vw;">
        <div fxFlex>
            <div class="investigatorsList">
                <table mat-table [dataSource]="datasourceInv" matSort class="mat-elevation-z8"
                    *ngIf="datasourceInv?.data?.length" (matSortChange)="announceSortChange($event)">
                    <ng-container matColumnDef="picture_link">
                        <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.pictureLink" class="profile-pic" />
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="alternative_name">
                        <th mat-header-cell *matHeaderCellDef style="width:25%;" mat-sort-header>{{ 'Name' | translate
                            }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.alternative_name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="rankName">
                        <th mat-header-cell *matHeaderCellDef style="width:15%;" mat-sort-header>{{ rankMeta.displayName
                            }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.rankName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="squad_ref">
                        <th mat-header-cell *matHeaderCellDef style="width:15%;" mat-sort-header>{{
                            squadMeta.displayName | translate
                            }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element?.squad_ref?.name || ''}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="call_sign">
                        <th mat-header-cell *matHeaderCellDef style="width:15%;">{{ callsignMeta.displayName |
                            translate }}</th>
                        <td mat-cell *matCellDef="let element" title="{{element.call_sign}}">
                            {{element.call_sign| truncate : ['50', '...']}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="remarks">
                        <th mat-header-cell *matHeaderCellDef style="width:30%;">{{ remarkMeta.displayName | translate
                            }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.remarks}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="id" style="width:10%;">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" style="text-align: right; text-wrap: nowrap;">
                            <ng-container *ngIf="element._id != currentUser?._id">
                                <!-- <mat-icon
                                    *ngIf="!isInvestigator && !rolesCannotView.includes(element.resources[0]?._id)"
                                    [matTooltip]="'View' | translate"
                                    (click)="viewProfile(element._id)">visibility</mat-icon> -->
                                <mat-icon
                                    *ngIf="!isInvestigator && !rolesCannotEdit.includes(element.resources[0]?._id)"
                                    [matTooltip]="'Edit' | translate" (click)="editUserMapData(element, element._id)">
                                    edit
                                </mat-icon>
                                <mat-icon *ngIf="!data.hideCall && !isPopup" (click)="call(element)"
                                    style="padding-left: 0.5vw;" [matTooltip]="'Call' | translate">call</mat-icon>
                                <mat-icon *ngIf="!data.hideCall  && !isPopup" (click)="chat(element)"
                                    style="padding-left: 0.5vw;" [style.color]="element.unread ? 'red' : ''"
                                    [matTooltip]="'Chat' | translate">{{element.unread ?
                                    'mark_chat_unread': 'chat_bubble'}}</mat-icon>
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsInv" style="background-color: #11345a;"></tr>
                    <tr mat-row *matRowDef="let row;let i = index; columns: displayedColumnsInv"
                        [ngClass]="'row-' + (i % 2)"></tr>
                </table>
                <!-- <span class="label">{{investigatorsMeta.displayName}}: </span>
                <span *ngFor="let itm of data.investigators;let index = index;">{{itm.name}}<span
                        *ngIf="index < data.investigators.length - 1">, </span></span> -->
            </div>
        </div>
        <!-- <div fxFlex>
            <span class="label">{{badgeNoMeta.displayName}}:</span> {{data.badgeNo || '-'}}
        </div>
        <div fxFlex>
            <span class="label">{{rankMeta.displayName}}:</span> {{(data.rank | enum: rankEnum) || '-'}}
        </div>
        <div fxFlex>
            <span class="label">{{divisionMeta.displayName}}:</span> {{(data.division | enum: divisionEnum)
            || '-'}}
        </div> -->
        <!-- <div fxFlex>
            <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center end">
                <div fxFlex fxGrow="0">
                    <button mat-fab (click)="callGroup()" [matTooltip]="'Call' | translate">
                        <mat-icon>call</mat-icon>
                    </button>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div mat-dialog-actions align="end">
    <!-- <button mat-raised-button (click)="viewProfile()"
        *ngIf="!isInvestigator && !rolesCannotView.includes(data.role._id)">
        {{ "View Profile" | translate }}
    </button> -->

    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <!-- <div fxFlex>
            <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="start center">
                <div fxFlex fxGrow="0">
                    <button mat-fab (click)="callGroup()" [matTooltip]="'Call' | translate">
                        <mat-icon>call</mat-icon>
                    </button>
                </div>
            </div>
        </div> -->
        <div fxFlex align="end">
            <button class="green-text" mat-raised-button (click)="editInvGroup()"
                *ngIf="!isInvestigator && data && data.caseId && data.groupMetadata">
                {{ "Edit" | translate }}
            </button>
            <button class="red-text" mat-raised-button mat-dialog-close>
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</div>