<h1 mat-dialog-title class="titleMain">
    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center" style="
    padding: 0px 0px 15px 0px;">
        <div fxFlex align="center">
            <img class="profile" [src]="data.pictureLink" />
            <div>{{ data.name }}</div>
            <div align="center" *ngIf="!data.hideCall" style="margin-top: 10px;">
                <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center center">
                    <div fxFlex fxGrow="0" *ngIf="currentUser._id !== data._id && !isPopup">
                        <button mat-fab (click)="call()" [matTooltip]="'Call' | translate">
                            <mat-icon>call</mat-icon>
                        </button>
                    </div>
                    <div fxFlex fxGrow="0" *ngIf="!isInvestigator || currentUser._id == data._id">
                        <button mat-fab (click)="logs()" [matTooltip]="'Call Logs' | translate">
                            <img src="assets/images/icon/call_log.svg" />
                        </button>
                    </div>
                    <div fxFlex fxGrow="0" *ngIf="currentUser._id !== data._id && !isPopup">
                        <button mat-fab (click)="chat()" [matTooltip]="'Chat' | translate">
                            <mat-icon>chat_bubble</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</h1>

<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="1vh" fxLayoutAlign="center start" class="investigator-info">
        <table>
            <tbody>
                <tr class="row-0">
                    <td class="label">{{badgeNoMeta.displayName}}</td>
                    <td class="info">{{data.badgeNo || '-'}}</td>
                </tr>
                <tr class="row-1">
                    <td class="label">{{rankMeta.displayName}}</td>
                    <td class="info">{{(data.rank | enum: rankEnum) || '-'}}</td>
                </tr>
                <tr class="row-0">
                    <td class="label">{{divisionMeta.displayName}}</td>
                    <td class="info">
                        <span *ngFor="let division of data['division'];let indexDivision = index;"
                            title="{{division.name}}">
                            {{division.name| truncate: ['20','...']}}<span
                                *ngIf="indexDivision < data['division'].length - 1"></span>
                        </span>
                        <span *ngIf="data['division'].length === 0">-</span>
                    </td>
                </tr>
                <tr class="row-1">
                    <td class="label">{{phoneMeta.displayName}}</td>
                    <td class="info">{{data.phone || '-'}}</td>
                </tr>

                <ng-container *ngIf="data && data.userMapMetadata && data.caseId">
                    <tr class="row-0">
                        <td class="label">{{squadMeta.displayName}}</td>
                        <td class="info">{{data?.squad_ref?.name || '-'}}</td>
                    </tr>
                    <tr class="row-1">
                        <td class="label">{{callsignMeta.displayName}}</td>
                        <td class="info" title="{{data.call_sign}}">{{(data.call_sign || '-')| truncate : ['50',
                            '...']}}</td>
                    </tr>
                    <tr class="row-0">
                        <td class="label">{{remarkMeta.displayName}}</td>
                        <td class="info">{{data.remarks || '-'}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <!-- <div fxFlex>
            <div fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="start center">
                <div fxFlex fxGrow="0">
                    <button mat-fab (click)="call()" [matTooltip]="'Call' | translate">
                        <mat-icon>call</mat-icon>
                    </button>
                </div>
                <div fxFlex fxGrow="0">
                    <button mat-fab (click)="chat()" [matTooltip]="'Chat' | translate">
                        <mat-icon>chat_bubble_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div> -->
        <div fxFlex align="end">
            <button class="green-text" mat-raised-button (click)="editUserMapData()"
                *ngIf="!isInvestigator && data && data.caseId && data.userMapMetadata && !rolesCannotEdit.includes(data.role._id)">
                {{ "Edit" | translate }}
            </button>
            <button mat-raised-button (click)="viewProfile()"
                *ngIf="!isInvestigator && data && data.role && !rolesCannotView.includes(data.role._id)">
                {{ "View Profile" | translate }}
            </button>
            <button class="red-text" mat-raised-button mat-dialog-close>
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</div>