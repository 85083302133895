import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';
import { collection, where, getDocs, writeBatch, doc, getFirestore, query } from 'firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    // public showNotificationUnRead: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dnd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
    public refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public unread: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    private db = getFirestore();

    constructor(private snackBar: MatSnackBar, private requestService: RequestService) { }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000
        });
    }

    getNotifications(term: string, pageIndex: number, pageSize: number, caseId?: string): Promise<void> {
        let filter: any = {
            "$and": [
                { "organizationId._id": { "$eq": this.requestService.orgId } },
                { "type": { "$nin": ['requestcamera'] } } // we exclude assign case and request camera
            ]
        };
        let currentUser = this.requestService.currentUser;
        let isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
        let isInvestigator = this.requestService.isUserRoleInvestigator();
        if (currentUser) {
            if (isSuperAdmin) {
                // nothing
            } else {
                if (isInvestigator) {
                    filter["$and"].push(
                        { "investigators._id": { "$in": [currentUser._id] } }
                    );
                } else {
                    if (currentUser['prefecture']) {
                        filter['$and'].push({ "prefecture": { "$eq": currentUser['prefecture'] } });
                    }
                    if (currentUser['region']) {
                        filter['$and'].push({ "region": { "$eq": currentUser['region'] } });
                    }
                    // filter["$and"].push(
                    //     { "users": { "$size": 0 } }
                    // );
                }
            }
        }

        if (caseId) {
            filter["$and"].push(
                { "case._id": { "$eq": caseId } }
            );
        }

        return new Promise((resolve, reject) => {
            this.requestService.getDataList('notify', {
                term: term || '', termfields: ['message', 'user.name', 'createdName', 'location', 'case.name'], page: pageIndex + 1, perpage: pageSize, filter: filter, orderBy: 'updatedAt', orderDir: 'desc', fieldKeys: ['message', 'title', 'type', 'case', 'note', 'document', 'user', 'createdName', 'location', 'geolocation', 'updatedAt', 'investigators', 'investigatorgroup', 'description', 'createdAt', 'prefecture', 'region']
            }, (data: any, error: any) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    async markAsRead(currentUserId, caseId?: string) {
        try {
            const colRef = collection(this.db, `${environment.firestore_config.unreadDb}/${currentUserId}/notifications`);
            let snapshot;

            if (caseId) {
                let userQuery = query(colRef, where('caseId', '==', caseId));
                snapshot = await getDocs(userQuery);
            }
            else {
                snapshot = await getDocs(colRef);
            }

            if (snapshot.empty) {
                // console.log('No documents to delete.');
                return;
            }

            const batch = writeBatch(this.db);

            snapshot.docs.forEach((docSnap) => {
                const docRef = doc(this.db, colRef.path, docSnap.id);
                batch.delete(docRef);
            });

            await batch.commit();
        }
        catch (e) { }
    }
}
