<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content style="overflow:visible">
		<div>
			<mat-card style="min-height: 250px;">
				<!-- <app-tiles-list #tileList (selectItem)="selectItemCheck($event)" [hasCategories]="true" [canDelete]="false"></app-tiles-list> -->
				<app-custom-select [value]="data.data" [itemName]="'datacustom'" [dataType]="this.data.dataType"
					[apiTarget]="'searchObject'" [placeholder]="this.data.dataTypeTitle"
					(onSelectReturn)="selectItemCheck($event)"></app-custom-select>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button class="red-text" mat-raised-button (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
			</div>
		</div>
		<!-- <button mat-raised-button (click)="closeModal({clear: true})">{{'Clear' | translate}}</button> -->
	</div>
	<br />
</div>