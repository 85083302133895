import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { LayoutUtilsService, LoaderService, ModalDialogComponent, RequestService } from "src/app/shared";
import { CaseService } from "src/app/shared/services/case.service";
import { ChatService } from "src/app/shared/services/chat.service";
import { environment } from "src/environments/environment";
import { EditInvestigatorMapDialogComponent } from "../edit-investigator-map-dialog/edit-investigator-map-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { openCenteredPopup } from "src/app/shared/helpers";

interface Profile {
    _id: string;
    name: string;
    // badgeNo: string;
    // rank: string;
    // division: string;
    caseId: string;
    pictureLink: string;
    groupMetadata: any;
    userMetadata: any;
    userMapMetadata: any;
    investigatorsMapData: any[];
    investigators: any[];
    hideCall: boolean;
}

@Component({
    selector: 'app-view-investigator-group-dialog',
    templateUrl: './view-investigator-group-dialog.component.html',
    styleUrls: ['./view-investigator-group-dialog.component.scss'],
})

export class ViewInvestigatorGroupDialogComponent implements OnInit, OnDestroy {

    public dataReturnedUsers: any = undefined;
    public isPopup: boolean = false;
    protected subscriptions: Subscription[] = [];
    private currentUser: any = undefined;
    public isAdmin: boolean = false;
    public isSuperAdmin: boolean = false;
    public rankEnum: any = [];
    // public divisionEnum: any = [];
    public nameMeta = undefined;
    public badgeNoMeta = undefined;
    public rankMeta = undefined;
    public divisionMeta = undefined;
    public squadMeta = undefined;
    public remarkMeta = undefined;
    public callsignMeta = undefined;
    pageNumber = 1;
    orderDir = 'asc';
    orderBy = 'name';
    public investigatorsMeta = undefined;
    public loading: boolean = false;
    public isInvestigator: boolean = false;
    public isInvestigatorIncluded: boolean = true;
    public displayedColumnsInv: string[] = ['picture_link', 'alternative_name', 'rankName', 'id'];
    public datasourceInv: any = [];
    public originalDatasource: any = [];
    public rolesCannotView: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];

    public rolesCannotEdit: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];
    constructor(private translate: TranslateService, private requestService: RequestService, public dialogRef: MatDialogRef<ViewInvestigatorGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Profile, private router: Router, private chatService: ChatService, private caseService: CaseService, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private loader: LoaderService) {
        if (window.opener) {
            this.isPopup = true;
        }
    }

    ngOnInit(): void {
        // this.rankEnum = this.data.userMetadata.fields.find(i => i.name == 'rank').enum;
        // this.divisionEnum = this.data.groupMetadata.fields.find(i => i.name == 'division').enum;
        // this.badgeNoMeta = this.data.groupMetadata.fields.find(i => i.name == 'badge_no');
        // this.rankMeta = this.data.groupMetadata.fields.find(i => i.name == 'rank');
        // this.divisionMeta = this.data.groupMetadata.fields.find(i => i.name == 'division');
        this.investigatorsMeta = this.data.groupMetadata.fields.find(i => i.name == 'investigators');
        if (this.data.userMetadata) {
            this.nameMeta = this.data.userMetadata.fields.find(i => i.name == 'name');
            this.rankMeta = this.data.userMetadata.fields.find(i => i.name == 'rank');
        }
        if (this.data.caseId && this.data.userMapMetadata) {
            this.displayedColumnsInv = ['picture_link', 'alternative_name', 'rankName', 'squad_ref', 'call_sign', 'remarks', 'id'];
            this.squadMeta = this.data.userMapMetadata.fields.find(i => i.name == 'squad_ref');
            this.remarkMeta = this.data.userMapMetadata.fields.find(i => i.name == 'remarks');
            this.callsignMeta = this.data.userMapMetadata.fields.find(i => i.name == 'call_sign');
        }
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.currentUser = data;
                    this.isAdmin = this.requestService.isUserRoleAdmin();
                    this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                    this.getInvs();
                    if (this.isInvestigator) {
                        let findUser = this.data.investigators.find((usr) => usr._id == this.currentUser._id);
                        if (!findUser) {
                            this.isInvestigatorIncluded = false;
                        }
                    }
                }
            })
        );

        this.subscriptions.push(this.chatService.unreadSubject.subscribe(unread => {
            if (this.dataReturnedUsers) {
                this.dataReturnedUsers.map(i => {
                    let unreadMessage = unread.find(j => j.id == i._id);
                    if (unreadMessage) {
                        i.createdAt = unreadMessage.createdAt.toDate().getTime();
                        i.unread = true;
                    }
                    else {
                        i.createdAt = null;
                        i.unread = false;
                    }
                });

                this.datasourceInv = new MatTableDataSource<any>(this.dataReturnedUsers);
                this.originalDatasource = this.dataReturnedUsers;
            }
        }));
        // console.log(this.data);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    viewProfile(id) {
        if (id) {
            this.dialogRef.close();
            // window.open(`/investigator/${id}`, "_blank")
            openCenteredPopup(`/investigator/${id}`, this.translate.instant('Investigator'));
            // this.router.navigate([`/investigator/${this.data._id}`]);
        }
    }
    getInvs() {
        let investigatorsIds = this.data.investigators.map(itm => itm._id);
        this.loadDataSearch(investigatorsIds);
    }
    callGroup() {
        this.chatService.showCallDialogSubject.next({
            show: true,
            caseId: this.data?.caseId || undefined,
            currentInvestigatorGroup: this.data,
            investigatorGroups: [this.data],
            investigators: this.data.investigators.map(item => ({
                _id: item._id,
                name: item.alternative_name
            })),
            selectedUsers: this.data.investigators.map(item => (
                item._id
            )),
            callerId: this.currentUser._id,
            invitedBy: this.currentUser._id,
        });
        this.dialogRef.close();
    }
    call(element) {
        this.chatService.showCallDialogSubject.next({
            show: true,
            caseId: this.data?.caseId || undefined,
            currentCaseInvestigator: { _id: element._id, name: element.alternative_name },
            investigators: [{ _id: element._id, name: element.alternative_name }],
            selectedUsers: [element._id],
            callerId: this.currentUser._id,
            invitedBy: this.currentUser._id,
        });
        this.dialogRef.close();
    }

    chat(element, isGroup = false) {
        // console.log(element);
        // element.tokens = [];
        // element.deviceids?.map(j => element.tokens.push(j.deviceId));
        // this.chatService.selectedUser.next({ id: element._id, name: element.alternative_name || element.name, isGroup: isGroup, role: undefined });

        // let investigators = [{ _id: element._id, name: element.alternative_name || element.name, deviceids: element.tokens }];
        // let investigatorGroups = [];
        // if (this.data.caseId) {
        //     investigators = this.caseService.investigators;
        //     investigatorGroups = this.caseService.investigatorGroups;
        // }

        // this.chatService.showChatDialogSubject.next({
        //     show: true,
        //     investigators: investigators,
        //     caseId: this.data?.caseId || undefined,
        //     caseName: undefined,
        //     investigatorGroups: investigatorGroups,
        //     isInvestigatorProfile: this.data?.caseId ? false : true
        // });
        if (this.data?.caseId) {
            let url = '/global-chat/' + element._id + '/' + this.data.caseId;
            // window.open(url);
            if (isGroup) {
                url = '/global-chat/' + element._id + '/' + this.data.caseId + '/group';

                openCenteredPopup(url, this.translate.instant('General Chat') + element._id + '/' + this.data.caseId + '/group');
            } else {
                openCenteredPopup(url, this.translate.instant('General Chat') + element._id + '/' + this.data.caseId);
            }
        } else {
            let url = '/global-chat/' + element._id;
            openCenteredPopup(url, this.translate.instant('General Chat') + element._id);
        }
        this.dialogRef.close();
    }
    public loadDataSearch(investigatorsIds) {
        if (!this.loading) {
            this.loading = true;
            this.loader.display(true);
            let fieldsConfiguration = ["name", "alternative_name", "region", "prefecture", "geolocation", "pictureLink", "tokens", "lastName", "firstName", "rank", "resources"];
            let filters = {};
            filters['$and'] = [
                { "organizationId._id": { "$in": [this.requestService.orgId] } },
                { "resources._id": { "$eq": environment.customKeys.roleInvestigators } }
            ];
            filters['$and'].push({ "_id": { "$in": investigatorsIds } });
            this.requestService.getDataList('user', { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, perpage: 1000, filter: filters, fieldKeys: fieldsConfiguration }, (data, error) => {
                if (error) {
                    this.datasourceInv = new MatTableDataSource<any>(this.data.investigators);
                    this.originalDatasource = this.data.investigators;
                }
                if (data) {
                    this.dataReturnedUsers = data.results;
                    if (this.data.caseId) {
                        this.dataReturnedUsers = this.caseService.mapUsersData(this.dataReturnedUsers, this.data.investigatorsMapData);
                    }

                    let unread = this.chatService.unreadSubject.getValue();
                    this.dataReturnedUsers.map(i => {
                        let unreadMessage = unread.find(j => j.id == i._id);
                        if (unreadMessage) {
                            i.createdAt = unreadMessage.createdAt;
                            i.unread = true;
                        }
                        else {
                            i.createdAt = null;
                            i.unread = false;
                        }
                    });

                    this.datasourceInv = new MatTableDataSource<any>(this.dataReturnedUsers);
                    this.originalDatasource = this.dataReturnedUsers;
                }
                this.loader.display(false);
                this.loading = false;
            });
        }
    }
    editUserMapData(element, userId) {
        const dialogRef = this.dialog.open(EditInvestigatorMapDialogComponent, {
            autoFocus: false,
            width: '50vw',
            data: {
                caseId: this.data.caseId,
                userId: userId,
                metadata: this.data.userMapMetadata,
                title: this.translate.instant('Edit Investigator'),
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.action && result.action == 'save') {
                    this.updateMapData(element, result);
                } else {
                    this.caseService.getInvestigatorsCaseData(this.data.caseId).then((data: any) => {
                        if (data && data.results) {
                            this.data.investigatorsMapData = data.results;
                        }
                        this.caseService.investigatorsMapUpdated.next(true);
                    });
                }
            }
        });
    }
    updateMapData(element, result) {
        element.squad_ref = result.data.squad_ref;
        element.remarks = result.data.remarks;
        element.call_sign = result.data.call_sign;
        this.caseService.getInvestigatorsCaseData(this.data.caseId).then((data: any) => {
            if (data && data.results) {
                this.data.investigatorsMapData = data.results;
            }
            this.caseService.investigatorsMapUpdated.next(true);
        });
    }
    announceSortChange(event) {
        this.sortData(event.active, event.direction);
    }
    sortData(column: string, direction: 'asc' | 'desc' | '') {
        if (!direction) {
            this.datasourceInv.data = [...this.originalDatasource]; // Reset to original data
            return;
        }

        this.datasourceInv.data = [...this.datasourceInv.data].sort((a, b) => {
            const valueA = this.getSortableValue(a, column);
            const valueB = this.getSortableValue(b, column);

            // Handle empty or undefined values (always move them to the end)
            if (valueA == null || valueA === undefined || valueA === '') return 1;
            if (valueB == null || valueB === undefined || valueB === '') return -1;

            return direction === 'asc' ? (valueA > valueB ? 1 : -1) : (valueA < valueB ? 1 : -1);
        });
    }

    /**
     * Extracts sortable values (Handles numbers, strings, and dates)
     */
    getSortableValue(item: any, column: string): any {
        const value = item[column];
        if (typeof value === 'string') return value.toLowerCase(); // Case-insensitive sorting
        if (value instanceof Date) return value.getTime(); // Sort dates numerically
        if (value instanceof Object) return (value?.name || '').toLowerCase();
        return value; // Default (numbers, booleans, etc.)
    }
    editInvGroup() {
        const dialogRef = this.dialog.open(ModalDialogComponent, {
            autoFocus: false,
            width: "850px",
            data: {
                dataType: 'investigatorgroup',
                dataTypeTitle: this.translate.instant('Investigators Group'),
                title: this.translate.instant("Edit") + " " + this.translate.instant("Investigators Group"),
                data: { _id: this.data._id },
                modalSetting: this.getGroupSetting({}),
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.action === 'refresh') {
                if (result.data) {
                    this.data.name = result.data.name;
                    let investigatorsPromise = this.caseService.getInvestigators(this.data.caseId);
                    let investigatorGroupsPromise = this.caseService.getInvestigatorGroups(this.data.caseId).then((data: any) => {
                        let dataResult = [];
                        if (data) {
                            dataResult = data;
                        }
                        let investigatorGroupIds = dataResult.find(itm => itm._id == this.data._id);
                        this.data.investigators = investigatorGroupIds?.investigators || [];
                        this.getInvs();
                    });

                    Promise.all([investigatorsPromise, investigatorGroupsPromise]).then(() => {
                        this.caseService.investigatorsUpdated.next(true);
                        this.caseService.investigatorsGroupsUpdated.next(true);
                    }).catch(error => {
                        this.layoutUtilsService.showNotification(this.translate.instant(error), this.translate.instant('Dismiss'));
                    });
                }
            }

        });

    }
    protected getGroupSetting(data) {
        let tableSetting = data;
        tableSetting['target'] = 'self';
        let caseInvestigators = {};
        caseInvestigators['$and'] = [
            { "organizationId._id": { "$in": [this.requestService.orgId] } },
            { "resources._id": { "$eq": environment.customKeys.roleInvestigators } }
        ];
        if (!this.isSuperAdmin) {
            caseInvestigators['$and'].push({ "region": { "$eq": this.currentUser.region } });
            caseInvestigators['$and'].push({ "prefecture": { "$eq": this.currentUser.prefecture } });
        }
        tableSetting['customSettings'] = {
            organizationId: {
                visible: false,
                value: [{ _id: this.requestService.orgId, name: '' }]
            },
            investigators: {
                filters: caseInvestigators
            },
            display: {
                visible: true
            }
        };
        if (!this.isSuperAdmin) {
            tableSetting['customSettings']['region'] = {
                disabled: true
            }
        }
        if (!this.isSuperAdmin) {
            tableSetting['customSettings']['prefecture'] = {
                disabled: true
            }
        }
        return tableSetting;
    }
}