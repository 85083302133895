import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomMetadataTableComponent } from '../custom-metadata-table/custom-metadata-table.component';
import { LayoutUtilsService, RequestService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalCropDialogComponent } from '../custom-crop-dialog/custom-crop-dialog.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { ModalFieldCropDialogComponent } from '../custom-field-crop-dialog/custom-field-crop-dialog.component';

interface ViewDialogData {
	title: string;
	dataType: string;
	dataTypeDisplay: string;
	dataTypeSingleDisplay: string;
	focusField: string;
	defaultFields: string[];
	fieldsToHide: string[];
	canNew: boolean;
	canIconUpload: boolean;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-custom-create-table-dialog-modal',
	templateUrl: './custom-create-table-dialog.component.html',
	styleUrls: ['./custom-create-table-dialog.component.scss']
})
export class CustomCreateTableDialogComponent implements OnInit {
	public itemName = '';
	protected subscriptions: Subscription[] = [];
	public isFirstLoaded: boolean = false;
	public selectedUser: any;
	public selectedUserRole: any;
	public isAdmin: boolean = false;
	public isSuperAdmin: boolean = false;
	public changeOccured: boolean = false;
	public originalTableSetting: any = undefined;
	public tableSetting: any = undefined;
	public errorMessage: string = '';
	public filterObject: any = undefined;
	public defaultFields: string[] = [];
	public focusField: string = 'name';
	public fieldsObject: string[] = JSON.parse(JSON.stringify(this.defaultFields));

	public searchVal: string = '';

	public region: string = undefined;
	public prefecture: string = undefined;
	public canImport: boolean = false;
	public canExport: boolean = false;
	public canFilter: boolean = false;
	public canIconUpload: boolean = false;
	public canNew: boolean = true;
	public loading: boolean = false;
	public tableName: string = '';
	public dataType: string = undefined;
	public dataTypeDisplay: string = '';
	public dataTypeSingleDisplay: string = ''
	public fieldsToHide: string[] = [];
	public targetName: string = '';
	orderDir = 'asc';
	orderBy = 'name'; // _id
	orderByList = [{ field: 'name', order: 'asc' }];

	@ViewChild('customMetadataTable') custom_metadata_table: CustomMetadataTableComponent;
	@ViewChild('searchInput') searchInput: ElementRef;
	constructor(public dialogRef: MatDialogRef<CustomCreateTableDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, protected requestService: RequestService, protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected layoutUtilsService: LayoutUtilsService,
		public dialog: MatDialog, protected translate: TranslateService) {
		// console.log('ViewDialogData', data);
		this.targetName = data.dataType;
		this.canNew = data.canNew || true;
		this.canIconUpload = data.canIconUpload || false;
		this.tableName = data.dataType + '-admin';
		this.defaultFields = data.defaultFields || ['name'];
		this.fieldsObject = JSON.parse(JSON.stringify(this.defaultFields));
		this.fieldsToHide = data.fieldsToHide || [];
		this.dataTypeDisplay = data.dataTypeDisplay ? this.translate.instant(data.dataTypeDisplay) : '';
		this.dataTypeSingleDisplay = data.dataTypeSingleDisplay ? this.translate.instant(data.dataTypeSingleDisplay) : '';
		this.dataType = data.dataType; // this should always be end
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					// console.log('currentUserSubject', data);
					this.selectedUser = data;
					this.selectedUserRole = this.requestService.getUserRole();
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
					if (!this.region) {
						this.region = this.selectedUser.region
					}
					if (!this.prefecture) {
						this.prefecture = this.selectedUser.prefecture
					}
					this.buildSetting();
				}
			})
		);
	}
	/**
   * On Destroy
   */
	ngOnDestroy() {
		this.isFirstLoaded = false;
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	closeModal(data): void {
		if (this.changeOccured) {
			this.dialogRef.close({ action: 'refresh', data });
		} else {
			this.dialogRef.close(data);
		}
	}
	termConfiguration(): any {
		if (this.searchInput) {
			const searchText: string = this.searchInput.nativeElement.value;
			return searchText;
		}
		return '';
	}
	public toggleClick(event: any) {
		console.log('toggleClickParent', event);
		if (event.action === 'active') {
		} else if (event.action === 'refresh') {
			this.changeOccured = true;
		} else if (event.action === 'create') {
			//do nothing
		} else if (event.action === 'edit') {
			//do nothing
		} else if (event.action === 'view') {
			const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
				autoFocus: false,
				width: '850px',
				data: {
					dataType: this.dataType,
					title: this.translate.instant('Details'),
					data: { _id: event.data._id },
					modalSetting: undefined
				}
			});
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					if (result === 'edit') {
						this.custom_metadata_table.toggleClick('edit', 'self', { _id: event.data._id }, undefined);
					}
				}
			});
		} else if (event.action === 'uploadUserImage') {
			console.log('this.pictureLink', event);
			const dialogRef = this.dialog.open(ModalFieldCropDialogComponent, {
				autoFocus: false,
				width: '800px',
				data: {
					dataType: this.dataType,
					dataTypeTitle: this.dataTypeDisplay,
					title: this.translate.instant('Upload icon'),
					data: event.data,
					currentImage: event.data.imageLink,
					variable: 'imageLink',
					maxHeight: 80,
					maxWidth: 40,
					roundCropper: false,
					maintainAspectRatio: false,
					targetDeleteApi: 'deleteimage',
					defaultPictureLink: 'assets/images/icon/police-pin-black.png',
					modalSetting: JSON.parse(JSON.stringify(this.tableSetting)),
				}
			});
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					if (result['status']) {
						setTimeout(() => {
							this.custom_metadata_table.refresh();
						}, 300);
					}
				}
			});
		}
	}
	protected buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let fields: any[] = this.fieldsObject;
			this.requestService.getMetaData(this.dataType, fields, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
				}
				if (data) {
					this.originalTableSetting = Object.assign({}, data.results);
					const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
					this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
				} else {
					console.log(this.translate.instant('Something is Wrong'));
				}
				this.loading = false;
			});
		}
	}
	protected getSetting(data, filters) {
		let tableSetting = data;
		tableSetting['target'] = 'self';
		if (filters)
			tableSetting['filters'] = filters;
		let caseManagerFilter = {};
		// caseManagerFilter['$and'] = [
		//   { "organizationId._id": { "$in": [this.requestService.orgId] } },
		//   { "resources._id": { "$eq": environment.customKeys.roleCaseManager } }
		// ];
		tableSetting['customSettings'] = {
			organizationId: {
				visible: false,
				value: [{ _id: this.requestService.orgId, name: '' }]
			},
			region: {
				visible: false,
				value: this.region
			},
			prefecture: {
				visible: false,
				value: this.prefecture
			}
		};

		let actions: any =
		{
			displayName: this.translate.instant('Actions'), name: 'action', type: 'action', dataType: 'array', orderable: false, actions: [
				// { action: 'view', displayName: this.translate.instant('Details'), target: 'parent', icon: 'web_asset' }
			], editable: false, required: false, visible: true
		}
		if (data.editable) {
			actions.actions.push(
				{ action: 'edit', displayName: 'Edit', target: 'self', icon: 'create' }
			)
		}
		if (data.editable && this.canIconUpload) {
			actions.actions.push(
				{ action: 'uploadUserImage', displayName: this.translate.instant('Upload icon'), target: 'parent', icon: 'image' }
			)
		}

		if (data.deletable) {
			actions.actions.push(
				{ action: 'delete', displayName: 'Delete', target: 'self', icon: 'delete', checkusability: true }
				// { action: 'delete', displayName: 'Delete', target: 'self', icon: 'delete' , rule: ['isSuperAdmin' , undefined ]}
			)
		}
		let idx = 0;
		// console.log(tableSetting.fields);
		// for (let fld of tableSetting.fields) {

		// 	idx++;
		// }
		tableSetting.fields.push(actions);
		return tableSetting;
	}
	protected getCustomFilter() {
		if (this.filterObject) {
			return this.filterObject;
		} else {
			let filters = {};
			filters['$and'] = [
				{ "organizationId._id": { "$in": [this.requestService.orgId] } },
				{ "region": { "$eq": this.region } },
				{ "prefecture": { "$eq": this.prefecture } }
			];
			return filters;
		}
	}
}
