import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CaseService } from "../../services/case.service";

@Component({
    selector: 'app-camera-device',
    templateUrl: './camera-device.component.html',
    styleUrls: ['./camera-device.component.scss']
})
export class CameraDeviceComponent implements OnInit, OnDestroy, AfterViewInit {

    private subscriptions: any = [];
    private subscriber: OT.Subscriber;

    @Input('id') cameraId: string = '';
    @Input('name') name: string = '';

    @ViewChild('camera') container: ElementRef;

    constructor(private caseService: CaseService) { }

    ngOnInit(): void {

        // this.subscriptions.push(this.caseService.cameraSteamDestroyed.subscribe((stream) => {
        //     if (stream) {
        //         let dataToken = JSON.parse(stream.connection.data);
        //         // document.getElementById(`user-${dataToken.userId}`).remove();
        //     }
        // }));

        // this.subscriptions.push(this.caseService.cameraSteamChanged.subscribe((stream) => {
        //     if (stream) {

        //     }
        // }));
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.caseService.cameraSteamCreated.subscribe((streams: any) => {
            if (streams?.length) {
                let stream: OT.Stream = streams.find(s => s.name == ('camera-' + this.cameraId))
                // console.log(streams)
                // streams.forEach((stream: any) => {
                //     if (stream?.name == ('camera-' + this.cameraId) && !stream.destroyed) {
                //         let dataToken = JSON.parse(stream.connection.data);
                //         this.addCamera(dataToken, stream);
                //     }
                // });
                if (stream) {
                    let dataToken = JSON.parse(stream.connection.data);
                    this.addCamera(dataToken, stream);
                }
            }
        }));
    }

    ngOnDestroy(): void {
        if (this.subscriber) {
            this.caseService.unsubscribeStream(this.subscriber);
        }
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    private async addCamera(data: any, stream: OT.Stream) {
        const boxDiv = document.createElement('div');
        // boxDiv.classList.add('video-box');
        boxDiv.id = `camera-${data.userId}`;
        boxDiv.style.height = '100%';
        boxDiv.style.width = '100%';

        // if (document.getElementById('device-cameras')) {
        //     document.getElementById('device-cameras').appendChild(boxDiv);
        // }


        // let container = document.getElementById('camera-' + data.userId);

        this.subscriber = await this.caseService.subscribeStream(stream, this.container.nativeElement, 'replace', () => { });
    }
}