<div class="col-xl-12">
	<h1 mat-dialog-title>
		<div fxLayout="row" fxFlex fxLayoutGap="10px">
			<div fxFlex class="title">
				{{data.title | translate}}
			</div>
			<div fxFlex align="right">
				<button class="primaryColor" mat-icon-button color="secondary" *ngIf="tabMode === 'map'"
					(click)="openMap(false)">
					<mat-icon>list</mat-icon>
				</button>
				<button class="primaryColor" mat-icon-button color="secondary" *ngIf="tabMode === 'list'"
					(click)="openMap(true)">
					<mat-icon>map</mat-icon>
				</button>
				<button class="primaryColor" mat-icon-button matTooltip="{{'Add Group' | translate}}" color="secondary"
					*ngIf="tabMode === 'invgroup'" (click)="addGroup()">
					<mat-icon>add_circle</mat-icon>
				</button>
			</div>
		</div>
	</h1>
	<div mat-dialog-content class="select-page-body">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex class="availableInv">
				<div fxLayout="column" fxFlex fxLayoutGap="10px">
					<div fxFlex="15" class="title">
						{{'List' | translate }}
					</div>
					<div fxFlex class="list">
						<div [hidden]="!showMap" class="mapView">
							<app-google-map #currentDialogMap [height]="'350px'" [addMyMarker]="false"
								(geoLocation)="showGeoLocation($event)" [isHoverable]="true"
								(markerSelect)="markerSelect($event)" (markerInfoSelect)="infoSelect($event)">
							</app-google-map>
						</div>
						<div [hidden]="showMap" class="listView">
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex style="height: 55px;">
								<div fxFlex>
									<mat-button-toggle-group name="fontStyle" aria-label="Font Style"
										[(ngModel)]="tabMode" (change)="changeMode($event.value)">
										<mat-button-toggle value="list">{{'Individual' |
											translate}}</mat-button-toggle>
										<mat-button-toggle value="invgroup">{{'Group' |
											translate}}</mat-button-toggle>
									</mat-button-toggle-group>
								</div>
								<div fxFlex [align]="'right'">
									<mat-form-field [hidden]="!(canSearch)" style="width: 100%;">
										<input matInput #searchInput placeholder="{{'Search' | translate}}"
											[value]="searchVal" maxlength="100" [disabled]="loading || loadingGroup">
									</mat-form-field>
								</div>
							</div>
							<div fxLayout="column" fxFlex [hidden]="tabMode !== 'list'"
								style="height: calc(100% - 55px);">
								<div fxFlex class="gridList">
									<mat-list role="list" style="display: grid">
										<mat-list-item role="listitem" *ngFor="let usr of optionData;let index = index">
											<div class="list-item-container">
												<div class="column column-1 clickable"
													(click)="loadCameraDataSec(usr._id)">
													{{usr.name | truncate: ['50','...']}}
												</div>
												<div class="column column-3">
													<mat-icon class="addColor" matTooltip="{{'Select' | translate}}"
														(click)="selectUserItem(usr, index)">arrow_forward</mat-icon>
												</div>
											</div>
										</mat-list-item>
									</mat-list>
								</div>
								<div fxFlex="20">
									<mat-paginator #paginatorsingle class="paginator" [pageIndex]="pageNumber-1"
										[hidden]="!pagination" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25]"
										[length]="paginatorTotal | async" [showFirstLastButtons]="true"></mat-paginator>
								</div>
							</div>
							<div fxLayout="column" fxFlex [hidden]="tabMode !== 'invgroup'"
								style="height: calc(100% - 55px);">
								<div fxFlex class="gridList">
									<mat-list role="list" style="display: grid">
										<mat-list-item role="listitem"
											*ngFor="let grp of optionGroupData;let index = index">
											<div class="list-item-container">
												<div class="column column-1 clickable"
													(click)="loadCameraGRoupDataSec(grp._id)">
													{{grp.name | truncate: ['50','...']}} <span
														*ngIf="grp.cameras && grp.cameras.length > 0">(<span
															*ngFor="let itm of grp.cameras;let cIndex = index">{{itm.name}}<span
																*ngIf="cIndex < grp.cameras.length -1">,
															</span></span>)</span>
												</div>
												<div class="column column-3">
													<mat-icon class="primaryColor" matTooltip="{{'Edit' | translate}}"
														(click)="editCameraGroup(grp, index)">edit</mat-icon>
													<mat-icon class="addColor" matTooltip="{{'Select' | translate}}"
														(click)="selectGroupItem(grp, index)">arrow_forward</mat-icon>
													<!-- <mat-icon (click)="deSelectItem()">arrow_back</mat-icon> -->
												</div>
											</div>
										</mat-list-item>
									</mat-list>
								</div>
								<div fxFlex="20">
									<mat-paginator #paginatorgroup class="paginator" [pageIndex]="pageNumberGroup-1"
										[hidden]="!paginationGroup" [pageSize]="pageSizeGroup"
										[pageSizeOptions]=" [5, 10, 25]" [length]="paginatorTotalGroup | async"
										[showFirstLastButtons]="true"></mat-paginator>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div fxFlex class="selectedInv">
				<div fxLayout="column" fxFlex fxLayoutGap="10px">
					<div fxFlex="15" class="title">
						{{'Already selected' | translate }}
						<!-- <button mat-button color="secondary" (click)="clearData()">
						<mat-icon>update</mat-icon> {{'Clear Selection' | translate}}
					</button> -->
					</div>
					<div fxFlex class="list">
						<div class="gridList">
							<mat-list role="list" style="display: grid">
								<mat-list-item role="listitem" *ngFor="let itmData of selectedData;let index = index"
									[hidden]="(tabMode == 'invgroup' && !itmData.isCameraGroup) || (tabMode == 'list' && itmData.isCameraGroup)">
									<div class="list-item-container">
										<div class="column column-0">
											<mat-icon matTooltip="{{'Deselect' | translate}}"
												(click)="deSelectItem(itmData, index)">arrow_back</mat-icon>
										</div>
										<div class="selected">
											<mat-icon *ngIf="!itmData.isCameraGroup">videocam</mat-icon>
											<mat-icon *ngIf="itmData.isCameraGroup" class="group">grid_view</mat-icon>
										</div>
										<div class="column column-1 clickable" (click)="loadCameraDataAll(itmData)">
											{{itmData.name | truncate: ['50','...']}} <span
												*ngIf="itmData.cameras && itmData.cameras.length > 0">(<span
													*ngFor="let itm of itmData.cameras;let cIndex = index">{{itm.name}}<span
														*ngIf="cIndex < itmData.cameras.length -1">,
													</span></span>)</span>
										</div>
										<div class="column column-2" *ngIf="!isInvestigator && !itmData.isCameraGroup">
											<span class="visibilityBtn" (click)="setVisibility(itmData, index)"
												[matTooltip]="'Visibility' | translate">
												<mat-icon class="green" *ngIf="!itmData.invisible">videocam</mat-icon>
												<mat-icon class="red" *ngIf="itmData.invisible">videocam_off</mat-icon>
											</span>
										</div>
										<div class="column column-2"
											*ngIf="!(!isInvestigator && !itmData.isCameraGroup)">

										</div>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
			<div fxFlex align="end">
				<button class="green-text" mat-raised-button [disabled]="loading || loadingGroup"
					(click)="selectData()">{{'Save' |
					translate}}</button>
				<button class="red-text" mat-raised-button (click)="closeModal()">{{'Close' | translate}}</button>
			</div>
		</div>
	</div>
	<!-- <br /> -->
</div>