<mat-toolbar class="nav" color="primary">
    <button type="button" class="menuButton visible-sm no-print" mat-icon-button style="z-index: 1;"
        (click)="toggleSidebar()" exceptclick>
        <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
    </button>
    <span class="nav-spacer no-print"></span>
    <div class="profile hide-sm">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start">
            <div fxFlex="10" fxLayoutAlign="start start" class="no-print">
                <button type="button" class="menuButton" mat-icon-button style="z-index: 1;" (click)="toggleSidebar()"
                    exceptclick>
                    <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
                </button>
                <div class="nav-brand" title="{{subTitle | translate}}" exceptclick fxLayout="row" fxLayoutGap="0.5vw"
                    fxLayoutAlign="center center">
                    <!-- <img fxFlex id="topNavLogo" src="assets/images/logo.png"
                        class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}"
                        (click)="toggleSidebar()"> -->
                    <div fxFlex class="userPrefecture" *ngIf="selectedUser">{{prefecture}}</div>
                    <img fxFlex *ngIf="!(selectedUser && selectedUser.prefecture)" src="assets/images/logo-text.png"
                        (click)="toggleSidebar()" />
                </div>
            </div>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            <div class="hide-sm picture-role no-print" [matMenuTriggerFor]="userMenu" #menuTrigger="matMenuTrigger">
                <div fxFlex fxLayout="column" fxGrow="0" fxLayoutAlign="center center">
                    <div class="profile-container">
                        <img fxFlex [src]="pictureLink || 'assets/images/profile.png'"
                            [class.role-border-red]="role == 'Admin'" [class.role-border-green]="role == 'Investigator'"
                            [class.role-border-yellow]="role == 'Commander'"
                            [class.role-border-black]="role == 'Technician'"
                            class="circle-img-btn z-depth-2 responsive-img activator" />
                        <div *ngIf="unreadNotificationsNumber || unread.length" class="red-dot"></div>
                    </div>
                    <div fxFlex fxGrow="0" class="role">
                        {{role | translate}}
                        <!-- {{selectedUser.lastName}} {{selectedUser.firstName}} -->
                    </div>
                </div>
                <mat-menu #userMenu="matMenu" class="user-menu">
                    <div matMenuContent (click)="$event.stopPropagation()">
                        <div fxLayout="column" fxLayoutGap="0" class="user-menu-content">
                            <div fxFlex fxLayout="column" fxLayoutGap="0" class="top-section">
                                <div fxFlex fxLayoutAlign="center center">
                                    <div class="user-image">
                                        <img [src]="pictureLink || 'assets/images/profile.png'"
                                            class="circle-img-btn z-depth-2 responsive-img activator" />
                                        <mat-icon (click)="editProfileImage()" class="edit"
                                            [matTooltip]="'Edit' | translate">edit
                                        </mat-icon>
                                    </div>
                                </div>
                                <div fxFlex fxGrow="0" fxLayoutAlign="center center" class="name">
                                    {{selectedUser.lastName}} {{selectedUser.firstName}}
                                </div>
                                <div fxFlex fxGrow="0" class="role" fxLayoutAlign="center center">
                                    {{role | translate}}
                                </div>
                                <div fxFlex fxGrow="0" class="badge" fxLayoutAlign="center center">
                                    {{selectedUser.badge_no | translate}}
                                </div>
                                <div fxFlex fxGrow="0" fxLayoutAlign="center center" class="email">
                                    {{selectedUser.email}}
                                </div>
                                <div fxFlex fxGrow="0" fxLayoutAlign="center center" class="email">
                                    {{selectedUser.phone}}
                                </div>
                            </div>
                            <div fxFlex fxGrow="0" *ngIf="unreadNumber">
                                <div (click)="openUnReadDialog()" class="clickable">
                                    <mat-icon style="color:red">mark_chat_unread</mat-icon> {{unreadNumber}}
                                    <ng-container *ngIf="unreadNumber == 1">{{'unread message' |
                                        translate}}</ng-container>
                                    <ng-container *ngIf="unreadNumber > 1">{{'unread messages' |
                                        translate}}</ng-container>
                                    <!-- <ng-container *ngIf="!unread.length">
                                        <mat-icon>chat_bubble</mat-icon> {{unread.length}} {{'unread messages' | translate}}
                                    </ng-container>                                     -->
                                </div>
                            </div>
                            <div fxFlex fxGrow="0">
                                <div (click)="openUnReadNotificationsDialog()" class="clickable">
                                    <mat-icon *ngIf="unreadNotificationsNumber"
                                        style="color:red">notifications_active</mat-icon>
                                    <mat-icon *ngIf="!unreadNotificationsNumber">notifications</mat-icon>
                                    <span *ngIf="unreadNotificationsNumber">{{unreadNotificationsNumber}} </span>
                                    <ng-container *ngIf="unreadNotificationsNumber == 1">{{'unread notifications' |
                                        translate}}</ng-container>
                                    <ng-container *ngIf="unreadNotificationsNumber > 1">{{'unread notifications' |
                                        translate}}</ng-container>
                                    <ng-container *ngIf="unreadNotificationsNumber == 0">{{'Notifications' |
                                        translate}}</ng-container>
                                </div>
                            </div>
                            <div fxFlex fxGrow="0">
                                <div (click)="saveDnd()" class="clickable">
                                    <mat-icon>{{isDnd ? 'do_not_disturb_on': 'do_not_disturb_off'}}</mat-icon>
                                    <span *ngIf="isDnd">{{'DND on' |
                                        translate}}</span>
                                    <span *ngIf="!isDnd">{{'DND off' |
                                        translate}}</span>
                                </div>
                            </div>
                            <div fxFlex fxGrow="0">
                                <div (click)="editProfile()" class="clickable">
                                    <mat-icon>edit</mat-icon> <span>{{'Edit Profile' | translate}}</span>
                                </div>
                            </div>
                            <div fxFlex>
                                <div (click)="changePassword()" class="clickable">
                                    <mat-icon>lock_reset</mat-icon> <span>{{'Change Password' | translate}}</span>
                                </div>
                            </div>
                            <!-- <div fxFlex>
                                <div (click)="askSupport()" class="clickable">
                                    <mat-icon>help</mat-icon> <span>{{'Help' | translate}}</span>
                                </div>
                            </div> -->
                            <div fxFlex *ngIf="languageSelection || isSuperAdmin">
                                <div (click)="changeLanguage()" class="clickable">
                                    <mat-icon>language</mat-icon> <span
                                        *ngIf="languageSelected === 'en'">Language/言語</span>
                                    <span *ngIf="languageSelected === 'ja'">言語/Language</span>
                                </div>
                            </div>
                            <div fxFlex>
                                <div (click)="onLoggedout()" class="clickable">
                                    <mat-icon>logout</mat-icon> <span>{{'Logout' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        <!-- <mat-form-field class="language hide-sm">
            <mat-label>{{'Language' | translate}}</mat-label>
            <mat-select name="select-clients" (selectionChange)="changeLang($event.value)" [value]="languageSelected">
                <mat-option value="en">English</mat-option>
                <mat-option value="ja">日本語</mat-option>
            </mat-select>
        </mat-form-field> -->
        <!-- <span class="date">{{date | date : "EEEE" | translate }}, {{date | date : "MMMM" | translate }} {{date |
            date : "d, y" | translate }}</span> -->
        <!-- <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" (click)="handleMenuOpened()"
            class="notifications-menu-button" [class.has-new]="hasNew">
            <mat-icon>notifications</mat-icon>
        </button> -->
        <!-- <mat-menu #notificationsMenu="matMenu">
        <div class="notifications-top-nav-box">

            <div *ngIf="!notificationsLoaded" style="text-align: center;">
                <mat-spinner diameter="30" style="margin: auto;"></mat-spinner>
            </div>

            <div *ngIf="!notificationsList.length && notificationsLoaded">
                <p style="text-align: center;">No notifications yet</p>
            </div>

            <span *ngIf="notificationsList.length && notificationsLoaded">
                <div *ngFor="let item of notificationsList">
                    <button mat-menu-item [class.notification-badge]="item.viewed == false"
                        (click)="viewNotification(item)" style="font-size: 14px;">{{item.title}}</button>
                </div>
            </span>
        </div>
        <div>
            <button mat-menu-item
                style="width:35%; float: left; padding: 0px; margin-right: 0px; text-align: center;line-height: 35px; height: 35px;"
                (click)="moveToNotificationsPage()">View all</button>
            <button mat-menu-item color="primary"
                style="width:Calc(65% - 5px); padding: 0px; float: left; text-align: center; background-color: #32A5D7; color: #ffffff;line-height: 35px; height: 35px;"
                (click)="markAllAsRead()">Mark all as read</button>
        </div>
    </mat-menu> -->
        <!-- <button mat-icon-button [routerLink]="[]" title="{{'My Profile' |translate}}" [matMenuTriggerFor]="profileMenu">
            <mat-icon style="font-size: 40px;line-height: 40px;height: 40px;width: 40px;"
                *ngIf="!pictureLink || pictureLink === ''">account_circle</mat-icon>
            <img *ngIf="pictureLink && pictureLink !== ''" [src]="pictureLink"
                class="circle-img-btn z-depth-2 responsive-img activator">
        </button>
        <mat-menu #profileMenu="matMenu">
            <div class="profile-top-nav-box">
                <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">{{'Profile' | translate}}
                </button>
                <button mat-menu-item (click)="changeLanguage()" style="font-size: 14px;">
                    <ng-container *ngIf="languageSelected == 'en'">
                        Language/言語
                    </ng-container>
                    <ng-container *ngIf="languageSelected == 'ja'">
                        言語/Language
                    </ng-container>
                </button>
                <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">{{'Logout' | translate}}
                </button>
            </div>
        </mat-menu> -->
        <!-- <div class="profile-menu" [matMenuTriggerFor]="profileMenu">

            <div class="dr-name">{{selectedUser.firstName}}</div>

            <button mat-icon-button>
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #profileMenu="matMenu">
                <div class="profile-top-nav-box">
                    <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">Profile
                    </button>

                    <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">Logout
                    </button>
                </div>
            </mat-menu>
        </div> -->
    </div>
</mat-toolbar>