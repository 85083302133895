import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from "@angular/google-maps";
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { GlobalService } from './services/global.service';

import {
    AlertActionEntityDialogComponent, SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent, ActionNotificationComponent, ConfirmInputEntityDialogComponent, AlertComponent,
    UpdateEntityDialogComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ModalUserDialogComponent, ModalDialogComponent, ModalPasswordDialogComponent, ModalUserViewDialogComponent, CustomSelectComponent, CustomTagsComponent, ModalIframeHeaderDialogComponent, InvestigatorsAssignDialogComponent, CustomModalViewEventDialogComponent, CustomStartEndDateTimeComponent, ModalSchedulerExportDialogComponent, ModalUploaderDialogComponent, GoogleMapsComponent, GoogleMapsDialogComponent, CustomVideoComponent, ModalLanguageDialogComponent, ModalCollectionDialogComponent,
    CamerasAssignDialogComponent, ModalCarplateViewDialogComponent,
    ModalImageViewDialogComponent,
    ModalCameraCaseAssignDialogComponent, CustomCasesListComponent,
    CustomImageComponent,
    CustomImageNewComponent
} from './components';
import { ModalFieldCropDialogComponent } from './components';

import { ModalGalleryDialogComponent } from './components';
import { ModalEmailDialogComponent, ModalVideoDialogComponent, ModalPagesDialogComponent, ModalEventMediaDialogComponent, ModalFormMediaDialogComponent, ModalEventDocumentDialogComponent, ModalCalenderDialogComponent, CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent } from './components';

import { CustomMetadataTableComponent, CustomMultipleSelectComponent, CustomMultipleSelectTreeComponent, CustomSelectCreateAutocompleteDialogComponent, IframeUrlComponent, ModalLayoutDialogComponent, SliderComponent, CustomSelectDialogComponent, ModalCropDialogComponent, MarginViewComponent, CustomDateTimeComponent } from './components';
import { ClickOutSideDirective, OrdinalDatePipe, FocusOnLoadDirective, HideOnWidthDirective } from './directives';
import { ConfirmSelectEntityDialogComponent } from './components';
// import { CkeditorBlockComponent, TextBlockComponent, LinkDescriptionBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, TileSelectDialogComponent, TileDataComponent, TilesListComponent, CustomTileViewComponent, ConfirmCkeditorEntityDialogComponent, WidgetComponent, ModalPreviewDialogComponent } from './modules/forms-libararies';

import { Utils } from './helpers/utils';

import { ImageCropperModule } from 'ngx-image-cropper';
import { FileModalDialogComponent } from './components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';
import { ModalCropPlateRecognitionDialogComponent } from './components/upload-image-crop-plate-dialog/upload-image-crop-plate-dialog.component';
import { MyFlexLayoutDirective } from './directives/my-flex-layout.directive';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomSelectNewComponent } from './components/custom-select-new/custom-select-new.component';
import { CustomCreateTableDialogComponent } from './components/custom-create-table-dialog/custom-create-table-dialog.component';
import { CameraDeviceComponent } from './components/camera-device/camera-device.component';
import { UnReadChatDialogComponent } from './components/topnav/unread-chat-dialog/unread-chat-dialog.component';
import { SupportDialogComponent } from './components/topnav/support-dialog/support-dialog.component';
import { UnReadNotificationsDialogComponent } from './components/topnav/unread-notifications-dialog/unread-notifications-dialog.component';
// import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        NgxMaterialTimepickerModule,
        // FlexLayoutModule.withConfig({ addFlexToParent: false })
        // DeviceDetectorModule
    ],
    providers: [
        // GlobalService
        Utils
    ],
    declarations: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        UnReadChatDialogComponent,
        UnReadNotificationsDialogComponent,
        SupportDialogComponent,
        NavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        EnumViewComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        // SpeakerCameraComponent,
        CustomTagsComponent,
        // TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        CustomMultipleSelectComponent,
        CustomMultipleSelectTreeComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        IframeUrlComponent,
        ModalLayoutDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        FocusOnLoadDirective,
        OrdinalDatePipe,
        SliderComponent,
        MarginViewComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent,
        ModalFieldCropDialogComponent,
        CustomModalViewEventDialogComponent,
        InvestigatorsAssignDialogComponent,
        CustomStartEndDateTimeComponent,
        ModalSchedulerExportDialogComponent,
        ModalUploaderDialogComponent,
        GoogleMapsComponent,
        GoogleMapsDialogComponent,
        CustomVideoComponent,
        ModalLanguageDialogComponent,
        ModalCollectionDialogComponent,
        CamerasAssignDialogComponent,
        ModalCropPlateRecognitionDialogComponent,
        ModalCarplateViewDialogComponent,
        ModalImageViewDialogComponent,
        MyFlexLayoutDirective,
        ModalCameraCaseAssignDialogComponent,
        CustomCasesListComponent,
        HideOnWidthDirective,
        CustomImageComponent,
        CustomImageNewComponent,
        CustomSelectNewComponent,
        CustomCreateTableDialogComponent,
        CameraDeviceComponent,
    ],
    exports: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        UnReadChatDialogComponent,
        UnReadNotificationsDialogComponent,
        SupportDialogComponent,
        NavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        // SpeakerCameraComponent,
        CustomTagsComponent,
        // TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        CustomMultipleSelectComponent,
        CustomMultipleSelectTreeComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        IframeUrlComponent,
        ModalLayoutDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        FocusOnLoadDirective,
        OrdinalDatePipe,
        SliderComponent,
        MarginViewComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent,
        ModalFieldCropDialogComponent,
        // EmojiFormComponent,
        // EmojiSliderFieldComponent,
        // FireworksBackgroundComponent,
        CustomModalViewEventDialogComponent,
        CameraDeviceComponent,
        InvestigatorsAssignDialogComponent,
        CustomStartEndDateTimeComponent,
        ModalSchedulerExportDialogComponent,
        ModalUploaderDialogComponent,
        GoogleMapsComponent,
        GoogleMapsDialogComponent,
        ReactiveFormsModule,
        CustomVideoComponent,
        ModalLanguageDialogComponent,
        ModalCollectionDialogComponent,
        CamerasAssignDialogComponent,
        ModalCropPlateRecognitionDialogComponent,
        ModalCarplateViewDialogComponent,
        ModalImageViewDialogComponent,
        MyFlexLayoutDirective,
        ModalCameraCaseAssignDialogComponent,
        CustomCasesListComponent,
        HideOnWidthDirective,
        CustomImageComponent,
        CustomImageNewComponent,
        CustomSelectNewComponent,
        CustomCreateTableDialogComponent
    ]
})
export class LayoutComponentModule { }
