import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LayoutUtilsService, LoaderService, RequestService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMapsComponent } from '../google-map/google-map.component';
import { environment } from 'src/environments/environment';
import { prepareCamera } from '../google-map/data-prepare';
import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CaseService } from '../../services/case.service';
import { openCenteredPopup } from '../../helpers';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  filters: any;
  filtersGroup: any;
  caseId: string;
  caseName: string;
  caseData: string;
  type: string;
  limit: number;
  limitMessage: string;
  columnData: string;
  confirmData: any;
  updateCaseservice: boolean;
}


@Component({
  selector: 'cameras-assign-dialog',
  templateUrl: './cameras-assign-dialog.component.html',
  styleUrls: ['./cameras-assign-dialog.component.scss']
})
export class CamerasAssignDialogComponent implements OnInit {
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public isInvestigator: boolean = false;
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: any = undefined;
  public apiGroupCallSubscription: any = undefined;
  public selectedUser: any;
  public errorMessage: string = '';
  public loading: boolean = false;
  public loadingGroup: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public paginationGroup: boolean = true;
  public canSearch: boolean = true;
  public optionData: any = [];
  public optionGroupData: any = [];
  public selectedData: any = [];
  public selectedGroupData: any = [];
  public searchVal: string = '';
  public tableDataTotal: number = 0;
  public tableDataTotalGroup: number = 0;
  public showMap = false;
  public tabMode = 'list';

  public baseImage = '';
  public baseImageSet = {};
  public baseImageSetDegrees = [0, 45, 90, 135, 180, 225, 270, 315];
  public centerLngLat: any = { lng: '', lat: '' };
  orderByList = [{ field: 'name', order: 'asc' }];

  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public paginatorTotalGroup: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageSizeGroup = 5;
  pageNumber = 1;
  pageNumberGroup = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('paginatorsingle') paginator: MatPaginator;
  @ViewChild('paginatorgroup') paginatorGroup: MatPaginator;

  @ViewChild('currentDialogMap') currentMap: GoogleMapsComponent;
  constructor(
    private requestService: RequestService, private loaderService: LoaderService, private translate: TranslateService, private caseService: CaseService,
    private layoutUtilsService: LayoutUtilsService, @Inject(MAT_DATE_LOCALE) public _locale: string,
    public dialogRef: MatDialogRef<CamerasAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog) {
    //console.log('DialogData', data);
  }

  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.paginatorGroup.pageIndex = 0;
            this.pageNumber = 1;
            this.pageNumberGroup = 1;
            this.loadDataSearch(undefined, true);
            this.loadDataGroupSearch(undefined, true);
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
    const paginatorSubscriptionsGroup = merge(this.paginatorGroup.page).pipe(
      tap(() => {
        this.getTableVariablesGroup();
        this.loadDataGroupSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptionsGroup);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.isInvestigator = this.requestService.isUserRoleInvestigator();
        }
      })
    );
  }
  getCamerasList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getCamerasList(this.data.caseId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          // console.log('getClientsList', data.results);
          this.selectedData = data.results.map((itm) => {
            itm['selected'] = true;
            itm['isCameraGroup'] = false;
            return itm;
          });
        } else {
          this.selectedData = [];
        }
        this.loading = false;
        this.getCamerasGroupList();
      });
    }
  }
  getCamerasGroupList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getCamerasGroupList(this.data.caseId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          // console.log('getClientsList', data.results);
          let selectedData = data.results.map((itm) => {
            itm['selected'] = true;
            itm['isCameraGroup'] = true;
            return itm;
          });
          this.selectedData = this.selectedData.concat(selectedData);
        } else {
          // this.selectedData = [];
        }
        this.loading = false;
        this.loadDataSearch(() => {
          this.loadDataGroupSearch(() => {
            this.loaderService.display(false);
          });
        });
      });
    }
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public getTableVariablesGroup() {
    this.pageNumberGroup = this.paginatorGroup.pageIndex + 1;
    this.pageSizeGroup = this.paginatorGroup.pageSize;
  }
  public loadDataSearch(callback = undefined, skipLoad = false) {
    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }
    if (this.loading) {
      this.loading = false;
    }
    if (!this.loading) {
      if (!skipLoad) {
        this.loading = true;
      }
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let fieldsConfiguration = ["name", "region", "prefecture", "geolocation", "pictureLink", "email"];
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.filter(itm => !itm.isCameraGroup);
        excludeList = this.selectedData.map(itm => itm._id);
      }

      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiCallSubscription = this.requestService.getDataList(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any = data;
          //console.log('dataReturned', dataReturned);
          if (dataReturned.pagination) {
            this.tableDataTotal = dataReturned.pagination.total;
            this.paginatorTotal.next(dataReturned.pagination.total);
          }
          this.optionData = dataReturned.results.map((itm) => {
            itm['selected'] = false;
            itm['isCameraGroup'] = false;
            return itm;
          });
        } else {
          this.optionData = [];
        }
        this.loading = false;
        if (callback) {
          callback()
        }
      });
    }
  }
  public loadDataGroupSearch(callback = undefined, skipLoad = false) {
    if (this.apiGroupCallSubscription) {
      this.apiGroupCallSubscription.unsubscribe();
    }
    if (this.loadingGroup) {
      this.loadingGroup = false;
    }
    if (!this.loadingGroup) {
      if (!skipLoad) {
        this.loadingGroup = true;
      }
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let fieldsConfiguration = ["name", "region", "prefecture", "cameras"];
      let filterConfiguration = undefined;
      if (this.data.filtersGroup) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filtersGroup));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.filter(itm => itm.isCameraGroup);
        excludeList = this.selectedData.map(itm => itm._id);
      }

      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiGroupCallSubscription = this.requestService.getDataList('cameragroup', { page: this.pageNumberGroup, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSizeGroup, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any = data;
          //console.log('dataReturned', dataReturned);
          if (dataReturned.pagination) {
            this.tableDataTotalGroup = dataReturned.pagination.total;
            this.paginatorTotalGroup.next(dataReturned.pagination.total);
          }
          this.optionGroupData = dataReturned.results.map((itm) => {
            itm['selected'] = false;
            itm['isCameraGroup'] = true;
            return itm;
          });
        } else {
          this.optionGroupData = [];
        }
        this.loadingGroup = false;
        if (callback) {
          callback()
        }
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  openMap(status): void {
    this.showMap = status;
    if (status) {
      this.tabMode = 'map';
      this.setUpMarker();
    } else {
      this.tabMode = 'list';
      this.loadDataSearch();
    }
  }
  changeMode(mode): void {
    // console.log('changeMode', mode);
  }
  selectData(): void {
    if (this.data.limit) {
      let selectedData = this.selectedData.filter(itm => !itm.isCameraGroup);
      let selectedDataGroup = this.selectedData.filter(itm => itm.isCameraGroup);
      if (selectedData.length > this.data.limit) {
        if (this.data.limitMessage) {
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot select more than') + ' ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
        }
      } else if (selectedDataGroup.length > this.data.limit) {
        if (this.data.limitMessage) {
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You cannot select more than') + ' ' + this.data.limit + ' camera group', 'Dismiss');
        }
      } else {
        this.continueAssign();
      }
    } else {
      this.continueAssign();
    }
  }
  refreshData() {
    this.loaderService.display(true);
    this.loadDataSearch(() => {
      this.loadDataGroupSearch(() => {
        this.loaderService.display(false);
        this.setUpMarker();
      });
    });
  }
  selectUserItem(user, index) {
    if (this.optionData.find(i => i._id === user._id)) {
      this.optionData = this.optionData.filter(i => i._id !== user._id);
    }
    user['selected'] = true;
    this.selectedData.push(user);
    this.refreshData();
  }
  selectGroupItem(group, index) {
    // console.log('selectGroupItem', group);
    if (this.optionGroupData.find(i => i._id === group._id)) {
      this.optionGroupData = this.optionGroupData.filter(i => i._id !== group._id);
    }
    group['selected'] = true;
    this.selectedData.push(group);
    this.refreshData();
  }
  deSelectItem(itm, index) {
    itm['selected'] = false;
    if (this.selectedData.find(i => i._id === itm._id)) {
      this.selectedData = this.selectedData.filter(i => i._id !== itm._id);
    }
    if (itm.isCameraGroup) {
      this.optionGroupData.push(itm);
    } else {
      this.optionData.push(itm);
    }
    this.refreshData();
    // console.log(itm, index);
  }
  // drop(event: CdkDragDrop<any[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     if (event.previousContainer.connectedTo instanceof Array && event.previousContainer.connectedTo.length === 2) {
  //       let currentIndex = event.currentIndex;
  //       if (event.previousContainer.data[event.previousIndex].isCameraGroup) {
  //         if (typeof event.previousContainer.connectedTo[1] === 'string') {
  //           // Handle string case
  //         } else {
  //           if (event.previousContainer.connectedTo[1].data.length < currentIndex) {
  //             currentIndex = 0;
  //           }
  //           transferArrayItem(event.previousContainer.data,
  //             event.previousContainer.connectedTo[1].data,
  //             event.previousIndex,
  //             currentIndex);
  //           this.optionGroupData = this.optionGroupData.map((itm) => {
  //             itm['selected'] = false;
  //             return itm;
  //           });
  //           this.tabMode = 'cgroup';
  //         }
  //       } else {
  //         if (typeof event.previousContainer.connectedTo[0] === 'string') {
  //           // Handle string case
  //         } else {
  //           if (event.previousContainer.connectedTo[0].data.length < currentIndex) {
  //             currentIndex = 0;
  //           }
  //           transferArrayItem(event.previousContainer.data,
  //             event.previousContainer.connectedTo[0].data,
  //             event.previousIndex,
  //             currentIndex);
  //           this.optionData = this.optionData.map((itm) => {
  //             itm['selected'] = false;
  //             return itm;
  //           });
  //           this.tabMode = 'list';
  //         }
  //       }
  //     } else {
  //       transferArrayItem(event.previousContainer.data,
  //         event.container.data,
  //         event.previousIndex,
  //         event.currentIndex);

  //       this.selectedData = this.selectedData.map((itm) => {
  //         itm['selected'] = true;
  //         return itm;
  //       });
  //     }
  //   }
  // }
  /** FILTRATION */
  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  continueAssign() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.requestService.assignCameras(this.data.caseId, this.selectedData, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.triggerCamerasCaseUpdate();
        } else {
          this.loading = false;
          this.loaderService.display(false);
        }
      });
    }
  }
  triggerCamerasCaseUpdate(respond: boolean = true) {
    if (this.data.updateCaseservice) {
      let dataPromise = this.caseService.getCameras(this.data.caseId);
      let dataGroupPromise = this.caseService.getCameraGroups(this.data.caseId);

      Promise.all([dataPromise, dataGroupPromise]).then(() => {
        // this.caseService.caseLoaded.next(true);
        this.caseService.camerasUpdated.next(true);
      }).catch(error => {
        // do nothing
      }).finally(() => {
        this.continueAssignRespond(respond);
      });
    } else {
      this.continueAssignRespond(respond);
    }
  }
  continueAssignRespond(respond: boolean = true) {
    if (respond) {
      this.loading = false;
      this.loaderService.display(false);
      this.layoutUtilsService.showNotification(this.data.targetDataTypeDisplay + ' ' + this.translate.instant('assigned successfully'), this.translate.instant('Dismiss'));
      this.dialogRef.close(this.selectedData);
    }
  }
  addMarkers(dataResultUsers = []) {
    let allMarkers = [];
    // dataResultUsers = dataResultUsers.filter(itm => itm.geolocation);
    if (dataResultUsers.length > 0) {
      dataResultUsers = dataResultUsers.map((itm) => {
        return prepareCamera(itm, undefined, true);
      });
      // console.log('dataResultUsers', dataResultUsers);
      allMarkers = allMarkers.concat(dataResultUsers);
    }
    this.currentMap.addMarkers(allMarkers);
  }
  getRandomElement(numbers) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    return numbers[randomIndex];
  }
  showGeoLocation(event) {
    this.loaderService.display(true);
    this.centerLngLat = event;
    this.getCamerasList();
    // this.requestService.getImageBase64('/assets/images/icon/video-cam.png', (base64) => {
    //   this.baseImage = base64;
    //   this.rotateImages().then(() => {
    //     this.getCamerasList();
    //   })
    // })
  }
  async rotateImages() {
    for (const degree of this.baseImageSetDegrees) {
      await this.rotateImage(degree);
    }
  }
  private rotateImage(degree: number): Promise<void> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = this.baseImage;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        // Set the canvas dimensions based on the rotated image
        canvas.width = img.height;
        canvas.height = img.width;

        // Rotate the image
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(-degree * (Math.PI / 180));
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        // Convert the rotated image back to base64

        this.baseImageSet[degree] = canvas.toDataURL('image/png');
        // console.log(degree, this.baseImageSet[degree]);
        resolve();
      };
    });
  }
  setUpMarker(callback = undefined) {
    let allMarkers = [];
    allMarkers = allMarkers.concat(this.selectedData);
    this.loadCameraData((dataResultCamera) => {
      allMarkers = allMarkers.concat(dataResultCamera);
      this.addMarkers(allMarkers);
      if (callback) {
        callback();
      }
    });
  }
  markerSelect(mark: any) {
    // console.log('markerSelect', mark);
    if (this.selectedData.find(i => i._id === mark._id)) {
      this.selectedData = this.selectedData.filter(i => i._id !== mark._id);
    } else {
      mark['selected'] = true;
      this.selectedData.push(mark);
    }
    this.setUpMarker();
  }
  infoSelect(mark: any) {
    // console.log('infoSelect', mark);
    if (this.selectedData.find(i => i._id === mark._id)) {
      this.selectedData = this.selectedData.filter(i => i._id !== mark._id);
    } else {
      mark['selected'] = true;
      this.selectedData.push(mark);
    }
    this.setUpMarker();

  }
  clearData(): void {
    this.selectedData = [];
    this.setUpMarker(() => {
      this.loadDataSearch();
      this.loadDataGroupSearch();
    });
  }
  loadCameraData(callback) {
    let dataResultCamera = [];
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      let fieldsConfiguration = ['picture', 'camera_id', 'name', 'region', 'prefecture', 'camera_type', 'status', 'geolocation', 'target_url'];
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.map(itm => itm._id)
      }
      // we might need to get the camera near this case (this.selectedMarker)

      let filterObj = { page: 1, perpage: 1000000, orderDir: this.orderDir, orderBy: this.orderBy, orderByList: this.orderByList, filter: filterConfiguration, fieldKeys: fieldsConfiguration, exclude: excludeList };
      this.apiCallSubscription = this.requestService.getDataList('camera', filterObj, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataResult = data.results.map((itm) => {
            itm['selected'] = false;
            return itm;
          });
          dataResultCamera = JSON.parse(JSON.stringify(dataResult));

        }
        this.loading = false;
        if (callback) {
          callback(dataResultCamera)
        }
      });
    }
  }
  addGroup() {
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      autoFocus: false,
      width: "850px",
      data: {
        dataType: 'cameragroup',
        dataTypeTitle: this.translate.instant('Camera Group'),
        title: this.translate.instant("Create") + " " + this.translate.instant("Camera Group"),
        data: {},
        modalSetting: this.getGroupSetting({}, this.getCustomGroupFilter()),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      if (result && result.action === 'refresh') {
        this.triggerCamerasCaseUpdate(false);
        if (result.data) {
          let group = result.data;
          group['selected'] = true;
          group['isCameraGroup'] = true;
          this.selectedData.push(group);
          this.refreshData();
        }
      }

    });

  }
  editCameraGroup(group, index) {
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      autoFocus: false,
      width: "850px",
      data: {
        dataType: 'cameragroup',
        dataTypeTitle: this.translate.instant('Camera Group'),
        title: this.translate.instant("Edit") + " " + this.translate.instant("Camera Group"),
        data: { _id: group._id },
        modalSetting: this.getGroupSetting({}, this.getCustomGroupFilter()),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      if (result && result.action === 'refresh') {
        if (result.data) {
          this.triggerCamerasCaseUpdate(false);
          this.updateGroupData(result.data);
        }
      }

    });

  }
  updateGroupData(updatedItm) {
    // console.log('updateGroupData', updatedItm);
    this.selectedData = this.selectedData.map((itm) => {
      if (itm._id === updatedItm._id) {
        let newItm = JSON.parse(JSON.stringify(updatedItm));
        newItm['selected'] = true;
        newItm['isCameraGroup'] = true;
        return newItm;
      }
      return itm;
    });
    this.optionGroupData = this.optionGroupData.map((itm) => {
      if (itm._id === updatedItm._id) {
        let newItm = JSON.parse(JSON.stringify(updatedItm));
        newItm['selected'] = false;
        newItm['isCameraGroup'] = true;
        return newItm;
      }
      return itm;
    });
  }
  protected getGroupSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'self';
    if (filters)
      tableSetting['filters'] = filters;

    let caseCameras = {};
    caseCameras['$and'] = [
      { "organizationId._id": { "$in": [this.requestService.orgId] } }
    ];
    if (!this.isSuperAdmin) {
      caseCameras['$and'].push({ "region": { "$eq": this.selectedUser.region } });
      caseCameras['$and'].push({ "prefecture": { "$eq": this.selectedUser.prefecture } });
    }

    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: [{ _id: this.requestService.orgId, name: '' }]
      },
      name: {
        visible: true
      },
      cameras: {
        filters: caseCameras
      }
    };
    if (this._locale === 'ja') {
      tableSetting['customSettings']['address_jp'] = {
        visible: true
      }
      tableSetting['customSettings']['address'] = {
        visible: false
      }
    } else {
      tableSetting['customSettings']['address'] = {
        visible: true
      }
      tableSetting['customSettings']['address_jp'] = {
        visible: false
      }
    }
    if (!this.isSuperAdmin && this.selectedUser.region && this.selectedUser.region !== '') {
      tableSetting['customSettings']['region'] = {
        visible: false,
        value: this.selectedUser.region
      }
    }
    if (!this.isSuperAdmin && this.selectedUser.prefecture && this.selectedUser.prefecture !== '') {
      tableSetting['customSettings']['prefecture'] = {
        visible: false,
        value: this.selectedUser.prefecture
      }
    }
    return tableSetting;
  }

  editCamera(camera, index) {
    let dataTypeSingleDisplay = 'Camera';
    const tableSetting = this.getCameraSetting({})
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      autoFocus: false,
      width: '850px',
      data: {
        dataType: 'camera',
        dataTypeTitle: this.translate.instant('Camera'),
        dataTypeSingleTitle: this.translate.instant('Camera'),
        title: this.translate.instant('Edit') + ' ' + this.translate.instant(dataTypeSingleDisplay),
        data: { _id: camera._id },
        action: 'edit',
        modalSetting: JSON.parse(JSON.stringify(tableSetting))
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do somthing
      }
    });
  }

  private getCameraSetting(data) {
    let tableSetting = data;
    tableSetting['target'] = 'self';
    let caseInvestigators = {};
    caseInvestigators['$and'] = [
      { "organizationId._id": { "$in": [this.requestService.orgId] } }
    ];

    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: [{ _id: this.requestService.orgId, name: '' }]
      },
      appId: { visible: false, value: [{ _id: this.requestService.appId, organizationId: this.requestService.orgId, name: '' }] },
      locationId: { visible: false, value: [{ _id: this.requestService.locId, appId: this.requestService.appId, name: '' }] },
      name: {
        visible: false,
        nullable: true
      },
      investigators: {
        filters: caseInvestigators
      },
    };
    if (!this.isSuperAdmin && this.selectedUser.region && this.selectedUser.region !== '') {
      tableSetting['customSettings']['region'] = {
        visible: false,
        value: this.selectedUser.region
      }
    }
    if (!this.isSuperAdmin && this.selectedUser.prefecture && this.selectedUser.prefecture !== '') {
      tableSetting['customSettings']['prefecture'] = {
        visible: false,
        value: this.selectedUser.prefecture
      }
    }
    return tableSetting;
  }
  protected getCustomGroupFilter() {
    let filters = {};
    filters['$and'] = [{ "organizationId._id": { "$in": [this.requestService.orgId] } }];
    // if (this.selectedRole) {
    //   filters['$and'].push({ "resources._id": { "$eq": this.selectedRole } });
    // }
    if (!this.isSuperAdmin && this.selectedUser.region && this.selectedUser.region !== '') {
      filters['region'] = { '$eq': this.selectedUser.region };
    }
    if (!this.isSuperAdmin && this.selectedUser.prefecture && this.selectedUser.prefecture !== '') {
      filters['prefecture'] = { '$eq': this.selectedUser.prefecture };
    }
    return filters;
  }
  setVisibility(camera, index) {
    this.selectedData[index]['invisible'] = !this.selectedData[index]['invisible'];
  }
  loadCameraDataSec(cameraId) {
    let url = '/camera/' + cameraId;
    openCenteredPopup(url, this.translate.instant('Camera') + ':' + cameraId);
  }
  loadCameraGRoupDataSec(cameragroupId) {
    let url = '/allcams/' + cameragroupId;
    openCenteredPopup(url, this.translate.instant('Camera') + ':' + cameragroupId);
  }
  loadCameraDataAll(data) {
    if (!data.isCameraGroup) {
      this.loadCameraDataSec(data._id)
    } else {
      this.loadCameraGRoupDataSec(data._id)
    }
  }
}
