import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, HostListener, ChangeDetectorRef, AfterViewInit, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoaderService } from '../../services';



@Component({
  selector: 'app-custom-image-new',
  templateUrl: './custom-image-new.component.html',
  styleUrls: ['./custom-image-new.component.scss']
})
export class CustomImageNewComponent implements AfterViewInit {
  @Input() name: any = '';
  @Input() url: string = '';
  @ViewChild('container', { static: true }) containerRef!: ElementRef<HTMLDivElement>;

  @ViewChild('image', { static: true }) imageRef!: ElementRef<HTMLImageElement>;



  // Transformation properties
  loaded: boolean = false
  scale: number = 1;

  translateX: number = 0;

  translateY: number = 0;

  transform: string = 'translate(0px, 0px) scale(1)';



  // Dragging state

  isDragging: boolean = false;

  lastMouseX: number = 0;

  lastMouseY: number = 0;



  constructor(private cd: ChangeDetectorRef, private loaderService: LoaderService) { }



  ngAfterViewInit(): void {

    this.loaderService.display(true);
    // In case the image is cached and already loaded.

    if (this.imageRef.nativeElement.complete) {

      this.onImageLoad();

    }

  }

  handleError(event: Event) {
    // const imgElement = event.target as HTMLImageElement;
    // imgElement.src = this.fallbackImage;
    this.loaderService.display(false);
  }

  /**

   * When the image loads, delay the calculation so that the popup container

   * has settled into its final size. Then calculate the scale factor to ensure

   * that the image completely fits inside the container and center it.

   */

  onImageLoad(): void {
    this.loaded = false;
    this.loaderService.display(true);
    setTimeout(() => {
      const containerRect = this.containerRef.nativeElement.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;
      const imgEl = this.imageRef.nativeElement;
      const imageWidth = imgEl.naturalWidth;
      const imageHeight = imgEl.naturalHeight;

      this.scale = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);
      // Center the image by computing the required translation.
      this.translateX = (containerWidth - imageWidth * this.scale) / 2;
      this.translateY = (containerHeight - imageHeight * this.scale) / 2;
      this.updateTransform();
      // Inform Angular of the change.

      this.cd.detectChanges();
      this.loaderService.display(false);
      this.loaded = true;
    }, 500);

  }



  /**

   * Update the CSS transform applied to the image.

   */

  updateTransform(): void {

    this.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`;

  }



  /**

   * Handle zooming via the mouse wheel.

   */

  // onWheel(event: WheelEvent): void {

  //   event.preventDefault();



  //   const zoomFactor = 0.1;

  //   if (event.deltaY < 0) {

  //     this.scale += zoomFactor;

  //   } else {

  //     this.scale -= zoomFactor;

  //   }



  //   // Optionally constrain the scale (adjust these limits as needed)

  //   this.scale = Math.min(Math.max(0.1, this.scale), 3);

  //   this.updateTransform();

  // }

  // onWheel(event: WheelEvent): void {
  //   event.preventDefault();

  //   const zoomFactor = 1.1; // Adjust sensitivity
  //   const scaleDelta = event.deltaY < 0 ? zoomFactor : 1 / zoomFactor;
  //   const prevScale = this.scale;
  //   this.scale *= scaleDelta;

  //   // Clamp scale to prevent excessive zooming
  //   this.scale = Math.min(Math.max(0.1, this.scale), 3);

  //   // Get the bounding box of the image or container
  //   const rect = this.imageRef.nativeElement.getBoundingClientRect(); // Replace with your element reference

  //   // Calculate mouse position relative to the element
  //   const mouseX = event.clientX - rect.left;
  //   const mouseY = event.clientY - rect.top;

  //   // Adjust translation to keep zoom centered at mouse position
  //   this.translateX -= (mouseX / prevScale - mouseX / this.scale);
  //   this.translateY -= (mouseY / prevScale - mouseY / this.scale);

  //   this.updateTransform();
  // }
  onWheel(event: WheelEvent): void {
    event.preventDefault();

    const zoomFactor = 1.1; // Adjust zoom sensitivity
    const scaleDelta = event.deltaY < 0 ? zoomFactor : 1 / zoomFactor;
    const prevScale = this.scale;
    this.scale *= scaleDelta;

    // Get container and image dimensions
    const containerRect = this.containerRef.nativeElement.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;
    const imgEl = this.imageRef.nativeElement;
    const imageWidth = imgEl.naturalWidth;
    const imageHeight = imgEl.naturalHeight;

    // Calculate the minimum scale to fit the image within the container
    const minScale = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);

    // Prevent excessive zooming out
    this.scale = Math.max(minScale, Math.min(3, this.scale));

    // Get mouse position relative to the container
    const mouseX = event.clientX - containerRect.left;
    const mouseY = event.clientY - containerRect.top;

    // Get the current offset of the image
    const offsetX = mouseX - this.translateX;
    const offsetY = mouseY - this.translateY;

    // Adjust translation to maintain zoom focus at the mouse pointer
    this.translateX -= (offsetX / prevScale) * (this.scale - prevScale);
    this.translateY -= (offsetY / prevScale) * (this.scale - prevScale);

    // If zoomed out to minimum, center the image
    if (this.scale === minScale) {
      this.translateX = (containerWidth - imageWidth * this.scale) / 2;
      this.translateY = (containerHeight - imageHeight * this.scale) / 2;
    }

    this.updateTransform();
  }

  /**

   * Start dragging.

   */

  onMouseDown(event: MouseEvent): void {

    event.preventDefault();

    this.isDragging = true;

    this.lastMouseX = event.clientX;

    this.lastMouseY = event.clientY;

    this.imageRef.nativeElement.style.cursor = 'grabbing';

  }



  /**

   * Continue dragging.

   */

  onMouseMove(event: MouseEvent): void {

    if (!this.isDragging) {

      return;

    }

    event.preventDefault();



    const deltaX = event.clientX - this.lastMouseX;

    const deltaY = event.clientY - this.lastMouseY;

    this.lastMouseX = event.clientX;

    this.lastMouseY = event.clientY;



    this.translateX += deltaX;

    this.translateY += deltaY;

    this.updateTransform();

  }



  /**

   * End dragging.

   */

  onMouseUp(event: MouseEvent): void {

    this.isDragging = false;

    this.imageRef.nativeElement.style.cursor = 'grab';

  }



  /**

   * End dragging if the mouse leaves the container.

   */

  onMouseLeave(event: MouseEvent): void {

    this.isDragging = false;

    this.imageRef.nativeElement.style.cursor = 'grab';

  }



  /**

   * Recalculate the fit and centering when the window is resized.

   */

  @HostListener('window:resize')

  onResize(): void {

    if (this.imageRef.nativeElement.complete) {

      this.onImageLoad();

    }

  }


}