import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { collection, getDocs, writeBatch, doc, getFirestore, query, where, } from "firebase/firestore";
import { environment } from "src/environments/environment";

export interface ChatData {
    show: boolean;
    focusToSearch?: boolean;
    caseId: string;
    caseName: string;
    investigators: any;
    investigatorGroups: any;
    isInvestigatorProfile?: boolean;
    isNotHiddenLeftMenu?: boolean;
}

export interface CallData {
    show: boolean;
    answerCall?: boolean;
    answerSessionCall?: string;
    currentCaseId?: string;
    currentCaseInvestigator?: any;
    currentInvestigatorGroup?: any;
    caseId?: string;
    investigators?: any;
    selectedUsers?: any;
    hostId?: string;
    userId?: string;
    invitedBy?: string;
    invitedByName?: string;
    callerId?: string;
    investigatorGroups?: any;
    isInvestigatorProfile?: boolean;
    invitees?: string[];
}

@Injectable()
export class ChatService {
    public autoSelectedChat: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public selectedUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public selectedDashboardUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public selectedChatUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public callStatus: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public unreadSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public unreadGroupSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public showChatDialogSubject: BehaviorSubject<ChatData> = new BehaviorSubject<ChatData>({
        show: undefined,
        caseId: '',
        caseName: '',
        investigatorGroups: [],
        investigators: [],
    });
    public showCallDialogSubject: BehaviorSubject<CallData> = new BehaviorSubject<CallData>({
        show: false
    });
    public showDashboardChatDialogSubject: BehaviorSubject<ChatData> = new BehaviorSubject<ChatData>({
        show: undefined,
        caseId: '',
        caseName: '',
        investigatorGroups: [],
        investigators: [],
    });
    public showCallPromptSubject: BehaviorSubject<CallData> = new BehaviorSubject<CallData>({
        show: false
    });
    public ongoingCall: any = undefined;

    private db = getFirestore();

    constructor(private router: Router) { }

    showChatDialog(senderId, senderName, shouldClear: boolean = false) {
        this.router.navigate(([`/notifications`])).then(() => {
            this.selectedUser.next({
                isGroup: false,
                id: senderId,
                name: senderName,
                shouldClear: shouldClear,
            });

            // let tokens = [];
            // userTokens.deviceids?.map(j => tokens.push(j.deviceId));

            this.showChatDialogSubject.next({
                show: true,
                investigators: [{ _id: senderId, name: senderName }],
                caseId: undefined,
                caseName: undefined,
                investigatorGroups: [],
                isInvestigatorProfile: true,
            });
        });
    }

    async markAsRead(currentUserId, senderId, isGroup) {
        try {
            const colRef = collection(this.db, `${environment.firestore_config.unreadDb}/${currentUserId}/${isGroup ? 'group' : 'private'}`);

            let userQuery;
            if (isGroup)
                userQuery = query(colRef, where('receiverId', '==', senderId));
            else
                userQuery = query(colRef, where('senderId', '==', senderId));

            const snapshot = await getDocs(userQuery);

            if (snapshot.empty) {
                // console.log('No documents to delete.');
                return;
            }

            const batch = writeBatch(this.db);

            snapshot.docs.forEach((docSnap) => {
                const docRef = doc(this.db, colRef.path, docSnap.id);
                batch.delete(docRef);
            });

            await batch.commit();
        }
        catch (e) { }
    }
}